// Search uses camelcase and mapping camel_case direclty to expected props
//  is more easy to read and understand what fields are directly from
//  algolia properties vs other transforms.
/* eslint-disable camelcase */

import utils from 'lib/utils';
import reviewSet from './reviewSet';
import { getImageUrl } from '../cloudinary';
import useAppContext, { DomainSiteKey } from 'lib/hooks/useAppContext';
import useReviewsAffiliateTag from 'lib/hooks/useReviewsAffiliateTag';
import { useCallback } from 'react';
import type { ArticleCardProps } from 'legacy-mise-ui/components/Cards/ArticleCard/ArticleCard';
import { trackedParterLink } from 'lib/hooks/mixpanel/tracked.ssr';
import getSiteKeyPreference from './documents/getSiteKeyPreference';
import { isFavoritableObjectID } from './documents/getObjectId';
import { generateAffiliatesUrl } from './domainHelpers';

export type Hit = Record<string, any>;

/**
 * Asin needed for ctaAttr for buy now click event. Parse from url like
 *  browse seems to do currently.
 */
function tryAsinFromCtaUrl(ctaUrl?: string): string {
  if (!ctaUrl) return '';
  const matches = /www\.amazon\.com\/dp\/([a-zA-Z0-9]+)/.exec(ctaUrl);
  return matches?.[1] ?? '';
}

function isReviewFromSearch(
  search_document_klass: string | undefined,
): boolean {
  if (!search_document_klass) return false;
  if (search_document_klass.toLowerCase().includes('equipment')) return true;
  if (search_document_klass.toLowerCase().includes('taste')) return true;
  return false;
}

type GetCtaFieldsFromSearchArgs = {
  search_document_klass: string;
  search_atk_buy_now_link: string;
  search_cio_buy_now_link: string;
  search_cco_buy_now_link: string;
  hit: Hit;
  documentSiteKey: DomainSiteKey;
  domainSiteKey: DomainSiteKey;
  tag: string;
  title: string;
};
function getCtaFieldsFromSearch({
  search_document_klass,
  search_atk_buy_now_link,
  search_cio_buy_now_link,
  search_cco_buy_now_link,
  hit,
  documentSiteKey,
  domainSiteKey,
  tag,
  title,
}: GetCtaFieldsFromSearchArgs) {
  const buyNowLinks = {
    atk: search_atk_buy_now_link,
    cio: search_cio_buy_now_link,
    cco: search_cco_buy_now_link,
  };

  if (isReviewFromSearch(search_document_klass)) {
    const {
      asin: possibleAsin,
      price = '',
      // TODO(cleanup): refactor this out of js in new function. combine below logic
    } = reviewSet.getDocData(hit, { siteKey: documentSiteKey, tag });

    const winnerLink = buyNowLinks[domainSiteKey]?.split('?')[0];
    const { isAmazon, ctaUrl } = generateAffiliatesUrl(winnerLink, tag);
    const asin = possibleAsin || tryAsinFromCtaUrl(ctaUrl);

    return {
      ctaUrl,
      ctaText: 'Buy the Winner',
      ctaDataAttrs: trackedParterLink({
        asin,
        price,
        reviewable: title,
        recommendationStatus: null,
        affiliateTag: isAmazon ? tag : null,
        affiliateUrl: ctaUrl,
      }),
    };
  }

  // allow spread operator
  return {};
}

export function getAuthorsFromSearch(
  search_author: string | string[] | undefined | null,
) {
  const authorNameDirty = Array.isArray(search_author)
    ? search_author?.[0]
    : search_author;

  if (!authorNameDirty) return [];

  // some algolia authors have double spaces between first and last name.
  const authorName = authorNameDirty.replace(/\s\s/g, ' ');

  const authorFolderPart = authorName
    ?.toLowerCase()
    ?.replace(/[&/\\#, +()$~%.'":*?<>{}-]/g, '_')
    ?.replace(/’/g, '');

  const [firstName, lastName] = authorName.split(' ', 2);

  // legacy directory where all algolia search authors are kept.
  const publicId = `AKO%20Articles/Author_Headshots/staff_${authorFolderPart}.jpg`;

  return [
    {
      firstName,
      lastName,
      photo: { publicId },
    },
  ];
}

/**
 * @param {string} search_published_date
 * @returns
 */
function getDateFromSearch(search_published_date?: number): string | undefined {
  if (!search_published_date) return;
  const publishDateString = search_published_date.toString();
  return `${publishDateString.substr(0, 4)}/${publishDateString.substr(
    4,
    2,
  )}/${publishDateString.substr(6, 2)}`;
}

/**
 * Get document site key for a domain with data from algolia search_site_list.
 * Only considers domain site keys.
 */
function getSiteKeyFromSearch(
  domainSiteKey: DomainSiteKey,
  search_site_list: string[] | string | undefined | null,
): DomainSiteKey {
  return getSiteKeyPreference(search_site_list, domainSiteKey) ?? domainSiteKey;
}

function isStringArray(value: unknown): value is string[] {
  if (!Array.isArray(value)) return false;
  if (value.some((item) => typeof item !== 'string')) return false;
  return true;
}

const dayCount = 30;
const newStart = new Date(
  new Date().getTime() - dayCount * 24 * 60 * 60 * 1000,
);

function isDocumentNew(search_published_date: number): boolean {
  const documentDate = getDateFromSearch(search_published_date);

  if (!documentDate) return false;

  return new Date(documentDate) >= newStart;
}

type Stickers = { type: 'priority' | 'editorial'; text: string }[];

function getStickersFromSearch(
  search_stickers: string[] | unknown,
  search_published_date: number,
): Stickers {
  const stickers: Stickers = [];

  if (isDocumentNew(search_published_date)) {
    stickers.push({ type: 'priority', text: 'new' });
  }

  if (isStringArray(search_stickers)) {
    search_stickers.filter(Boolean).forEach((sticker) => {
      stickers.push({ type: 'editorial', text: sticker });
    });
  }

  return stickers.slice(0, 2);
}

export function algoliaToArticleCard(
  domainSiteKey: DomainSiteKey,
  isAuthenticated: boolean,
): (hit: Hit) => ArticleCardProps {
  return (hit: Hit) => {
    const {
      objectID,
      title,
      description,
      search_stickers,
      search_published_date,
      search_author,
      search_document_date,
      search_cloudinary_id,
      search_site_list,
      search_document_klass,
      search_url,
    } = hit;

    const documentSiteKey = getSiteKeyFromSearch(
      domainSiteKey,
      search_site_list,
    );

    const href = utils.getDocumentUrl(
      search_document_klass,
      search_url,
      documentSiteKey,
    );

    const canFavorite = isAuthenticated && isFavoritableObjectID(objectID);

    return {
      documentSiteKey,
      title,
      description,
      cloudinaryId: search_cloudinary_id,
      stickers: getStickersFromSearch(search_stickers, search_published_date),
      authors: getAuthorsFromSearch(search_author),
      attribution: utils.formatPublishDate(search_document_date),
      linkProps: { href },
      favoritesObjectId: canFavorite ? objectID : undefined,
    };
  };
}

/**
 * If a user is authenticated and a recipe has rating data, then display recipe
 * attributions.
 *
 * @param {boolean} isAuthenticated
 * @param {number} avgRating
 * @param {number} numRating
 * @returns {boolean}
 */
const getDisplayRecipeAttributions = (
  isAuthenticated: boolean,
  avgRating: number,
  numRating: number,
): boolean => isAuthenticated && !!avgRating && !!numRating;

/** Thunk version of algoliaHitToStandardCard in transforms.js */
export function useAlgoliaToStandardCardCallback(hideBuyWinnerLinks?: boolean) {
  const { isAuthenticated, domainSiteKey, subdomain } = useAppContext();
  const tag = useReviewsAffiliateTag({ cardType: 'standard' });

  return useCallback(
    (hit: Hit) => {
      const {
        search_avg_rating,
        search_user_ratings_count,
        search_atk_buy_now_link,
        search_cio_buy_now_link,
        search_cco_buy_now_link,
        search_cloudinary_id,
        search_comment_count,
        search_document_klass,
        search_document_klass_formatted = '',
        search_price,
        search_published_date,
        search_sale_price,
        search_site_list = ['atk'],
        search_stickers,
        search_url,
        title,
      } = hit;

      const siteKey = search_site_list.includes(domainSiteKey)
        ? domainSiteKey
        : search_site_list[0];
      const href = utils.getDocumentUrl(
        search_document_klass,
        search_url,
        siteKey,
        subdomain,
      );
      const { favoritableId, favoritableSiteKey } =
        utils.getFavoritableDataFromUrl(href, siteKey);
      const displayFavoritesButton = isAuthenticated && Boolean(favoritableId);

      const imageUrl = search_cloudinary_id
        ? getImageUrl(search_cloudinary_id, 'thumbnail', { aspectRatio: '1:1' })
        : null;
      const stickers = utils.getStickers(
        search_published_date,
        search_stickers,
      );
      // add recipe ratings in to this later
      const searchAttribution = search_comment_count || null;
      const displaySecondaryAttribution = true;
      const target = siteKey !== domainSiteKey ? '_blank' : null;

      const { ctaDataAttrs, ctaText, ctaUrl } = getCtaFieldsFromSearch({
        documentSiteKey: siteKey,
        domainSiteKey,
        hit,
        search_atk_buy_now_link,
        search_cco_buy_now_link,
        search_cio_buy_now_link,
        search_document_klass,
        tag,
        title,
      });

      const shopPrices =
        search_price && search_sale_price
          ? { price: search_price, salePrice: search_sale_price }
          : undefined;

      const dataAttrs = {
        'data-title': title,
        'data-type': search_document_klass,
        'data-id': search_url,
        'data-link': href,
      };

      const displayRecipeAttribution = getDisplayRecipeAttributions(
        isAuthenticated,
        search_avg_rating,
        search_user_ratings_count,
      );

      return {
        avgRating: search_avg_rating,
        contentType: search_document_klass,
        contentTypeFormatted: search_document_klass_formatted,
        numRatings: search_user_ratings_count,
        objectId: favoritableId,
        ctaDataAttrs,
        ctaText,
        ctaUrl: hideBuyWinnerLinks ? null : ctaUrl,
        dataAttrs,
        displayLockIcon: false,
        displayFavoritesButton,
        displaySecondaryAttribution,
        displayRecipeAttribution,
        favoriteRibbonColor: utils.siteKeyToRibbonColor(siteKey),
        href,
        imageUrl,
        searchAttribution: true,
        searchComments: searchAttribution && search_comment_count,
        shopPrices,
        siteKey,
        siteKeyFavorites: favoritableSiteKey,
        stickers,
        target,
        title: utils.truncateString(title, 59),
      };
    },
    [domainSiteKey, hideBuyWinnerLinks, isAuthenticated, subdomain, tag],
  );
}
