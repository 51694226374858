import {
  KIDS_PODCAST_NEWSLETTER_PENDING,
  KIDS_PODCAST_NEWSLETTER_FULFILLED,
  KIDS_PODCAST_NEWSLETTER_REJECTED,
} from '../actions/kidsPodcastNewsletter';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  email: null,
  status: c.DEFAULT,
};

const kidsPodcastNewsletter = (state = initialState, action) => {
  switch (action.type) {
    case KIDS_PODCAST_NEWSLETTER_PENDING:
      return {
        email: action.payload.email,
        status: c.LOADING,
      };

    case KIDS_PODCAST_NEWSLETTER_REJECTED:
      return {
        ...state,
        status: c.ERROR,
      };

    case KIDS_PODCAST_NEWSLETTER_FULFILLED:
      return {
        ...state,
        status: c.READY,
      };

    default:
      return state;
  }
};

export default kidsPodcastNewsletter;
