import useAppContext, { DomainSiteKey } from './useAppContext';

const amazonTags = {
  atk: 'atkequipland-20',
  cio: 'cioequipland-20',
  cco: 'ccoequipland-20',
};

// WEB-853
const thanksgivingAmazonTag = 'atkthanksgivingguide-20';

// WEB-884
const tngAmazonTag = 'atknextgen22-20';

/**
 * Gets Amazon affiliate tag based on site key. These site keys refer to the domain
 *  of the site. This currently only shows equipment tags. UPDATE: this is being
 *  kept the same on non reviews pages, so far this includes homepages. Still worth
 *  flagging in new feature pages if this assumption holds.
 */

interface ReviewsAffiliateTagProps {
  siteKey?: DomainSiteKey;
  pageType?: string;
  cardType?: string;
}

export default function useReviewsAffiliateTag({
  siteKey,
  pageType,
  cardType,
}: ReviewsAffiliateTagProps) {
  const { domainSiteKey } = useAppContext();
  if (cardType === 'standard') return 'atkstandardcard-20';
  if (cardType === 'feature') return 'atkfeaturedcard-20';

  if (pageType === 'thanksgiving') return thanksgivingAmazonTag;
  if (pageType === 'tng') return tngAmazonTag;
  return siteKey ? amazonTags[siteKey] : amazonTags[domainSiteKey];
}
