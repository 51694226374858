export type ExtendedDomainSiteKey = 'atk' | 'cco' | 'cio' | 'play';

export interface GlobalHeaderLink {
  id?: string;
  isActive?: boolean;
  label: string;
  href: string;
  showPlayIcon?: boolean;
  target?: string;
  codes: {
    param: string;
    values: {
      [key: string]: string[];
    };
  };
}

const siteNavLinkData: GlobalHeaderLink[] = [
  {
    id: 'atk',
    label: "America's Test Kitchen",
    href: '/',
    codes: {
      param: 'incode',
      values: {
        cco: ['MKHBDA1L0', 'MKHBTA1L0', 'MKHBMA1L0'],
        cio: ['MCHBDA1L0', 'MCHBTA1L0', 'MCHBMA1L0'],
      },
    },
  },
  {
    id: 'cio',
    label: "Cook's Illustrated",
    href: '/cooksillustrated',
    codes: {
      param: 'incode',
      values: {
        atk: ['MAHBDC1L0', 'MAHBTC1L0', 'MAHBMC1L0'],
        cco: ['MKHBDC1L0', 'MKHBTC1L0', 'MKHBMC1L0'],
        play: ['MAHBDC1L0', 'MAHBTC1L0', 'MAHBMC1L0'],
      },
    },
  },
  {
    id: 'cco',
    label: "Cook's Country",
    href: '/cookscountry',
    codes: {
      param: 'incode',
      values: {
        atk: ['MAHBDK1L0', 'MAHBTK1L0', 'MAHBMK1L0'],
        cio: ['MCHBDK1L0', 'MCHBTK1L0', 'MCHBMK1L0'],
        play: ['MAHBDK1L0', 'MAHBTK1L0', 'MAHBMK1L0'],
      },
    },
  },
  {
    id: 'atknextgen',
    label: 'ATK The Next Generation',
    href: '/tng',
    target: '_blank',
    codes: {
      param: 'incode',
      values: {
        atk: ['MAHBZTN10', 'MAHBZTN10', 'MAHBZTN10'],
        cco: ['MKHBZTN10', 'MKHBZTN10', 'MKHBZTN10'],
        cio: ['MCHBZTN10', 'MCHBZTN10', 'MCHBZTN10'],
        play: ['MAHBZTN10', 'MAHBZTN10', 'MAHBZTN10'],
      },
    },
  },
  {
    id: 'school',
    label: 'Cooking School',
    href: 'https://www.americastestkitchen.com/sso/relay?target=https://www.onlinecookingschool.com/?&_ga=2.128610255.2123640240.1692884973-758414257.1692884973',
    target: '_blank',
    codes: {
      param: 'extcode',
      values: {
        atk: ['MAHBDS1L0', 'MAHBTS1L0', 'MAHBMS1L0'],
        cco: ['MKHBDS1L0', 'MKHBTS1L0', 'MKHBMS1L0'],
        cio: ['MCHBDS1L0', 'MCHBTS1L0', 'MCHBMS1L0'],
        play: ['MAHBDS1L0', 'MAHBTS1L0', 'MAHBMS1L0'],
      },
    },
  },
  {
    id: 'shop',
    label: 'ATK Shop',
    href: 'https://shop.americastestkitchen.com/',
    target: '_blank',
    codes: {
      param: 'sourcekey',
      values: {
        atk: [
          'CAHBDBSL0&___store=default',
          'CAHBTBSL0&___store=default',
          'CAHBMBSL0&___store=default',
        ],
        cco: [
          'CKHBZBSL0&___store=default',
          'CKHBTBSL0&___store=default',
          'CKHBMBSL0&___store=default',
        ],
        cio: [
          'CCHBD00L0&___store=default',
          'CCHBT00L0&___store=default',
          'CCHBM00L0&___store=default',
        ],
        play: [
          'CAHBDBSL0&___store=default',
          'CAHBTBSL0&___store=default',
          'CAHBMBSL0&___store=default',
        ],
      },
    },
  },
];

export default siteNavLinkData;
