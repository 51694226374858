import { useEffect } from 'react';

const useOnDocumentBlur = (cb: () => void) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        cb();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [cb]);
};

export default useOnDocumentBlur;
