import cookie from 'cookie';

const cookies = {
  delete(name, options = { path: '/' }) {
    this.set(name, '', { ...options, days: -1 });
  },
  set(name, value, options = { days: 14, path: '/', secure: true }, res) {
    const secure =
      typeof options.secure !== 'undefined' ? Boolean(options.secure) : true;
    const path = options.path || '/';

    const days = parseInt(options.days || 14, 10);
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    delete options.days;

    const defaultOptions = {
      expires,
      path,
      secure,
    };
    const cookieOptions = {
      ...defaultOptions,
      ...options,
    };
    // TODO: need to patch this because res.cookie isn't a function with NextJS
    if (res) {
      res.cookie(name, value, cookieOptions);
    } else if (typeof document !== 'undefined') {
      document.cookie = cookie.serialize(name, value, cookieOptions);
    }
  },
};

export default cookies;
