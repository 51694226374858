import {
  TOGGLE_NAVIGATION_DRAWER,
  SET_NAVIGATION_DRAWER_CONTENT,
} from '../actions/navigationDrawer';

export const initialState = {
  isOpen: false,
  content: {
    type: 'nav',
  },
};

const navigationDrawer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NAVIGATION_DRAWER:
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    case SET_NAVIGATION_DRAWER_CONTENT:
      return {
        ...state,
        content: { ...action.payload.content },
      };
    default:
      return state;
  }
};

export default navigationDrawer;
