import Head from 'next/head';
import React, { Fragment } from 'react';

const Pinterest = () => {
  const markup =
    "(function(d){setTimeout(function () {var f = d.getElementsByTagName('SCRIPT')[0], p = d.createElement('SCRIPT');p.type = 'text/javascript';p.async = true;p.defer = true;p.crossOrigin='anonymous';p.src = '//assets.pinterest.com/js/pinit.js';f.parentNode.insertBefore(p, f);}, 1000)}(document));";

  return (
    <Fragment>
      <Head>
        <meta
          content="false"
          key="pinterest-rich-pin"
          name="pinterest-rich-pin"
        />
      </Head>
      <script
        key="optimizely-script"
        dangerouslySetInnerHTML={{
          __html: markup,
        }}
      />
    </Fragment>
  );
};

export default Pinterest;
