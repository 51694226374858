import React from 'react';
/* eslint-disable */

const dryInlineScript = `
/* eslint-disable */
(function (win, doc) {
var dryTopics = {}, hOP = dryTopics.hasOwnProperty, debug = false,
    customEvents = {
      'dry:ready': doc.createEvent('Event')
    },
    rtRe = //kids/(recipes|activities|discover|quizzes|home|search)/;
function reducedTracking() {
  var hostname = document.location.hostname, path = document.location.pathname;
  return rtRe.test(path);
}
function createTopic(topic) {
  dryTopics[topic] = {data: null, subscribers: [] };
}
function subscribe(topic, listener, defer) {
  if(!hOP.call(dryTopics, topic)) { createTopic(topic); }
  var immediate = typeof defer === 'undefined';
  var index = dryTopics[topic].subscribers.push(listener) - 1;
  var data = dryTopics[topic].data;
  if (debug) console.info('subscribe', topic, data);
  if (data && immediate) listener(JSON.parse(JSON.stringify(data)));
  return { remove: function() { delete dryTopics[topic].subscribers[index];} };
}
function publish(topic, data) {
  if(!hOP.call(dryTopics, topic)) { createTopic(topic); }
  dryTopics[topic].data = JSON.parse(JSON.stringify(data || true));
  if (debug) console.info('publish', topic, data);
  dryTopics[topic].subscribers.forEach(function(item) {
    if (typeof item === 'function') { item(typeof data !== 'undefined' ? data : true); }
  });
  if (customEvents[topic]) win.dispatchEvent(customEvents[topic]);
}
win.dry = win.dry || {
  events: { subscribe: subscribe, publish: publish },
  DOMContentLoaded: false,
  mpLoaded: false,
};
Object.keys(customEvents).forEach(function (customEvent) {
  customEvents[customEvent].initEvent(customEvent, true, true);
});

win.dry.events.publish('dry:events');

doc.addEventListener('DOMContentLoaded', function () {
  win.dry.DOMContentLoaded = true;
  win.dry.events.publish('dom:loaded');
});

blueconicDataLayer = window.blueconicDataLayer || {};
var countBlue = 0;
var loopBlue = setInterval(checkBlue, 250);
function checkBlue() {
  var feelingBlue = typeof blueconicDataLayer.blueconicSegment !== 'undefined';
  if (feelingBlue && blueconicDataLayer.blueconicSegment) document.cookie = 'atk_blueconicSegment=' + blueconicDataLayer.blueconicSegment + '; path=/';
  if (feelingBlue || countBlue >= 20) clearInterval(loopBlue);
  else countBlue += 1;
}
}(window, document));
`;

const DryInline = () => {
  return (
    <script 
      id="global-dry-js"
      key="global-dry-js"
      dangerouslySetInnerHTML={{
        __html: dryInlineScript
      }}
    />
  )
};

export default DryInline;
