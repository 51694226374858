import { TrackProvider } from '@americastestkitchen/ui/app/TrackProvider';
import trackUI from './trackUI';
import Footer from '@americastestkitchen/ui/footer/index';

export default function UIFooter() {
  return (
    <TrackProvider track={trackUI}>
      <Footer />
    </TrackProvider>
  );
}
