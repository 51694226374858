import { breakpoints as miseBreakpoints } from 'legacy-mise-ui/styles';

import {
  breakpoints,
  checkbox,
  contentMaxWidth,
  colors,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
} from './defaults';

const themeColors = {
  ...colors,
  aliceBlue: '#e2e7ff',
  brightLilac: '#d889ff',
  caribbean: '#00D1A4', // 0 209 164
  christi: '#5DA11D', // 93 161 29
  citrus: '#B0D100', // 176 209 0
  darkJade: '#008055',
  darkPastelGreen: '#00D14F', // 0 209 79
  darkTurquoise: '#00C3D3', // 0 195 211
  darkViolet: '#9300DF', // 147 0 223
  deluge: '#7369ae', // because what's one more color?
  deepSkyBlue: '#00DBFF', // 0 219 255
  derby: '#ffecd8',
  dodgerBlue: '#0D8DFF', // 13 141 255
  eden: '#2a6e5c',
  elm: '#22765e', // 34 118 94
  frostedMint: '#dcfff4',
  goldenPoppy: '#D7AD00', // 215 173 0
  gossamer: '#40aa8d', // 64 170 141
  grape: '#7369AE',
  grayDay: '#EFF2F5',
  islandGreen: '#48bd9d',
  transparentIslandGreen: 'rgba(220, 255, 244, 0.5)',
  lightSeaGreen: '#1EC6CE', // 30 198 206
  lemonade: '#F8F280',
  magnolia: '#faf6ff',
  mangoTango: '#ED7700', // 237 119 0
  mediumSlateBlue: '#6C43EF', // 108 67 239
  mediumVioletRed: '#CC16C9', // 204 22 201
  melrose: '#BDB6FC',
  navyBlue: '#005AF0', // 0 90 240
  oceanGreen: '#3ea388',
  oceanGreener: '#3FAA8D',
  orangeRed: '#F44713', // 244 71 19
  palePurple: '#f4deff',
  rainCloud: '#DFE5EA',
  rainforest: '#047b54', // 4 123 84
  sunglow: '#FFC940',
  torchRed: '#FA223D', // 250 34 61
  turquoise: '#44d7b6',
  turquoiseButDifferentSlightlyDarker: '#6DEAC2',
  wildStrawberry: '#FF4094', // 255 64 148
  lighterSmoke: '#F4F4F4', // 235 235 235
};

const themeBreakpoints = {
  ...breakpoints,
  ...miseBreakpoints,
};

const themeFonts = {
  ...fonts,
  cwf: 'chippewa-falls',
};

const kidsTheme = {
  siteKey: 'kids',
  themeColor: '#d73a15',
  fonts: themeFonts,
  breakpoints: themeBreakpoints,
  contentMaxWidth,
  colors: themeColors,
  checkbox,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
};

export default kidsTheme;
