import { DomainSiteKey } from 'lib/hooks/useAppContext';

/**
 * Get site key preference from some array or string of site keys.
 * Only considers domain site keys.
 */
export default function getSiteKeyPreference(
  siteList: string[] | string | undefined | null,
  domainSiteKey: DomainSiteKey | undefined,
): DomainSiteKey | undefined {
  const siteKeyOrder = ['atk', 'cio', 'cco'] as const;

  if (!siteList) return undefined;

  if (typeof siteList === 'string') {
    const siteKey = siteList.toLowerCase();
    return siteKeyOrder.find((orderKey) => orderKey === siteKey);
  }

  if (Array.isArray(siteList)) {
    const siteKeyList = siteList
      .filter(Boolean)
      .map((key) => key.toLowerCase());
    if (domainSiteKey && siteKeyList.includes(domainSiteKey))
      return domainSiteKey;

    return siteKeyOrder.find((siteKey) => siteKeyList.includes(siteKey));
  }

  return undefined;
}
