import WidgetsAnalytics from './widgetUtils/analytics';
import FavoritablesManager from './manager';
import widgetUtils from './widgetUtils/utils';
import renderPopoverHelper from './popover-app-render';

// DEV note - traceur doesn't like trailing commas - don't use them

/**
 * Application to be initialized on the Favorites Dashboard and Document detail pages.
 * The application allows favorite creation/deletion as well as managing favorite collection
 * membership. You can also create a new collection from the application.
 * @param       {HTMLElement} appEl
 * @constructor
 */
function FavoritesPopoverApp(appEl) {
  if (!appEl) return;
  this.appEl = appEl;
  this.state = {
    collectionName: null,
    collections: [],
    favoriteId: null,
    loading: false,
    open: false,
    saved: false,
    triggerEl: null,
  };

  this.isMobile =
    document.body.classList.contains('phone') || window.innerWidth <= 375;
  this.maxWidth = 345;
  this.openClassName = 'open';
  this.openBodyClass = 'favorites-popover-open';
  this.favoritedClassName = 'favorited';
  this.managerDataAttr = 'data-manage-favoritable-id';
  this.manager = new FavoritablesManager(
    this.managerDataAttr,
    this.favoritedClassName,
  );
  this.popoverEl = document.createElement('div');
  this.appEl.appendChild(this.popoverEl);
  this.handleAppClick = widgetUtils.debounce(
    this.handleAppClick.bind(this),
    250,
    true,
  );
  this.renderPopover = widgetUtils.debounce(this.renderPopover.bind(this), 50);
  this.appEl.addEventListener('click', this.handleAppClick);
  this.appEl.addEventListener('change', this.handleCheckboxChange.bind(this));
  this.appEl.addEventListener('submit', this.handleFormSubmit.bind(this));
  this.appEl.addEventListener('keydown', this.handleKeydown.bind(this));
  this.updateMetaEvent = document.createEvent('Event');
  this.updateMetaEvent.initEvent('update-favorite-meta', true, true);
  document.body.classList.add('favorites-popover-page');

  const boundUpdate = this.update.bind(this);
  this.update = widgetUtils.debounce(boundUpdate, 250);
  window.addEventListener('atk-search', boundUpdate, false);
  boundUpdate();

  window.onpopstate = this.closePopover.bind(this);

  this.analytics = new WidgetsAnalytics();
}

FavoritesPopoverApp.prototype = {
  update() {
    this.manager.update();
  },
  get error() {
    return this.state.error;
  },

  set error(errorText) {
    this.state.error = errorText;
  },

  get success() {
    return this.state.sucess;
  },

  set success(sucessText) {
    this.state.success = sucessText;
  },

  get open() {
    return this.state.open;
  },

  get triggerEl() {
    return this.state.triggerEl;
  },

  set triggerEl(el) {
    if (
      this.triggerEl &&
      this.triggerEl.classList.contains(this.openClassName)
    ) {
      this.triggerEl.classList.remove(this.openClassName);
    }
    this.state.triggerEl = el;
  },

  /**
   * When we set 'open', we start the loading process and also request
   * favorite collection api data. Setting open to false hides popover
   * and unbinds listeners.
   * @param  {Boolean} doOpen
   * @return {undefined}
   */
  set open(doOpen) {
    this.error = null;
    const eventName = this.isMobile ? 'orientationchange' : 'resize';
    window.removeEventListener(eventName, this.renderPopover);
    if (this.isMobile)
      window.removeEventListener('gestureend', this.renderPopover);
    if (doOpen) {
      this.collectionName = '';
      this.manager.getFavoriteCollections(
        this.favoritableId,
        this.favoritableSite,
        this.onCollectionsLoadSuccess.bind(this),
        this.closePopover.bind(this),
      );
      document.body.classList.add(this.openBodyClass);
      if (this.triggerEl) this.triggerEl.classList.add(this.openClassName);
      window.addEventListener(eventName, this.renderPopover);
      if (this.isMobile)
        window.addEventListener('gestureend', this.renderPopover);
    } else {
      /** Focus caller if closed not through navigation. */
      if (this.triggerEl) this.triggerEl.focus();
      this.triggerEl = null;
      this.success = null;
      this.state.open = false;
      this.renderPopover();
      document.body.classList.remove(this.openBodyClass);
      if (this.triggerEl) this.triggerEl.classList.remove(this.openClassName);
    }
  },

  get collectionName() {
    return this.state.collectionName;
  },

  set collectionName(name) {
    this.state.collectionName = name;
  },

  get collections() {
    return this.state.collections;
  },

  set collections(data) {
    this.state.collections = data;
  },

  get favoriteId() {
    return this.state.favoriteId || null;
  },

  set favoriteId(id) {
    this.state.favoriteId = id;
  },

  get favoritableId() {
    return (
      (this.triggerEl && this.triggerEl.dataset.manageFavoritableId) || null
    );
  },

  get favoritableTitle() {
    return (this.triggerEl && this.triggerEl.dataset.documentTitle) || null;
  },

  get v6favoritableId() {
    return (
      (this.triggerEl && this.triggerEl.dataset.manageFavoritableV6) || null
    );
  },

  get favoritableSite() {
    return (
      (this.triggerEl && this.triggerEl.dataset.originSite) ||
      widgetUtils.getSiteKey()
    );
  },

  /**
   * Success callback for collections api response.
   * Set the data in state and render the popover.
   * @param  {[type]} data [description]
   * @return {[type]}      [description]
   */
  onCollectionsLoadSuccess(data) {
    this.state.open = true;
    this.collections = data.collections || [];
    this.favoriteId = data.favorite_id;
    this.renderPopover(true);
  },

  /**
   * Error callback for collections api response.
   * Set loading and open to false, hiding the popover.
   * @return {[type]} [description]
   */
  closePopover() {
    this.open = false;
  },

  getAnalyticsOptions() {
    const favId = this.favoritableId;
    const splitSpot = favId.lastIndexOf('_');
    const document_id = favId.substring(splitSpot + 1); // eslint-disable-line camelcase
    const document_type = favId.substring(0, splitSpot); // eslint-disable-line camelcase
    const document_title = this.favoritableTitle; // eslint-disable-line camelcase
    const url = window.location.href;
    const eventLocation =
      url.indexOf('/favorites/') !== -1 ||
      url.indexOf('/favorite_collections/') !== -1
        ? 'dashboard'
        : 'detail_page';
    return {
      document_id,
      document_type,
      document_title,
      location: eventLocation,
    };
  },

  getEventElem(evt) {
    return evt.target.closest(`[${this.managerDataAttr}]`);
  },

  /**
   * Delegated event handler which captures clicks on our action elements.
   * Manage favorite click - opens the popover to manage favorite collections
   * Save favorite click - create/remove document in favorites
   * @param  {Event} evt
   * @return {undefined}
   */
  handleAppClick(evt) {
    const elem = this.getEventElem(evt);
    const favoritableId = elem && elem.dataset.manageFavoritableId;
    if (elem && typeof favoritableId !== 'undefined') {
      evt.preventDefault();
      const differentFavoritable =
        this.open && favoritableId !== this.favoritableId;
      this.triggerEl = elem;
      if (elem.classList.contains('manage-favorite')) {
        if (differentFavoritable) {
          this.open = true;
        } else {
          this.open = !this.open;
        }
      }
    } else if (
      evt.target.closest('.close') ||
      (this.open && !evt.target.closest('.favorites-popover'))
    ) {
      this.open = false;
    }
  },

  onFavoriteCollectionItemChangeSuccess(actionType, collection) {
    this.error = null;
    window.dispatchEvent(this.updateMetaEvent);
    this.renderPopover();
    const eventName =
      actionType === 'addFavoriteCollectionItem'
        ? 'COLLECTION_ITEM_CREATED'
        : 'COLLECTION_ITEM_DESTROYED';
    const options = this.getAnalyticsOptions();
    options.collection_name = collection.name;
    options.site_key = widgetUtils.getSiteKey();
    this.analytics.trackEvent(eventName, options);
  },

  onFavoriteCollectionItemChangeError() {
    this.error = 'Could not change collection membership at this time.';
    this.renderPopover();
  },

  /**
   * Delegated event capturing checkbox changes for favorite collections.
   * @param  {Event} evt
   * @return {Boolean}
   */
  handleCheckboxChange(evt) {
    if (evt.target.closest('.collections-list__input')) {
      const collectionSlug = evt.target.value;
      const collection = this.collections.find(
        (c) => c.slug === collectionSlug,
      );
      if (collection) collection.active = !collection.active;

      const collectionAction = evt.target.checked
        ? 'addFavoriteCollectionItem'
        : 'removeFavoriteCollectionItem';
      this.manager[collectionAction](
        collectionSlug,
        this.favoritableId,
        this.onFavoriteCollectionItemChangeSuccess.bind(
          this,
          collectionAction,
          collection,
        ),
        () => {
          this.onFavoriteCollectionItemChangeError();
        },
      );
    }
    return true;
  },

  /**
   * Escape: Close modal.
   * @param {KeyboardEvent} evt keyboard event.
   */
  handleKeydown(evt) {
    const { code, shiftKey } = evt;

    const escapeClose = code === 'Escape';

    const tabForwardClose =
      code === 'Tab' &&
      shiftKey === false &&
      'lastElement' in document.activeElement.dataset;

    const tabBackwardClose =
      code === 'Tab' &&
      shiftKey === true &&
      'firstElement' in document.activeElement.dataset;

    const keydownClose = escapeClose || tabForwardClose || tabBackwardClose;

    if (this.open && keydownClose) {
      evt.preventDefault();
      this.open = false;
    }
  },

  /**
   * Success callback for favorite collection add/remove api call.
   * Re-render popover (by setting open = true) and dispatch browser event
   * @return {undefined}
   */
  onCollectionCreateSuccess(response) {
    // track in analytics
    const options = this.getAnalyticsOptions();
    options.collection_name = this.collectionName;
    this.analytics.trackEvent('COLLECTION_CREATED', options);

    // reset form field, error and re-draw
    this.error = null;
    this.success = `${this.collectionName} collection created.`;
    this.collectionName = '';
    this.lastCollectionSlug = response.data.slug;
    this.open = true;

    // trigger meta data update (dashboard only)
    window.dispatchEvent(this.updateMetaEvent);
  },

  /**
   * Error callback for favorite collection add/remove api call.
   * Close popover and show error message.
   * @param  {String} errorText
   * @return {undefined}
   */
  onCollectionCreateError(errorText) {
    this.error = errorText;
    this.success = null;
    this.renderPopover();
  },

  /**
   * Delegated event capturing form submission for creating a new collection.
   * Trigger api call for create a new collection.
   * @param  {Event} evt
   * @return {undefined}
   */
  handleFormSubmit(evt) {
    if (evt.target.closest('.collection-form')) {
      evt.preventDefault();
      const name = evt.target.collectionName.value.trim();
      // This regex is used to exclude non-alphanumeric characters from
      // colleciton names
      const nonAlphaNumRegex = new RegExp(/[^a-zA-Z0-9\s]/); // eslint-disable-line
      if (!name.length) {
        this.error = 'Please enter a collection name.';
        this.success = null;
        this.renderPopover();
        this.collectionName = '';
      } else if (name.length < 1 || name.length > 25) {
        this.error = 'Collection name must be 1 to 25 characters long.';
        this.success = null;
        this.renderPopover();
        this.collectionName = name;
      } else if (nonAlphaNumRegex.test(name)) {
        this.error =
          'Collection name can only contain alphanumeric characters.';
        this.success = null;
        this.renderPopover();
        this.collectionName = name;
      } else {
        this.collectionName = name;
        this.manager.createCollection(
          name,
          this.v6favoritableId || this.favoritableId,
          this.onCollectionCreateSuccess.bind(this),
          this.onCollectionCreateError.bind(this),
        );
      }
    }
  },

  renderPopover(doFocus) {
    let collectionsScrollTop = null;
    const newCollection = typeof this.lastCollectionSlug !== 'undefined';
    // if not adding a new collection, we want to remember the current scroll location
    if (!newCollection) {
      const collectionsList = this.popoverEl.querySelector('.collections-list');
      if (collectionsList) {
        collectionsScrollTop = this.state.open ? collectionsList.scrollTop : 0;
      }
    }
    renderPopoverHelper(
      this.popoverEl,
      this.state,
      this.collections,
      this.favoritableTitle,
      this.triggerEl,
      this.maxWidth,
      doFocus,
    );

    // if new collection, we want to scroll to where that new collection is
    if (newCollection) {
      const el = document.getElementById(
        `input-collection-${this.lastCollectionSlug}-wrapper`,
      );
      collectionsScrollTop = el ? el.offsetTop - 10 : 0;
      this.lastCollectionSlug = undefined;
    }
    if (collectionsScrollTop) {
      // scroll the collection list to the location of the new collection
      this.popoverEl.querySelector('.collections-list').scrollTop =
        collectionsScrollTop;
      // On mobile, if the popover was pushed off the screen by the keyboard, we
      // should scroll it back onto the screen so the user can see the new collection
      if (this.state.open && !this.error && this.isMobile) {
        const pos = widgetUtils.getPosition(this.popoverEl.firstElementChild);
        if (pos.y < 0) {
          // 120 is a magic number. Should be the height of the fixed header
          // plus enough height to show all or most of the action button
          window.scrollTo(0, window.pageYOffset + pos.y - 120);
        }
      }
    }
  },
};

export default FavoritesPopoverApp;
