import { NextPageContext } from 'next';

const proxiedFor = 'X-Proxied-For';

const hasHeader = (req: NextPageContext['req'], header: string) => {
  const isDefined =
    req?.headers[header] || req?.headers[header.toLowerCase()] ? true : false;
  return isDefined;
};

const subdomainFromNextReq = (req: NextPageContext['req']): string => {
  let host: string | string[] | undefined;
  let subdomain = 'www';
  if (hasHeader(req, proxiedFor)) {
    host = req?.headers[proxiedFor] || req?.headers[proxiedFor.toLowerCase()];
  } else if (hasHeader(req, 'host')) {
    host = req?.headers['host'];
  }

  if (!host || typeof host !== 'string') return subdomain;

  if (host.indexOf('www') === 0) {
    const subdomainFromHost = host.split('.').shift();
    if (subdomainFromHost) {
      subdomain = subdomainFromHost;
    }
  }
  return subdomain;
};

export default subdomainFromNextReq;
