import Head from 'next/head';
import Script from 'next/script';
import React, { Fragment } from 'react';

const Facebook = () => {
  const appId = process.env.FACEBOOK_APP_ID_ATK;
  return (
    <Fragment key="facebook-widgets">
      <Head>
        <meta
          content="137227518307"
          key="meta-fb-page-one"
          property="fb:pages"
        />
        <meta
          content="164100590759"
          key="meta-fb-page-two"
          property="fb:pages"
        />
        <meta
          content="281933765564"
          key="meta-fb-page-three"
          property="fb:pages"
        />
        {appId && (
          <meta content={appId} key="meta-fb-app-id" property="fb:app_id" />
        )}
      </Head>
      {appId && (
        <Script
          id="facebook-jssdk"
          key="facebook-jssdk"
          dangerouslySetInnerHTML={{
            __html: `(function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s); js.id = id;js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=${appId}";js.defer = true;js.crossOrigin="anonymous";fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));`,
          }}
        />
      )}
    </Fragment>
  );
};

export default Facebook;
