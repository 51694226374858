import has from 'lodash.has';

import browser from '../../utils/browser';
import events from './events';

/**
 * If an event fires prior to mixpanel loading, we push the event
 * data onto a queue, which is processed as soon as mixpanel is initialized
 */
const eventQueue = [];
let initialized = false;

const getDevice = () => {
  const viewWidth = window.innerWidth;
  let device = 'mobile';
  if (viewWidth > 668) {
    device = viewWidth > 1099 ? 'desktop' : 'tablet';
  }
  return device;
};

// NOTE: The bulk of the mixpanel initialization has been moved to Mixpanel.js
// This has created some duplication, but is necessary to enable mixpanel ASAP
const onMixpanelLoaded = () => {
  initialized = true;
  if (eventQueue.length && mixpanel) {
    const deviceType = getDevice();
    eventQueue.forEach((evt) => {
      mixpanel.track(evt[0], {
        name: evt[0],
        device_type: deviceType,
        ...evt[1],
      });
    });
  }
};

/**
 * Mixpanel tracking library.
 *
 * Initializes with general user information which is appended to all
 * triggered events automatically via mixpanel code. Is capable of issuing
 * trackEvent calls plus synchronous calls for when we want to make sure
 * the calls complete prior to the page unloading, for example.
 */
function mixpanelLib() {
  if (typeof dry !== 'undefined') {
    window.dry.events.subscribe('mixpanel:user:loaded', onMixpanelLoaded);
  }

  /**
   * Asynchronous event tracking - calls mixpanel or pushes to queue
   */
  const trackEvent = (eventName, options = {}, callback) => {
    // Mixpanel doesn't work for FF mobile and the callbacks don't get called,
    // so skip all tracking for now.
    if (browser.isIOSFirefox()) {
      callback();
    } else if (has(events, eventName)) {
      if (initialized && window?.mixpanel) {
        options.device_type = getDevice();
        options.name = events[eventName];
        window.mixpanel.track(events[eventName], options, callback);
      } else {
        eventQueue.push([events[eventName], options]);
        if (typeof callback === 'function') callback();
      }
    }
  };

  const trackRouterChange = () => {
    trackEvent('PAGEVIEW', {
      location: document.location.pathname,
    });
  };

  /**
   * Generic PAGEVIEW event
   */
  const trackPageView = (options) => {
    if (initialized) {
      window.mixpanel.track(events.PAGEVIEW, options);
    } else {
      eventQueue.push([events.PAGEVIEW, options]);
    }
  };

  return {
    trackEvent,
    trackPageView,
    trackRouterChange,
  };
}

export default mixpanelLib;
