import {
  breakpoints,
  contentMaxWidth,
  colors,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
} from './defaults';

const themeColors = {
  ...colors,
  amethyst: '#54479E',
  amethystShade: '#3D3285',
  blueWhale: '#505E69',
  citrus: '#B0D100', // 176 209 0
  darkTurquoise: '#00C3D3', // 0 195 211
  jade: '#00A26D', // 0 162 209
  jadeTint: '#DCFFF4',
  mediumVioletRed: '#CC16C9', // 204 22 201
  rainforest: '#047b54', // 4 123 84
  ice: '#E5E9ED',
  grape: '#7369AE',
  grapeTint: '#E3dEFF',
  guavaLava: '#FFD0D0', // 255, 208, 208
  lemonade: '#F8F280',
  carrotCake: '#EC8315',
  carrotCakeTint: '#FFECD8',
  blueberry: '#3B4C99',
  blueberryTint: '#E2E7FF',
  plum: '#8B3FB1',
  raspberry: '#9D3333',
  grayDay: '#EFF2F5',
  rainCloud: '#DFE5EA',
  rock: '#8D9EAF',
  sharkFin: '#DAE0E6',
  nightmare: '#363E44',
};

const themeFonts = {
  ...fonts,
  cwf: 'chippewa-falls, sans-serif',
  gdn: 'gooddog-new, sans-serif',
};

const variables = {
  cardBoxShadow: '0.1rem 0.4rem 1.1rem 0 rgba(0, 0, 0, 0.07)',
  faqMaxWidthTabletUp: '99.2rem',
  testerContainerPadding: '3rem',
};

const parentsTheme = {
  siteKey: 'parents',
  themeColor: '#d73a15',
  fonts: themeFonts,
  colors: themeColors,
  fontSizes,
  lineHeights,
  breakpoints,
  contentMaxWidth,
  letterSpacing,
  socialColors,
  variables,
};

export default parentsTheme;
