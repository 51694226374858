import {
  NEWSLETTER_SUBMISSION_PENDING,
  NEWSLETTER_SUBMISSION_FULFILLED,
  NEWSLETTER_SUBMISSION_REJECTED,
} from '../actions/newsletter';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  email: null,
  status: c.DEFAULT,
};

const newsletter = (state = initialState, action) => {
  switch (action.type) {
    case NEWSLETTER_SUBMISSION_PENDING:
      return {
        email: action.payload.email,
        status: c.LOADING,
      };

    case NEWSLETTER_SUBMISSION_REJECTED:
      return {
        ...state,
        status: c.ERROR,
      };

    case NEWSLETTER_SUBMISSION_FULFILLED:
      return {
        ...state,
        status: c.READY,
      };

    default:
      return state;
  }
};

export default newsletter;
