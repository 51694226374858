/* eslint-disable no-console */
import qs from 'qs';

import atk from './atk';
import { getEndpoint } from './atk/endpoints';

const previewUrl = (contentId, entryId) => {
  const lastSlash = contentId.lastIndexOf('/');
  const docType = contentId.substring(0, lastSlash).replace('/', '_');
  const documentId = contentId.substring(lastSlash);
  return `preview/${docType}${documentId}/${entryId}`;
};

const zypeApiKey = process.env.ZYPE_API_KEY;
const zypePlaylistUri = 'https://api.zype.com/playlists';

const api = {
  async collection(contentId, authToken, options = {}) {
    const { pageSize, siteKey, pac, pacs, preview } = options;
    let fetchId = contentId;
    if (options.entryId) fetchId = previewUrl(contentId, options.entryId);
    const url = atk.getUrl(
      'v6',
      `/${fetchId}`,
      { pageSize, pac, pacs, preview },
      siteKey,
    );
    const response = await atk
      .get(url, authToken)
      .catch((err) => Promise.reject(err));
    return Promise.resolve({ contentId, response });
  },
  async detail(contentId, authToken, options = {}, siteKey) {
    let fetchId = contentId;
    if (options.entryId) {
      fetchId = previewUrl(contentId, options.entryId);
      delete options.entryId;
    }
    if (fetchId.charAt(0) !== '/') fetchId = `/${fetchId}`;
    let url = atk.getUrl('v6', fetchId, options, siteKey);
    // still fails if slug starts with /kids, but should from now on alway have \d+- first
    if (!url.includes('/kids')) {
      ['recipes', 'how_tos'].forEach((docType) => {
        url = url.replace(`/${docType}/`, `/no_cf_${docType}/`);
      });
    }
    const response = await atk
      .get(url, authToken, options)
      .catch((err) => Promise.reject(err));
    return Promise.resolve({ contentId, response });
  },
  articles: {
    async detail(id, options = {}) {
      const url = atk.getUrl('cv', `articles/${id}`, options);
      return atk.get(url);
    },
    async detailBarista(id, siteKey, options = {}) {
      const url = atk.getUrl('v6', `/articles/${id}`, options, siteKey);
      return atk.get(url);
    },
    async apiV6(endpoint) {
      const apiSlug = endpoint.replace('/api/v6/', '').replace('api/v6/', '');
      const response = await atk
        .get(`${getEndpoint('v6')}/${apiSlug}`)
        .catch((err) => Promise.reject(err));
      return Promise.resolve({ response });
    },
    async index(options = {}) {
      const url = atk.getUrl('v6', '/atk_articles_index', options);
      return atk.get(url);
    },
  },
  guides: {
    async detailBarista(id, siteKey, options = {}) {
      const url = atk.getUrl('v6', `/guides/${id}`, options, siteKey);
      return atk.get(url);
    },
  },
  authors: {
    async detail(id, options = {}) {
      const url = atk.getUrl('v6', `/cast_members/${id}`, options);
      return atk.get(url);
    },
  },
  homepage: {
    index(siteKey, options = {}) {
      const url = atk.getUrl('v6', `/home_pages/${siteKey}`, options);
      return atk.get(url);
    },
  },
  loadNextPage: {
    index(nextPageUrl, options = {}) {
      const url = atk.getPaginationUrl(nextPageUrl, options);
      return atk.get(url);
    },
  },
  async latest(contentId, pageSize) {
    const url = atk.getUrl('v6', `/${contentId}`, { pageSize });
    const response = await atk.get(url).catch((err) => Promise.reject(err));
    return Promise.resolve({ response });
  },
  async marketingHat(slug) {
    const url = atk.getUrl('v6', `/marketing_hat/${slug}`);
    const response = await atk.get(url).catch((err) => Promise.reject(err));
    return Promise.resolve({ slug, response });
  },
  async play(slug, authToken, options) {
    const apiSlug = slug.replace('/api/v6/', '');
    const url = atk.getUrl('v6', `/${apiSlug}`, options);
    const response = await atk
      .get(url, authToken)
      .catch((err) => Promise.reject(err));
    return Promise.resolve({ response });
  },
  registration(data, siteKey) {
    const url = atk.getUrl('v6', '/accounts/register', {}, siteKey);
    return atk.post(url, data).catch((err) => Promise.reject(err));
  },
  reviews: {
    index(siteKey = 'atk', options = {}) {
      const url = atk.getUrl(
        'v6',
        `/landing_pages/reviews/${siteKey}`,
        options,
        siteKey,
      );
      return atk.get(url);
    },
    indexSection(url) {
      return atk.get(url);
    },
  },
  landingPage: {
    index(docType, siteKey = 'atk', options = {}) {
      const url = atk.getUrl(
        'v6',
        `/landing_pages/${docType}/${siteKey}`,
        options,
        siteKey,
      );
      return atk.get(url);
    },
    seasonalIndex(seasonalType, options = {}) {
      const url = atk.getUrl(
        'v6',
        `/landing_pages/seasonal/${seasonalType}`,
        options,
        'atk',
      );
      return atk.get(url);
    },
    indexSection(url) {
      return atk.get(url);
    },
  },
  async sponsors(siteKey = 'atk') {
    const url = atk.getUrl('v4', '/underwriters', {
      'filters[site]': siteKey,
      summary: true,
      site_key: siteKey,
    });
    const { data } =
      (await atk.get(url).catch((err) => Promise.reject(err))) || {};
    return Promise.resolve(data);
  },
  async suggestions() {
    const url = atk.getUrl('v6', '/miso/user_to_products');
    const response = await atk
      .post(url, { start: 0, rows: 12 })
      .catch((err) => Promise.reject(err));
    // note, api is transitioning to data.documents, but is currently .documents
    const { data: { documents: dataDocs } = {}, documents } = response || {};
    const hits = dataDocs || documents;
    return Promise.resolve({ hits });
  },
  async trending(siteKey = 'atk') {
    const url = atk.getUrl('atk', `/api/analytics/trending/${siteKey}`);
    const response = await atk.get(url).catch((err) => Promise.reject(err));
    return Promise.resolve({ response, siteKey });
  },
  surveys: {
    async get(qsParamsObj) {
      const url = atk.getUrl(
        'v6',
        `/user_survey${qsParamsObj ? `?${qs.stringify(qsParamsObj)}` : ''}`,
      );
      return atk.get(url);
    },
    async misoPost(data) {
      const url = atk.getUrl('v6', '/user_survey');
      return atk.post(url, data);
    },
  },
  comments: {
    get(contentId, data) {
      const url = atk.getUrl(
        'comments',
        `/api/document_thread/${contentId}/document_thread_posts`,
        data,
        data.site_key,
      );
      return atk
        .get(url, null, { credientials: 'omit' })
        .catch((err) => Promise.reject(err));
    },
    create(threadId, data) {
      const url = atk.getUrl(
        'comments',
        `/api/document_thread/${threadId}/document_thread_posts`,
      );
      return atk.post(url, data).catch((err) => Promise.reject(err));
    },
    update(threadId, commentId, data) {
      const url = atk.getUrl(
        'comments',
        `/api/document_thread/${threadId}/document_thread_posts/${commentId}`,
      );
      return atk.patch(url, data).catch((err) => Promise.reject(err));
    },
  },
  ratings: {
    get(documentType, documentId) {
      const url = atk.getUrl('v6', `/ratings/${documentType}/${documentId}`);
      return atk.get(url).catch((err) => Promise.reject(err));
    },
    create(documentType, documentId, score) {
      const url = atk.getUrl(
        'v6',
        `/user_ratings/${documentType}/${documentId}`,
      );
      return atk.post(url, { score }).catch((err) => Promise.reject(err));
    },
    update(documentType, documentId, score) {
      const url = atk.getUrl(
        'v6',
        `/user_ratings/${documentType}/${documentId}`,
      );
      return atk.patch(url, { score }).catch((err) => Promise.reject(err));
    },
    deleteRating(documentType, documentId) {
      const url = atk.getUrl(
        'v6',
        `/user_ratings/${documentType}/${documentId}`,
      );
      return atk.delete(url).catch((err) => Promise.reject(err));
    },
  },
  zype: {
    player(videoId) {
      const url = `https://player.zype.com/embed/${videoId}.json?api_key=${process.env.ZYPE_PLAYER_API_KEY}`;
      return atk.get(url).catch((err) => Promise.reject(err));
    },
    async video(videoId) {
      const request = await fetch(
        `https://api.zype.com/videos/${videoId}?api_key=${zypeApiKey}`,
      ).catch((err) => console.error(err));
      const { response } = await request
        .json()
        .catch((err) => console.error(err));
      return response;
    },
    /** NOTE, this is not currently in use, but could be useful in the future, leaving for now */
    url: {
      playlist: {
        detail(id, path = '') {
          return `${zypePlaylistUri}/${id}${path}`;
        },
        hero() {
          return `${zypePlaylistUri}?active=true&per_page=6&category[Shows][]=America%27s%20Test%20Kitchen%20TV`;
        },
        grid({ category, page = 1 }, firstRender) {
          let pageSize = 4;
          let pageNumber = page;
          if (firstRender) {
            pageSize *= parseInt(page, 10);
            pageNumber = 1;
          }

          const params = qs.stringify(
            {
              active: true,
              category: {
                Shows: [category],
              },
              page: pageNumber,
              per_page: pageSize,
            },
            {
              arrayFormat: 'brackets',
              encode: false,
              filter: ['active', 'category', 0, 'Shows', 'page', 'per_page'],
            },
          );
          return `${zypePlaylistUri}?${params}`;
        },
      },
    },
  },
};

export default api;
