import { breakpoints as miseBreakpoints } from 'legacy-mise-ui/styles';

import {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
  variables,
} from './defaults';

const themeColors = {
  ...colors,
  cornflower: '#e6f1ff',
  malibu: '#69a0e1',
  maliblueish: '#659de1',
  tawny: '#dc3500',
};

const themeBreakpoints = {
  ...breakpoints,
  ...miseBreakpoints,
};

const themeLetterSpacing = {
  ...letterSpacing,
  xxxlg: '3.6px',
};

const cco = {
  siteKey: 'cco',
  themeColor: '#416490',
  checkbox: {
    checkboxBg: themeColors.white,
    checkboxBorder: themeColors.silver,
    checkboxBgHover: themeColors.whiteSmoke,
    checkboxBorderHover: themeColors.silver,
    checkboxBgChecked: themeColors.denim,
    checkboxBorderChecked: themeColors.denim,
    checkboxBgHoverChecked: themeColors.denim,
    checkboxBorderHoverChecked: themeColors.denim,
  },
  fonts: {
    ...fonts,
    clb: 'Clarendon Bold, sans-serif',
  },
  breakpoints: themeBreakpoints,
  contentMaxWidth: '1200px',
  colors: themeColors,
  fontSizes,
  letterSpacing: themeLetterSpacing,
  lineHeights,
  socialColors,
  variables: {
    ...variables,
    recipeDetailPaddingTop: '3.2rem',
    recipeDetailPaddingTopOnboarding: '6.4rem',
  },
};

export default cco;
