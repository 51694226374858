import analytics from 'lib/analytics';
import { RefObject } from 'react';

const onClickOutsideMegaMenu = (
  megaMenuTrigger: RefObject<HTMLButtonElement>,
) => {
  const type = megaMenuTrigger.current?.dataset.megaMenuType;
  analytics.track('MEGA_MENU_CLOSE', { type });
};

const recipeCategoryMenu = [
  {
    label: 'Recipe tabs',
    id: 'recipes',
    categories: [
      {
        id: '647e2e15e19ab3f5c6eaff88',
        isPromotional: true,
        sectionTitle: 'Main Ingredient',
        links: [
          {
            id: 'beans',
            label: 'Beans',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Beans',
          },
          {
            id: 'beef',
            label: 'Beef',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Beef',
          },
          {
            id: 'cheese',
            label: 'Cheese',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Cheese',
          },
          {
            id: 'chicken',
            label: 'Chicken',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Chicken',
          },
          {
            id: 'chocolate',
            label: 'Chocolate',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Chocolate',
          },
          {
            id: 'duck',
            label: 'Duck',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Duck',
          },
          {
            id: 'eggs',
            label: 'Eggs',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Eggs',
          },
          {
            id: 'fish-and-seafood',
            label: 'Fish & Seafood',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Fish%20%26%20Seafood',
          },
          {
            id: 'fruit',
            label: 'Fruit',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Fruit',
          },
          {
            id: 'game-birds',
            label: 'Game Birds',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Game%20Birds',
          },
          {
            id: 'grains',
            label: 'Grains',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Grains',
          },
          {
            id: 'lamb',
            label: 'Lamb',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Lamb',
          },
          {
            id: 'pasta',
            label: 'Pasta',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Pasta',
          },
          {
            id: 'pork',
            label: 'Pork',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Pork',
          },
          {
            id: 'potatoes',
            label: 'Potatoes',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Potatoes',
          },
          {
            id: 'rice',
            label: 'Rice',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Rice',
          },
          {
            id: 'turkey',
            label: 'Turkey',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Turkey',
          },
          {
            id: 'vegetables',
            label: 'Vegetables',
            url: '/recipes/browse?refinementList%5Bsearch_main_ingredient_list%5D%5B0%5D=Vegetables',
          },
        ],
      },
      {
        id: '647e2e15f967e293ad91f1ea',
        sectionTitle: 'Dish Type',
        links: [
          {
            id: 'beverages',
            label: 'Beverages',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Beverages',
          },
          {
            id: 'breads',
            label: 'Breads',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Breads',
          },
          {
            id: 'breakfast-and-brunch',
            label: 'Breakfast & Brunch',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Breakfast%20%26%20Brunch',
          },
          {
            id: 'brownies-and-bars',
            label: 'Brownies & Bars',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Brownies%20%26%20Bars',
          },
          {
            id: 'cakes',
            label: 'Cakes',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Cakes',
          },
          {
            id: 'candy',
            label: 'Candy',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Candy',
          },
          {
            id: 'casseroles',
            label: 'Casseroles',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Casseroles',
          },
          {
            id: 'condiments',
            label: 'Condiments',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Condiments',
          },
          {
            id: 'cookies',
            label: 'Cookies',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Cookies',
          },
          {
            id: 'dessert-pies',
            label: 'Dessert Pies',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Dessert%20Pies',
          },
          {
            id: 'frozen-desserts',
            label: 'Frozen Desserts',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Frozen%20Desserts',
          },
          {
            id: 'fruit-desserts',
            label: 'Fruit Desserts',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Fruit%20Desserts',
          },
          {
            id: 'marinades',
            label: 'Marinades',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Marinades',
          },
          {
            id: 'pizza',
            label: 'Pizza',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Pizza',
          },
          {
            id: 'puddings-custards-gelatins-souffles',
            label: 'Puddings, Custards, Gelatins, & Souffles',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Puddings%2C%20Custards%2C%20Gelatins%2C%20%26%20Souffles',
          },
          {
            id: 'quick-breads',
            label: 'Quick Breads',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Quick%20Breads',
          },
          {
            id: 'roasts',
            label: 'Roasts',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Roasts',
          },
          {
            id: 'rubs',
            label: 'Rubs',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Rubs',
          },
          {
            id: 'salads',
            label: 'Salads',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Salads',
          },
          {
            id: 'sandwiches',
            label: 'Sandwiches',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Sandwiches',
          },
          {
            id: 'sauces',
            label: 'Sauces',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Sauces',
          },
          {
            id: 'savory-pies-and-tarts',
            label: 'Savory Pies & Tarts',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Savory%20Pies%20%26%20Tarts',
          },
          {
            id: 'snacks',
            label: 'Snacks',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Snacks',
          },
          {
            id: 'soups',
            label: 'Soups',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Soups',
          },
          {
            id: 'stews',
            label: 'Stews',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Stews',
          },
          {
            id: 'tarts',
            label: 'Tarts',
            url: '/recipes/browse?refinementList%5Bsearch_dish_type_list%5D%5B0%5D=Tarts',
          },
        ],
      },
      {
        id: '647e2e1545bbe707cc7e6360',
        isPromotional: true,
        sectionTitle: 'Recipe Type',
        links: [
          {
            id: 'cast-iron-skillet',
            label: 'Cast-Iron Skillet',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Cast-Iron%20Skillet',
          },
          {
            id: 'for-two',
            label: 'For Two',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=For%20Two',
          },
          {
            id: 'gluten-free',
            label: 'Gluten Free',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Gluten%20Free',
          },
          {
            id: 'grilling-and-barbecue',
            label: 'Grilling & Barbecue',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Grilling%20%26%20Barbecue',
          },
          {
            id: 'light',
            label: 'Light',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Light',
          },
          {
            id: 'make-ahead',
            label: 'Make Ahead',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Make%20Ahead',
          },
          {
            id: 'pressure-cooker',
            label: 'Pressure Cooker',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Pressure%20Cooker',
          },
          {
            id: 'quick',
            label: 'Quick',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Quick',
          },
          {
            id: 'reduced-sugar',
            label: 'Reduced Sugar',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Reduced%20Sugar',
          },
          {
            id: 'slow-cooker',
            label: 'Slow Cooker',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Slow%20Cooker',
          },
          {
            id: 'vegan',
            label: 'Vegan',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Vegan',
          },
          {
            id: 'vegetarian',
            label: 'Vegetarian',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Vegetarian',
          },
          {
            id: 'weeknight',
            label: 'Weeknight',
            url: '/recipes/browse?refinementList%5Bsearch_recipe_type_list%5D%5B0%5D=Weeknight',
          },
        ],
      },
      {
        id: '647e2e153a7f4f819b5f9e69',
        sectionTitle: 'Cuisine',
        links: [
          {
            id: 'african',
            label: 'African',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=African',
          },
          {
            id: 'american',
            label: 'American',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=American',
          },
          {
            id: 'asian',
            label: 'Asian',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Asian',
          },
          {
            id: 'california',
            label: 'California',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=California',
          },
          {
            id: 'carribean',
            label: 'Caribbean',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Caribbean',
          },
          {
            id: 'central-and-south-american',
            label: 'Central & South American',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Central%20%26%20South%20American',
          },
          {
            id: 'chinese',
            label: 'Chinese',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Chinese',
          },
          {
            id: 'creole-and-cajun',
            label: 'Creole & Cajun',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Creole%20%26%20Cajun',
          },
          {
            id: 'eastern-european-and-german',
            label: 'Eastern European & German',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Eastern%20European%20%26%20German',
          },
          {
            id: 'french',
            label: 'French',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=French',
          },
          {
            id: 'great-britain',
            label: 'Great Britain',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Great%20Britain',
          },
          {
            id: 'greek',
            label: 'Greek',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Greek',
          },
          {
            id: 'indian',
            label: 'Indian',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Indian',
          },
          {
            id: 'irish',
            label: 'Irish',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Irish',
          },
          {
            id: 'italian',
            label: 'Italian',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Italian',
          },
          {
            id: 'japanese',
            label: 'Japanese',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Japanese',
          },
          {
            id: 'korean',
            label: 'Korean',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Korean',
          },
          {
            id: 'mexican',
            label: 'Mexican',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Mexican',
          },
          {
            id: 'mid-atlantic',
            label: 'Mid-Atlantic',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Mid-Atlantic',
          },
          {
            id: 'middle-eastern',
            label: 'Middle Eastern',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Middle%20Eastern',
          },
          {
            id: 'midwest',
            label: 'Midwest',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Midwest',
          },
          {
            id: 'new-england',
            label: 'New England',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=New%20England',
          },
          {
            id: 'southern',
            label: 'Southern',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Southern',
          },
          {
            id: 'southwest-tex-mex',
            label: 'Southwest (Tex-Mex)',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Southwest%20(Tex-Mex)',
          },
          {
            id: 'spanish-and-portuguese',
            label: 'Spanish & Portuguese',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Spanish%20%26%20Portuguese',
          },
          {
            id: 'thai',
            label: 'Thai',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Thai',
          },
          {
            id: 'vietnamese',
            label: 'Vietnamese',
            url: '/recipes/browse?refinementList%5Bsearch_cuisine_list%5D%5B0%5D=Vietnamese',
          },
        ],
      },
      {
        id: '647e2e15887c1da3af8163d6',
        isPromotional: true,
        sectionTitle: 'Course',
        links: [
          {
            id: 'appetizers',
            label: 'Appetizers',
            url: '/recipes/browse?refinementList%5Bsearch_course_list%5D%5B0%5D=Appetizers',
          },
          {
            id: 'desserts-or-baked-goods',
            label: 'Desserts or Baked Goods',
            url: '/recipes/browse?refinementList%5Bsearch_course_list%5D%5B0%5D=Desserts%20or%20Baked%20Goods',
          },
          {
            id: 'main-courses',
            label: 'Main Courses',
            url: '/recipes/browse?refinementList%5Bsearch_course_list%5D%5B0%5D=Main%20Courses',
          },
          {
            id: 'side-dishes',
            label: 'Side Dishes',
            url: '/recipes/browse?refinementList%5Bsearch_course_list%5D%5B0%5D=Side%20Dishes',
          },
        ],
      },
      {
        id: '647e2e156fff192672615b63',
        sectionTitle: 'Cookbook Collection',
        browseAll: {
          label: 'Browse All',
          url: '/books',
        },
        links: [
          {
            id: 'cooking-with-plant-based-meat',
            label: 'Cooking with Plant-Based Meat',
            url: '/books/cooking-with-plant-based-meat?q=&fR[search_browse_slugs][0]=cooking-with-plant-based-meat&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'healthy-and-delicious-instant-pot',
            label: 'Healthy and Delicious Instant Pot',
            url: '/books/healthy-and-delicious-instant-pot?q=&fR[search_browse_slugs][0]=healthy-and-delicious-instant-pot&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'master-of-the-grill',
            label: 'Master of the Grill',
            url: '/books/master-of-the-grill?q=&fR[search_browse_slugs][0]=master-of-the-grill&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'mediterranean-instant-pot',
            label: 'Mediterranean Instant Pot',
            url: '/books/mediterranean-instant-pot?q=&fR[search_browse_slugs][0]=mediterranean-instant-pot&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'healthy-air-fryer',
            label: 'Healthy Air Fryer',
            url: '/books/healthy-air-fryer?q=&fR[search_browse_slugs][0]=healthy-air-fryer&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'big-flavors-from-italian-america',
            label: 'Big Flavors From Italian America',
            url: '/books/big-flavors-from-italian-america?q=&fR[search_browse_slugs][0]=big-flavors-from-italian-america&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'one-hour-comfort',
            label: 'One-Hour Comfort',
            url: '/books/one-hour-comfort?q=&fR[search_browse_slugs][0]=one-hour-comfort&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'how-to-roast-everything',
            label: 'How to Roast Everything',
            url: '/books/how-to-roast-everything?q=&fR[search_browse_slugs][0]=how-to-roast-everything&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'foolproof-fish',
            label: 'Foolproof Fish',
            url: '/books/foolproof-fish?q=&fR[search_browse_slugs][0]=foolproof-fish&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'cooking-for-one',
            label: 'Cooking for One',
            url: '/books/cooking-for-one?q=&fR[search_browse_slugs][0]=cooking-for-one&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'cook-it-in-your-dutch-oven',
            label: 'Cook It In Your Dutch Oven',
            url: '/books/cook-it-in-your-dutch-oven?q=&fR[search_browse_slugs][0]=cook-it-in-your-dutch-oven&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'everything-chocolate',
            label: 'Everything Chocolate',
            url: '/books/everything-chocolate?q=&fR[search_browse_slugs][0]=everything-chocolate&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'sous-vide-for-everybody',
            label: 'Sous Vide for Everybody',
            url: '/books/sous-vide-for-everybody?q=&fR[search_browse_slugs][0]=sous-vide-for-everybody&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'spiced',
            label: 'Spiced',
            url: '/books/spiced?q=&fR[search_browse_slugs][0]=spiced&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'how-to-cocktail',
            label: 'How to Cocktail',
            url: '/books/how-to-cocktail?q=&fR[search_browse_slugs][0]=how-to-cocktail&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'one-pan-wonders',
            label: 'One-Pan Wonders',
            url: '/books/one-pan-wonders?q=&fR[search_browse_slugs][0]=one-pan-wonders&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'all-time-best-dinners-for-two',
            label: 'All-Time Best Dinners for Two',
            url: '/books/all-time-best-dinners-for-two?q=&fR[search_browse_slugs][0]=all-time-best-dinners-for-two&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'foolproof-preserving',
            label: 'Foolproof Preserving',
            url: '/books/foolproof-preserving?q=&fR[search_browse_slugs][0]=foolproof-preserving&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'naturally-sweet',
            label: 'Naturally Sweet',
            url: '/books/naturally-sweet?q=&fR[search_browse_slugs][0]=naturally-sweet&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'tasting-italy',
            label: 'Tasting Italy',
            url: '/books/tasting-italy?q=&fR[search_browse_slugs][0]=tasting-italy&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'air-fryer-perfection',
            label: 'Air Fryer Perfection',
            url: '/books/air-fryer-perfection?q=&fR[search_browse_slugs][0]=air-fryer-perfection&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'nutritious-and-delicious',
            label: 'Nutritious Delicious',
            url: '/books/nutritious-delicious?q=&fR[search_browse_slugs][0]=nutritious-delicious&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
          {
            id: 'cook-it-in-cast-iron',
            label: 'Cook It In Cast Iron',
            url: '/books/cook-it-in-cast-iron?q=&fR[search_browse_slugs][0]=cook-it-in-cast-iron&fR[search_document_klass][0]=recipe&fR[search_site_list][0]=atk',
          },
        ],
      },
    ],
  },
];

const recipeSupportingMenu = [
  {
    label: 'Recipes Supporting Menu',
    links: [
      {
        id: 'browse-all-recipes',
        label: 'Browse All Recipes',
        url: '/recipes/browse',
      },
    ],
  },
];

const reviewsCategoryMenu = [
  {
    label: 'Reviews tabs',
    id: 'reviews',
    categories: [
      {
        id: '647e2e15e19ab3f5c6eaff88',
        isPromotional: true,
        sectionTitle: 'Equipment Reviews',
        links: [
          {
            id: 'bakeware',
            label: 'Bakeware',
            url: '/equipment_reviews/browse/bakeware',
          },
          {
            id: 'cleaning-supplies',
            label: 'Cleaning Supplies',
            url: '/equipment_reviews/browse/cleaning_supplies',
          },
          {
            id: 'cookware',
            label: 'Cookware',
            url: '/equipment_reviews/browse/cookware',
          },
          {
            id: 'grilling',
            label: 'Grilling',
            url: '/equipment_reviews/browse/grilling',
          },
          {
            id: 'kitchen-basics',
            label: 'Kitchen Basics',
            url: '/equipment_reviews/browse/kitchen_basics',
          },
          {
            id: 'knives',
            label: 'Knives',
            url: '/equipment_reviews/browse/knives',
          },
          {
            id: 'small-appliances',
            label: 'Small Appliances',
            url: '/equipment_reviews/browse/small_appliances',
          },
          {
            id: 'tools-gadgets',
            label: 'Tools & Gadgets',
            url: '/equipment_reviews/browse/tools_gadgets',
          },
        ],
      },
      {
        id: '647e2e15f967e293ad91f1ea',
        isPromotional: true,
        sectionTitle: 'Ingredient Reviews',
        links: [
          {
            id: 'baking-ingredients',
            label: 'Baking Ingredients',
            url: '/taste_tests/browse/baking_ingredients',
          },
          {
            id: 'beverages',
            label: 'Beverages',
            url: '/taste_tests/browse/beverages',
          },
          {
            id: 'canned-goods',
            label: 'Canned Goods',
            url: '/taste_tests/browse/canned_goods',
          },
          {
            id: 'dairy-products',
            label: 'Dairy Products',
            url: '/taste_tests/browse/dairy_products',
          },
          {
            id: 'meat-and-poultry',
            label: 'Meat & Poultry',
            url: '/taste_tests/browse/meat_poultry',
          },
          {
            id: 'pantry-items',
            label: 'Pantry Items',
            url: '/taste_tests/browse/pantry_items',
          },
          {
            id: 'pasta-grains-beans',
            label: 'Pasta, Grains & Beans',
            url: '/taste_tests/browse/pasta_grains_beans',
          },
          {
            id: 'prepared-foods',
            label: 'Prepared Foods',
            url: '/taste_tests/browse/prepared_foods',
          },
          {
            id: 'spices-and-seasonings',
            label: 'Spices & Seasonings',
            url: '/taste_tests/browse/spices',
          },
        ],
      },
    ],
  },
];

const reviewsSupportingMenu = [
  {
    label: 'Reviews Supporting Menu',
    links: [
      {
        id: 'buying-guides',
        label: 'Buying Guides',
        url: '/buying_guides',
      },
      {
        id: 'browse-all-reviews',
        label: 'Browse All Reviews',
        url: '/reviews/browse',
      },
    ],
  },
];

export const recipeMegaMenu = {
  categoryMenu: recipeCategoryMenu,
  supportingMenu: recipeSupportingMenu,
  onClickOutside: onClickOutsideMegaMenu,
};

export const reviewsMegaMenu = {
  categoryMenu: reviewsCategoryMenu,
  supportingMenu: reviewsSupportingMenu,
  onClickOutside: onClickOutsideMegaMenu,
};
