'use client';

import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import { mixins, withSiteTheme } from '../../../../config/css';

const Relateds = styled.div.attrs({
  id: 'blueconic-relateds',
})`
  ${mixins.relatedDocumentCard}
`;

const BlueConicRelatedsTheme = {
  default: css`
    .related-flat-list {
      &__title {
        margin: 0 0 0.8rem;
      }
    }

    .document-browse-card {
      &__image-wrapper {
        align-items: center;
        display: flex;
      }
    }
    ${mixins.syncFlatList('default')}
  `,
  cio: css`
    ${mixins.syncFlatList('cio')}
  `,
};

const BlueConicRelateds = styled.div`
  ${withSiteTheme(BlueConicRelatedsTheme)}
`;

class BlueconicRelateds extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <BlueConicRelateds>
        <Relateds />
      </BlueConicRelateds>
    );
  }
}

export default BlueconicRelateds;
