import { useMemo } from 'react';
import queryString from 'query-string';
import { DeviceTypes } from './useGlobalHeaderConfig';
import siteNavLinkData, {
  ExtendedDomainSiteKey,
  GlobalHeaderLink,
} from './siteNavLinkData';

export interface SiteNavLink {
  id?: string;
  label: string;
  href: string;
  target?: string;
}

const useHeaderCodes = (
  deviceType: DeviceTypes,
  siteKey: ExtendedDomainSiteKey,
) => {
  const deviceTypeIndex = ['desktop', 'tablet', 'phone'].indexOf(deviceType);

  const headerLinks = useMemo(() => {
    const codesReducer = (linksWithCodes: GlobalHeaderLink) => {
      const {
        codes: { param, values },
        id,
        href,
        label,
        target,
      } = linksWithCodes;

      const query: {
        [key: string]: string;
      } = {
        ref: 'global_header',
      };

      if (values[`${siteKey}`]) {
        query[param] = values[`${siteKey}`][deviceTypeIndex];
      }

      const adjustedHref = queryString.stringifyUrl({
        url: href,
        query,
      });

      return {
        id,
        label,
        target,
        href: adjustedHref,
      };
    };

    return siteNavLinkData.map(codesReducer, [] as SiteNavLink[]);
  }, [deviceTypeIndex, siteKey]);

  return headerLinks;
};

export default useHeaderCodes;
