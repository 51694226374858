import { FocusEventHandler } from 'react';
import classNames from 'classnames';

import UserCtas, { UserCtasProps } from '../UserCtas/UserCtas';
import HamburgerMenu, {
  HamburgerMenuProps,
} from '../HamburgerMenu/HamburgerMenu';
import { LogoIcon } from '@americastestkitchen/mise/components/Icons/LogoIcon/index';
import SearchBar, { SearchBarOnSubmit } from '../SearchBar/SearchBar';

import styles from './mainNav.module.scss';
const cx = classNames.bind(styles);

export type MainNavProps = {
  brand: 'atk' | 'cco' | 'cio' | 'play';
  hamburgerMenu: HamburgerMenuProps;
  onFocusSearch: FocusEventHandler<HTMLInputElement>;
  onSubmitSearch: SearchBarOnSubmit;
  userCtas: UserCtasProps;
  isDarkMode?: boolean;
};

const MainNav = ({
  brand,
  hamburgerMenu,
  onFocusSearch,
  onSubmitSearch,
  userCtas,
  isDarkMode = false,
}: MainNavProps) => {
  const wrapperClassNames = cx(styles.wrapper, 'main-nav', {
    [styles.darkMode]: isDarkMode,
  });
  let homepageHref = '/';
  if (brand === 'cco') homepageHref = '/cookscountry';
  if (brand === 'cio') homepageHref = '/cooksillustrated';
  return (
    <div className={wrapperClassNames} data-testid="main-nav">
      <HamburgerMenu {...hamburgerMenu} />
      <div className={styles.inner}>
        <div className={styles.logoCtas}>
          <div className={styles.logo}>
            <a className={styles.logoLink} href={homepageHref}>
              <LogoIcon type="atk" />
            </a>
            {brand === 'play' && (
              <a
                aria-label="Play home"
                className={styles.playLogo}
                href="https://www.americastestkitchen.com/shows"
              >
                <svg
                  className="play-logo"
                  aria-label="Play"
                  xmlns="http://www.w3.org/2000/svg"
                  width="91.14"
                  height="20.216"
                  viewBox="0 0 91.14 20.216"
                >
                  <g transform="translate(-8678 -4191.784)">
                    <path
                      d="M6.02,2.892H8.148c1.9,0,2.352,1.4,2.352,3.052,0,1.344-.868,2.716-2.128,2.716H6.02ZM1.792,20H6.02V11.768H9.156c4.956,0,5.628-3.64,5.628-5.936,0-3.7-1.512-6.048-5.376-6.048H1.792Zm27.524,0V16.64H22.988V-.216H18.76V20Zm5.88,0,1.148-4.48h5.768L43.232,20h4.452L42.2-.216H36.624L31.024,20Zm6.02-7.588H37.184l1.932-8.736h.056ZM53.256,20h4.228V13L63.14-.216H58.828l-3.22,8.54L52.584-.216h-4.62L53.256,13Z"
                      transform="translate(8706 4192)"
                      fill="#fff"
                    ></path>
                    <g transform="translate(8678 4192)">
                      <path
                        d="M-33,540a10.011,10.011,0,0,1-10-10,10.011,10.011,0,0,1,10-10,10.011,10.011,0,0,1,10,10A10.011,10.011,0,0,1-33,540Zm-2.656-14.862a.5.5,0,0,0-.5.5v8.722a.5.5,0,0,0,.5.5.5.5,0,0,0,.264-.077l6.978-4.361A.5.5,0,0,0-28.18,530a.5.5,0,0,0-.235-.423l-6.978-4.361A.5.5,0,0,0-35.656,525.138Z"
                        transform="translate(43 -520)"
                        fill="#fff"
                      ></path>
                    </g>
                  </g>
                </svg>
              </a>
            )}
          </div>
          <UserCtas {...userCtas} />
        </div>
        <SearchBar
          inputId="global-header-main-search"
          onFocus={onFocusSearch}
          onSubmit={onSubmitSearch}
        />
      </div>
    </div>
  );
};

export default MainNav;
