import WidgetsAnalytics from './widgetUtils/analytics';
import FavoritablesManager from './manager';
import favoritesTemplates from './templates';
import widgetUtils from './widgetUtils/utils';

// DEV note - traceur doesn't like trailing commas - don't use them

/**
 * [FavoritesToggleApp description]
 * @param       {[type]} appEl [description]
 * @constructor
 */
function FavoritesToggleApp(appEl) {
  if (!appEl) return;
  window.onunload = function noop() {
    return;
  }; // disables bfcache
  this.appEl = appEl;

  this.triggerEl = null;
  this.favoritedClassName = 'favorited';
  this.openBodyClass = 'favorites-popover-open';
  this.managerDataAttr = 'data-favoritable-id';
  this.manager = new FavoritablesManager(
    this.managerDataAttr,
    this.favoritedClassName,
  );
  this.popoverEl = document.createElement('div');
  this.appEl.appendChild(this.popoverEl);
  document.body.addEventListener('click', this.handleBodyClick.bind(this));
  document.body.classList.add('favorites-index-page');

  const boundGet = this.getFavoritedDocuments.bind(this);
  this.getFavoritedDocuments = widgetUtils.debounce(boundGet, 250);
  window.addEventListener('jarvis-nav', this.getFavoritedDocuments, false);

  const boundUpdate = this.updateFavoritedElems.bind(this);
  this.updateFavoritedElems = widgetUtils.debounce(boundUpdate, 150);
  window.addEventListener(
    'favorites-update-elements',
    this.updateFavoritedElems,
    false,
  );
  try {
    dry.events.subscribe('user:get', this.getFavoritedDocuments);
  } catch (err) {
    setTimeout(this.getFavoritedDocuments, 150);
  }

  this.analytics = new WidgetsAnalytics();
}

FavoritesToggleApp.prototype = {
  getFavoritedDocuments() {
    this.manager.getFavoritedDocuments();
  },

  updateFavoritedElems() {
    this.manager.updateFavoritedElems();
  },

  getAnalyticsOptions() {
    const attr = this.managerDataAttr.replace('data-', '');
    const favId = this.triggerEl.dataset[widgetUtils.hyphenToCamelCase(attr)];
    const splitSpot = favId.lastIndexOf('_');
    const document_id = favId.substring(splitSpot + 1); // eslint-disable-line camelcase
    const document_type = favId.substring(0, splitSpot); // eslint-disable-line camelcase
    const document_title = this.triggerEl.dataset.documentTitle; // eslint-disable-line camelcase
    const documentLocation = this.triggerEl.dataset.documentLocation;

    let eventLocation = 'detail page';
    let document_url = document.location.href; // eslint-disable-line camelcase
    if (!this.triggerEl.closest('.detail__actions')) {
      if (documentLocation) {
        eventLocation = documentLocation;
      } else if (this.triggerEl.closest('#user-favorites-search')) {
        eventLocation = 'search widget';
      } else {
        const pathname = document.location.pathname;
        eventLocation =
          pathname === '/favorites' ? 'favorites' : 'document card';
      }
      document_url = this.getDocUrl(document_type, document_id); // eslint-disable-line camelcase
    }

    return {
      document_id,
      document_type:
        document_type !== 'buying_guide' ? document_type : 'buying-guide', // eslint-disable-line camelcase
      document_title,
      document_url,
      location: eventLocation,
    };
  },

  siteKeyDomainMap: {
    atk: 'americastestkitchen',
    cco: 'cookscountry',
    cio: 'cooksillustrated',
  },

  getDocUrl(documentType, documentId) {
    const originSite = this.triggerEl.dataset.originSite;
    let domainString = document.location.hostname;

    if (originSite) {
      const domain = this.siteKeyDomainMap[originSite];
      domainString = `${domainString.split('.')[0]}.${domain}.com`;
    }

    let pathDocType = `${documentType}s`;
    if (documentType === 'episode') {
      pathDocType = documentType;
    } else if (documentType === 'playlist') {
      pathDocType = 'skills';
    } else if (documentType === 'buying_guide') {
      pathDocType = 'buying-guides';
    }

    return `https://${domainString}/${pathDocType}/${documentId}`;
  },

  /**
   * Delegated event capturing clicks on elements with data-favoritable-id.
   * Captured clicks trigger a POST or DELETE xhr request
   * @param  {Event} evt
   * @return {Undefined}
   */
  handleBodyClick(evt) {
    let elem = evt.target.closest('[data-favoritable-id]');
    if (!elem) elem = evt.target.closest('[data-undo-delete-favorite-id]');
    let objectId = elem && widgetUtils.dataAttr(elem, 'favoritableId');
    let originSite = elem && widgetUtils.dataAttr(elem, 'originSite');
    const undoObjectId =
      elem && widgetUtils.dataAttr(elem, 'undoDeleteFavoriteId');
    const isFavorited =
      elem && elem.classList.contains(this.favoritedClassName);

    // undeleting needs to reset collections. This can be done via 'undo' in the popover
    // or by clicking the ribbon again while the popover is open
    // This is unexpectedly reached when we have an error popover!
    if (undoObjectId || (this.popoverOpen() && objectId && !isFavorited)) {
      let collections = widgetUtils.dataAttr(elem, 'collectionSlugs');
      // if link didn't have collections, look inside the popover
      if (!collections) {
        const popoverUndo = this.popoverEl.querySelector(
          '[data-collection-slugs]',
        );
        if (popoverUndo) {
          collections = popoverUndo.dataset.collectionSlugs;
        }
      }
      evt.preventDefault();
      evt.stopImmediatePropagation();
      this.manager.createFavorite(
        undoObjectId || objectId,
        originSite,
        (response) => {
          if (collections && response.data && response.data.user_favorite_id) {
            this.onUndoDeleteFavoriteSuccess(
              response.data.user_favorite_id,
              collections.split(','),
            );
          }
          this.analytics.trackEvent(
            'FAVORITE_ADDED',
            this.getAnalyticsOptions(),
          );
          this.removePopover();
        },
        this.showErrorPopover.bind(this),
      );
    } else if (objectId) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      this.triggerEl = elem;
      if (this.popoverOpen()) this.removePopover();
      objectId = elem.dataset.favoritableId;
      originSite = elem.dataset.originSite;
      const showUndo =
        isFavorited && this.triggerEl.dataset.undoDelete === 'true';
      const actionMethod = isFavorited ? 'destroyFavorite' : 'createFavorite';
      const trackingEvent = isFavorited
        ? 'FAVORITE_DESTROYED'
        : 'FAVORITE_ADDED';
      const successCallback = showUndo
        ? this.onDeleteWithUndoSuccess.bind(this, trackingEvent)
        : this.onToggleFavoriteSuccess.bind(this, trackingEvent);

      const mixpanelData = this.getAnalyticsOptions();
      this.analytics.trackEvent(trackingEvent, { ...mixpanelData });

      this.manager[actionMethod](
        objectId,
        originSite,
        successCallback,
        this.showErrorPopover.bind(this),
      );
    } else if (this.popoverOpen()) {
      this.removePopover();
    }
  },

  popoverOpen() {
    return this.popoverEl.childElementCount > 0;
  },

  onUndoDeleteFavoriteSuccess(favoriteId, collections) {
    if (favoriteId && collections && collections.length) {
      collections.map((slug) =>
        this.manager.addFavoriteCollectionItem(
          slug,
          favoriteId,
          undefined,
          widgetUtils.debounce(this.showErrorPopover.bind(this), 1000),
        ),
      );
    }
  },

  onDeleteWithUndoSuccess(eventName, response) {
    if (response.data) {
      this.boundShowUndoPopover = widgetUtils.debounce(
        this.showUndoPopover.bind(
          this,
          response.data.favoritable_id,
          response.data.favoritable_title,
          response.data.user_favorite_site,
          response.data.favorite_collections,
        ),
        150,
        true,
      );
      this.boundShowUndoPopover();
      const resizeEventName = this.isMobile ? 'orientationchange' : 'resize';
      window.addEventListener(resizeEventName, this.boundShowUndoPopover);
    }
  },

  /**
   * This doesn't exist on non-detail pages and throws without "?."
   * This causes an error popup that isn't visible on landing pages
   * When we see error popups the system thinks its in a collection popup and calls the wrong events,
   * it will think its still evaluating the previous document
   */
  onToggleFavoriteSuccess(eventName, res) {
    // when doc is saved, attach the returned favid from v6 response.
    this.appEl
      .querySelector('[data-manage-favoritable-id]')
      ?.setAttribute('data-manage-favoritable-v6', res.data.id);
  },

  fadeAndRemovePopover() {
    setTimeout(() => (this.popoverEl.style.opacity = '100'), 50); // eslint-disable-line
    setTimeout(() => (this.popoverEl.style.opacity = '0'), 10000); // eslint-disable-line
    setTimeout(() => this.removePopover.bind(this), 15000);
  },

  showUndoPopover(objectId, favoritableTitle, site, collections) {
    const innerHTML = `
      <p class="favorites-popover__undo" style="margin-bottom: 3px">
        <strong>${favoritableTitle}</strong> has been removed from your favorites.
      </p>
      <a
        href="#undo"
        data-undo-delete-favorite-id="${objectId}"
        ${collections ? `data-collection-slugs="${collections.join(',')}"` : ''}
        title="Undo"
      >
        Undo
      </a>
    `;
    this.popoverEl.innerHTML = favoritesTemplates.popoverTemplate(
      this.triggerEl,
      innerHTML,
      ['open'],
      225,
    );
    this.popoverEl.style.display = 'block';
    this.fadeAndRemovePopover();
    document.body.classList.add(this.openBodyClass);
  },

  removePopover() {
    this.popoverEl.innerHTML = '';
    this.popoverEl.style.display = 'none';
    if (typeof this.boundShowUndoPopover !== 'undefined') {
      const eventName = this.isMobile ? 'orientationchange' : 'resize';
      window.removeEventListener(eventName, this.boundShowUndoPopover);
    }
    document.body.classList.remove(this.openBodyClass);
  },

  showErrorPopover(text) {
    this.popoverEl.innerHTML = favoritesTemplates.popoverTemplate(
      this.triggerEl,
      'Oops, try again later',
      ['toggle-app-error'],
    );
    this.popoverEl.style.display = 'block';
    this.fadeAndRemovePopover();
    document.body.classList.add(this.openBodyClass);
    console.error(text); // eslint-disable-line no-console
  },
};

export default FavoritesToggleApp;
