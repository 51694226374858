import queryString from 'query-string';
import URL from 'url-parse';
import Brands from 'legacy-mise-ui/components/DesignTokens/Brands';

import utils from './index';

/**
 * Extract properties from the `productReference` or `documentReference.winner` ReviewSet.
 * @param  {Object} item Reviewable
 * @return {Object}      Props for the component
 */
const getProductData = (
  product,
  { queryParams = {}, siteKey = 'cio', tag = null, pathname = '' },
) => {
  const {
    affiliateOverrides = {},
    affiliates,
    asin,
    photo,
    price,
    testersComments,
    title,
  } = product;

  const data = {
    asin,
    price,
  };
  const siteKeyUpper = siteKey.toUpperCase();
  let buyingGuide = false;
  if (pathname && pathname?.includes('buying-guide')) {
    buyingGuide = true;
  }

  if (testersComments && testersComments.length)
    data.description = testersComments;
  if (title && title.length) data.title = title;
  if (photo && photo.public_id) {
    data.photo = { id: photo.public_id };
  }
  if (buyingGuide) {
    data.ctaTitle = 'Buy Now';
  } else {
    data.ctaTitle = 'Buy the Winner';
  }

  // from buying-guides and doppio
  let siteAffiliate;
  if (affiliates) {
    siteAffiliate = affiliates[siteKeyUpper] || affiliates[siteKey];
  }
  // from relateds on latte, find first affiliateOverride matching siteKeyUpper
  const affiliateSiteOverrides = affiliateOverrides.values || [];
  const affiliateSiteOverride = affiliateSiteOverrides.find(
    (o) => o.site === siteKeyUpper,
  );

  if (
    siteAffiliate &&
    (siteAffiliate?.url?.length > 0 || siteAffiliate?.affiliateUrl?.length > 0)
  ) {
    data.ctaUrl = siteAffiliate.url || siteAffiliate.affiliateUrl;
    data.affiliateName = siteAffiliate.name || siteAffiliate.affiliateName;
  } else if (
    affiliateSiteOverride &&
    affiliateSiteOverride.url &&
    affiliateSiteOverride.url.length > 0
  ) {
    const { affiliate, url } = affiliateSiteOverride;
    data.affiliateName = affiliate;

    if (affiliate === 'Amazon') {
      data.ctaUrl = `${url}?tag=atkequipland-20`;
    } else {
      data.ctaUrl = url;
    }
  } else if (asin) {
    data.ctaUrl = `https://www.amazon.com/dp/${asin}/?tag=${
      tag || `${siteKey}prodcoll2-20`
    }`;
    data.affiliateName = 'Amazon';
  } else {
    data.ctaUrl = null;
    data.affiliateName = null;
  }

  if (data.affiliateName && typeof Brands[data.affiliateName] !== 'undefined') {
    data.affiliateSvg = Brands[data.affiliateName];
  }

  // replace api `tag` parameter with queryString.tag
  if (
    queryParams &&
    queryParams.tag &&
    data.ctaUrl &&
    data.ctaUrl.indexOf('tag=') !== -1
  ) {
    const parsedUrl = queryString.parseUrl(data.ctaUrl);
    data.ctaUrl = `${parsedUrl.url}?${queryString.stringify({
      ...parsedUrl.query,
      tag: queryParams.tag,
    })}`;
  }

  return data;
};

/**
 * Extract properties from the `documentReference` from the ReviewSet.
 * @param  {Record<string, string>} item EquipmentReview
 * @return {Record<string, string | undefined>}      Props for the component
 */
const getDocData = (doc, options) => {
  const { docType, documentType, slug, title } = doc;

  let data = { documentType: documentType || docType };
  if (documentType && slug && slug.length > 0) {
    data.url = utils.getDocumentUrl(documentType, slug);
  }
  if (title && title.length > 0) data.subtitle = title;
  if (doc.winner) {
    data = {
      ...data,
      ...getProductData(doc.winner, options),
    };
  }

  return data;
};

/**
 * Extract properties from the top-level item. These values should always win
 * @param  {Object} item ContentCard or RelatedLink from Contentful
 * @return {Object}      Props for the component
 */
const getOverrideData = (item) => {
  const data = {};
  const {
    fields: { description, link, photo, subtitle, title, url },
  } = item;

  if (link) {
    const { title: linkTitle, url: linkUrl } = link.fields;
    if (linkTitle) data.ctaTitle = linkTitle;
    if (linkUrl) data.ctaUrl = linkUrl;
  }
  if (description) data.description = description;
  if (url) data.url = url;
  if (photo) data.photo = { id: photo.public_id };
  if (subtitle) data.subtitle = subtitle;
  if (title) data.title = title;

  return data;
};

/**
 * Extract final data from a content item.
 * Order of prescedence:
 *   1) Override fields from Contentful UI
 *   2) productReference - override product takes prescedence over documentReference.winner
 *   3) documentReference - url, winner props
 * @param  {Object}  item                ContentCard or RelatedLink from Contentful
 * @param  {Object}  [options=displayPrice=true, queryParams={}, siteKey='cio'}] card options
 * @return {Object}                      Final props for the Component
 */
const getReviewableData = (item, options) => {
  const {
    fields: { documentReference, productReference },
  } = item;

  let data = {};
  // default values come from the documentReference
  if (documentReference) {
    data = {
      ...data,
      ...getDocData(documentReference, options),
    };
  }

  // product data can override defaults
  if (productReference) {
    data = {
      ...data,
      ...getProductData(productReference, options),
    };
  }

  // override fields get the final say
  data = {
    ...data,
    ...getOverrideData(item),
  };

  let host;
  data.siteKey = options.siteKey;
  // set the siteKey based on the url
  const { hostname, pathname } = new URL(data.url);
  if (hostname) {
    host = utils.hostnameToDomain(hostname);
    data.siteKey = utils.hostToSiteKey(host);
  }
  // if this is a atk|cio|cco url, get the type and id of the doc
  if (
    // if atk|cco|cio hostname with pathname
    (host && pathname) ||
    // or relative pathname
    (data.url && data.url.charAt(0) === '/' && pathname)
  ) {
    // `/recipes/8125-chocolate-crinkle-cookies` -> ['recipes', '8125-chocolate-crinkle-cookies']
    const [collectionType, slug] = pathname.substring(1).split('/');
    data.documentType = utils.collectionTypeToDocType[collectionType];
    if (slug) {
      data.documentId = /[\d]/.test(slug.charAt(0)) ? slug.split('-')[0] : slug;
    }
  }

  return data;
};

const reviewSet = {
  getDocData,
  getOverrideData,
  getProductData,
  getReviewableData,
};

export default reviewSet;
