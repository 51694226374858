import styles from './headerPlayIcon.module.scss';

const HeaderPlayIcon = () => {
  return (
    <div className={styles.playIconWrapper}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 89 89"
        role="img"
        aria-hidden="true"
        focusable="false"
      >
        <path
          fill="#3d3d3d"
          fillRule="evenodd"
          d="M33 65V23l36 21-36 21zM44.5 0C19.925 0 0 19.924 0 44.5S19.924 89 44.5 89C69.078 89 89 69.076 89 44.5S69.077 0 44.5 0z"
        ></path>
      </svg>
    </div>
  );
};

export default HeaderPlayIcon;
