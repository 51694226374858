import {
  GET_PLAY_HOME_PAGE_FULFILLED,
  GET_PLAY_HOME_PAGE_PENDING,
  GET_PLAY_HOME_PAGE_REJECTED,
} from '../actions/play';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  slug: null,
  items: null,
  status: c.DEFAULT,
};

const play = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLAY_HOME_PAGE_PENDING:
      return {
        ...state,
        status: c.LOADING,
      };

    case GET_PLAY_HOME_PAGE_REJECTED:
      return {
        ...state,
        status: c.ERROR,
      };

    case GET_PLAY_HOME_PAGE_FULFILLED:
      return {
        ...action.payload.response,
        status: c.READY,
      };

    default:
      return state;
  }
};

export default play;
