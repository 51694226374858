import {
  GET_TRENDING_FULFILLED,
  GET_TRENDING_PENDING,
  GET_TRENDING_REJECTED,
} from '../actions/trending';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

const siteData = {
  documents: [],
  status: c.DEFAULT,
};

export const initialState = {
  data: {
    atk: {
      ...siteData,
    },
    cco: {
      ...siteData,
    },
    cio: {
      ...siteData,
    },
  },
};

const trending = (state = initialState, action) => {
  let response;
  let siteKey;

  switch (action.type) {
    case GET_TRENDING_PENDING:
      siteKey = action.payload.siteKey;
      return siteKey
        ? {
            data: {
              ...state.data,
              [siteKey]: {
                ...siteData,
                status: c.LOADING,
              },
            },
          }
        : state;

    case GET_TRENDING_REJECTED:
      siteKey = action.payload.siteKey;
      return siteKey
        ? {
            data: {
              ...state.data,
              [siteKey]: {
                ...siteData,
                status: c.ERROR,
              },
            },
          }
        : state;

    case GET_TRENDING_FULFILLED:
      siteKey = action.payload.siteKey;
      response = action.payload.response;
      if (Array.isArray(response)) {
        response = response.filter((d) => d.document_type === 'recipe');
      }
      return siteKey
        ? {
            data: {
              ...state.data,
              [siteKey]: {
                documents: response,
                status: c.READY,
              },
            },
          }
        : state;

    default:
      return state;
  }
};

export default trending;
