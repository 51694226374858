import api from '../lib/api';

export const GET_COLLECTION_PENDING = 'GET_COLLECTION_PENDING';
export const GET_COLLECTION_FULFILLED = 'GET_COLLECTION_FULFILLED';
export const GET_COLLECTION_REJECTED = 'GET_COLLECTION_REJECTED';

const defaultOptions = {
  pageSize: 250,
  prefetch: false,
};

export const getCollectionData = (contentId, authToken, options = {}) => {
  const opts = {
    ...defaultOptions,
    ...options,
  };
  return {
    type: 'GET_COLLECTION',
    payload: {
      data: opts,
      promise: api.collection(contentId, authToken, opts),
    },
  };
};
