export const FF_MISE_REVIEWS = process.env.NEXT_PUBLIC_FF_MISE_REVIEWS
  ? process.env.NEXT_PUBLIC_FF_MISE_REVIEWS === 'true'
  : false;

export const FF_REMOVE_ARTICLE_COMMENTS = process.env
  .NEXT_PUBLIC_FF_REMOVE_ARTICLE_COMMENTS
  ? process.env.NEXT_PUBLIC_FF_REMOVE_ARTICLE_COMMENTS === 'true'
  : false;

export const FF_RECIPE_TESTER_ARTICLE = process.env
  .NEXT_PUBLIC_FF_RECIPE_TESTER_ARTICLE
  ? process.env.NEXT_PUBLIC_FF_RECIPE_TESTER_ARTICLE === 'true'
  : false;

export const FF_THANKSGIVING_HOLIDAY = process.env
  .NEXT_PUBLIC_FF_THANKSGIVING_HOLIDAY
  ? process.env.NEXT_PUBLIC_FF_THANKSGIVING_HOLIDAY === 'true'
  : false;
