import React from 'react';
import cx from 'classnames';
import styles from './LinkList.module.scss';
import { GlobalHeaderLink } from '../shared/types';
import HeaderPlayIcon from '../HeaderPlayIcon/HeaderPlayIcon';
import { Typography } from '@americastestkitchen/mise/components/Typography/index';

export interface LinkListProps {
  ariaLabel: string;
  includeAnalytics?: boolean;
  list: GlobalHeaderLink[];
  magazineAd?: {
    altText: string;
    href: string;
    imgSrc: string;
  };
  textAlignment?: 'left' | 'center';
}

export const LinkList: React.FC<LinkListProps> = ({
  ariaLabel,
  includeAnalytics,
  list,
  magazineAd,
  textAlignment = 'center',
}: LinkListProps) => {
  return (
    <nav
      aria-label={ariaLabel}
      className={styles.link_list}
      data-testid="link-list"
    >
      <ul className={cx(styles.list)}>
        {list?.map((item, index) => (
          <li key={index} className={styles.item}>
            <a
              className={cx(styles.link, {
                'site-nav-link': includeAnalytics,
                [styles.leftAlign]: textAlignment === 'left',
                [styles.link_active]: item.isActive,
              })}
              href={item.href}
            >
              <Typography size="sm" type="open" weight="bold">
                {item.showPlayIcon && <HeaderPlayIcon />}
                {item.label}
              </Typography>
            </a>
            {item.label === 'Magazine' && magazineAd ? (
              <a
                aria-label={magazineAd.altText}
                className={styles.magazineAdLink}
                href={magazineAd.href}
              >
                <img alt="" src={magazineAd.imgSrc} />
              </a>
            ) : null}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default LinkList;
