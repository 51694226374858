import algolia from '../lib/algolia';

export const GET_BOOK_RECIPES_PENDING = 'GET_BOOK_RECIPES_PENDING';
export const GET_BOOK_RECIPES_FULFILLED = 'GET_BOOK_RECIPES_FULFILLED';
export const GET_BOOK_RECIPES_REJECTED = 'GET_BOOK_RECIPES_REJECTED';

export const getBookRecipes = (data) => ({
  type: 'GET_BOOK_RECIPES',
  payload: {
    promise: algolia.getBookRecipes(data),
  },
});
