const widgetUtils = {
  debounce: (func, wait, immediate) => {
    let timeout;
    return function debounced() {
      const args = arguments; //eslint-disable-line
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(this, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(this, args);
    };
  },
  dataAttr: (el, attr) =>
    typeof el.dataset[attr] !== 'undefined' ? el.dataset[attr] : null,
  hyphenToCamelCase: (hyphen) =>
    hyphen.replace(/-([a-z])/g, (match) => match[1].toUpperCase()),
  getCookie: (cname) => {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  getSiteKey: (originSite) => {
    if (originSite && originSite !== 'cookbook_collection') {
      return originSite;
    }
    const pathname = window.location.pathname;
    if (pathname.startsWith('/cooksillustrated')) return 'cio';
    if (pathname.startsWith('/cookscountry')) return 'cco';
    return 'atk';
  },
  getOuterHeight(el) {
    let height = el.offsetHeight;
    const style = getComputedStyle(el);

    height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    return height;
  },
  getPosition: (el) => {
    let xPos = 0;
    let yPos = 0;

    while (el) {
      if (el.tagName === 'BODY') {
        // deal with browser quirks with body/window/document and page scroll
        const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
        const yScroll = el.scrollTop || document.documentElement.scrollTop;

        xPos += el.offsetLeft - xScroll + el.clientLeft;
        yPos += el.offsetTop - yScroll + el.clientTop;
      } else {
        // for all other non-BODY elements
        xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
        yPos += el.offsetTop - el.scrollTop + el.clientTop;
      }

      el = el.offsetParent;
    }
    return {
      x: xPos,
      y: yPos,
    };
  },
  // https://davidwalsh.name/query-string-javascript
  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); // eslint-disable-line
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'); // eslint-disable-line
    const results = regex.exec(document.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  isFunction(val) {
    return typeof val === 'function';
  },
  isString(val) {
    return typeof val === 'string' || val instanceof String;
  },
  isWithinDateWindow(startTime, endTime) {
    if (!startTime || !endTime) return false;
    const now = new Date().getTime();
    return startTime <= now && now <= endTime;
  },
  isDefined(val) {
    return typeof val !== 'undefined';
  },
  isEmail(value) {
    // https://github.com/ansman/validate.js/blob/master/validate.js
    const re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i; // eslint-disable-line
    return re.exec(value);
  },
  /**
   * poor man's script loader with callback
   * @param  {[String]}  url       Url of script to be loaded
   * @param  {Function}  isLoaded  Callback - should return true if url has been loaded
   * @param  {Function}  onLoaded  Callback - called when isLoaded() returns true
   * @return {void}
   */
  loadScriptFile(url, isLoaded, onLoaded) {
    const interval = 250;
    const loadAttemptsMax = 10;
    let loadAttempts = 0;

    const gads = document.createElement('script');
    gads.async = true;
    gads.type = 'text/javascript';
    const useSSL = document.location.protocol === 'https:';
    const protocol = useSSL ? 'https:' : 'http:';
    gads.src = `${protocol}//${url}`;
    const node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(gads, node);

    const scriptChecker = () => {
      if (isLoaded()) {
        onLoaded();
      } else if (loadAttempts < loadAttemptsMax) {
        setTimeout(scriptChecker, interval);
      }
      loadAttempts += 1;
    };
    if (!isLoaded()) setTimeout(scriptChecker, interval);
  },
  searchableFacets: {
    article: 'Articles',
    cookbook: 'Cookbooks',
    course: 'Courses',
    episode: 'Episodes',
    equipment_review: 'Equipment Reviews',
    how_to: 'How Tos',
    menu: 'Menus',
    recipe: 'Recipes',
    taste_test: 'Taste Tests',
    video: 'Clips',
  },
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  },
  scroll: {
    disable() {
      const $body = document.querySelector('body');
      const scrollPosition = window.pageYOffset;
      $body.style.overflow = 'hidden';
      $body.style.position = 'fixed';
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = '100%';

      return () => {
        $body.style.removeProperty('overflow');
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
      };
    },
  },
};

function WidgetState(initialState) {
  const state = initialState;
  this.get = function get(name) {
    return state[name];
  };
  this.set = function set(name, value) {
    const withPropName = this.isString(name);
    if (!withPropName && this.isPlainObject(name)) {
      Object.entries.forEach(({ name, value }) => {
        state[name] = value;
      });
    } else {
      state[name] = value;
    }
  };
  this.freeze = function data() {
    return Object.freeze(Object.assign({}, state));
  };
}

WidgetState.prototype = {
  isString(val) {
    return typeof val === 'string' || val instanceof String;
  },
  isPlainObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  },
};

export { WidgetState };

export default widgetUtils;
