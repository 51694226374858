import cx from 'classnames';
import {
  UtilityIcon,
  UtilityIconType,
} from '@americastestkitchen/mise/components/Icons/UtilityIcon/index';
import React, { useState, ReactNode, useRef, useEffect } from 'react';
import styles from './Disclosure.module.scss';
import useClickOutside from '@americastestkitchen/mise/lib/hooks/useClickOutside';

export interface DisclosureProps {
  title: string;
  children: ReactNode;
  activeIcon: UtilityIconType;
  icon: UtilityIconType;
  buttonClass?: string;
  iconClass?: string;
  id: string;
}

export const Disclosure: React.FC<DisclosureProps> = ({
  title,
  children,
  buttonClass,
  iconClass,
  activeIcon,
  icon,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const toggleDisclosure = () => setIsOpen(!isOpen);
  const closeDisclosure = () => setIsOpen(false);

  useClickOutside(ref, closeDisclosure);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeDisclosure();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const buttonClassName = buttonClass ? buttonClass : 'default-class';
  const iconClassName = iconClass ? iconClass : 'default-class';

  return (
    <div ref={ref} className={styles.disclosure}>
      <button
        aria-label={title}
        aria-expanded={isOpen}
        aria-controls={id}
        onClick={toggleDisclosure}
        className={buttonClassName}
      >
        <span>{title}</span>
        <UtilityIcon
          className={cx(styles.icon, iconClassName)}
          type={isOpen ? activeIcon : icon}
        />
      </button>
      <div id={id} hidden={!isOpen}>
        {children}
      </div>
    </div>
  );
};

export default Disclosure;
