import { createContext, useContext } from 'react';

export type DeviceType = 'phone' | 'tablet' | 'desktop';
export type DomainSiteKey = 'atk' | 'cio' | 'cco';
export type Subdomain =
  | 'www'
  | 'www-test'
  | 'www-dev'
  | 'www-staging'
  | 'www-staging2'
  | 'www-staging3';

export type AppState = {
  deviceType: DeviceType;
  domainSiteKey: DomainSiteKey;
  disableTracking: boolean;
  isAuthenticated: boolean;
  pageProps: Record<string, any>;
  queryParams: Record<string, string>;
  url: string;
  pathname: string;
  subdomain: Subdomain;
  serversideUserToken: string;
};

const appContext = createContext<AppState>({
  deviceType: 'desktop',
  disableTracking: false,
  domainSiteKey: 'atk',
  isAuthenticated: false,
  pageProps: {},
  pathname: '',
  queryParams: {},
  subdomain: 'www',
  url: '',
  serversideUserToken: '',
});

appContext.displayName = 'AppContext';

export const AppProvider = appContext.Provider;

/**
 * Passes by context both page props from getInitialProps of pages and the
 *  getInitialProps of _app.
 */
export default function useAppContext() {
  return useContext(appContext);
}
