import {
  useRef,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  RefObject,
  SetStateAction,
} from 'react';
import useOnDocumentBlur from './useOnDocumentBlur';
import useTabTrapping from './useTabTrapping';

export interface DrawerHook {
  triggerProps: {
    ref: RefObject<HTMLButtonElement>;
    'aria-expanded': boolean;
    'aria-controls': string;
    onClick: () => void;
  };
  drawerProps: {
    ref: RefObject<HTMLDivElement>;
    id: string;
  };
  expanded: boolean;
  toggleDrawer: () => void;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

export function useDrawer(
  id: string,
  onOutsideClickCallback?: (triggerRef: RefObject<HTMLElement>) => void,
): DrawerHook {
  const drawerRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleDrawer = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);

  // close if the user changes tabs
  useOnDocumentBlur(() => {
    setExpanded(false);
    if (expanded) {
      triggerRef?.current?.focus();
    }
  });

  // handle tab trapping
  useTabTrapping({ ref: drawerRef, isElementFocused: expanded });

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setExpanded(false);
      triggerRef?.current?.focus();
    }
  };

  // close if the user hits the escape key
  useEffect(() => {
    if (expanded) document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [expanded]);

  // close if the user clicks outside of the drawer
  const handleOutsideClick = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node) &&
        !triggerRef.current?.contains(event.target as Node) &&
        event.target !== triggerRef.current
      ) {
        // some trigger elements have nested spans or icons, these need to be ignored.
        const isChildNode = Array.from(
          triggerRef.current?.childNodes || [],
        ).some((childNode) => childNode === event.target);

        if (!isChildNode) {
          setExpanded(false);
        }

        onOutsideClickCallback && onOutsideClickCallback(triggerRef);
      }
    },
    [setExpanded, onOutsideClickCallback],
  );

  // Only apply outside click handlers when drawer is opened.
  // Previously if there were X drawers on a given page, there
  // would be X click event listeners triggering on every click
  // a user makes on a page, even with no drawers open.
  useEffect(() => {
    if (expanded) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('touchstart', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [expanded, handleOutsideClick]);

  return {
    triggerProps: {
      ref: triggerRef,
      'aria-expanded': expanded,
      'aria-controls': id,
      onClick: toggleDrawer,
    },
    drawerProps: {
      ref: drawerRef,
      id: id,
    },
    expanded,
    toggleDrawer,
    setExpanded,
  };
}
