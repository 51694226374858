import api from '../lib/api';

export const GET_TRENDING_PENDING = 'GET_TRENDING_PENDING';
export const GET_TRENDING_FULFILLED = 'GET_TRENDING_FULFILLED';
export const GET_TRENDING_REJECTED = 'GET_TRENDING_REJECTED';

export const getTrending = (siteKey) => ({
  type: 'GET_TRENDING',
  payload: {
    data: { siteKey },
    promise: api.trending(siteKey),
  },
});
