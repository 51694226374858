'use client';

import gtmParts from 'react-google-tag-manager';
import React from 'react';
import PropTypes from 'prop-types';

class GoogleTagManager extends React.Component {
  shouldComponentUpdate() {
    // once we render we never want to re-render
    return false;
  }

  render() {
    const { additionalEvents, dataLayerName, gtmId } = this.props;
    const gtm = gtmParts({
      id: gtmId,
      dataLayerName,
      additionalEvents,
    });

    return gtm.noScriptAsReact();
  }
}

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired,
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.object,
};

GoogleTagManager.defaultProps = {
  additionalEvents: {},
  dataLayerName: 'dataLayer',
};

export default GoogleTagManager;
