import {
  GET_COLLECTION_FULFILLED,
  GET_COLLECTION_PENDING,
  GET_COLLECTION_REJECTED,
} from '../actions/collections';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  documents: {},
  status: c.DEFAULT,
};

export const filterCollectionItems = ({ metaData }) =>
  metaData && metaData.photo && metaData.photo.id;

const collections = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLECTION_PENDING:
      if (!action.payload.prefetch) {
        return {
          ...state,
          status: c.LOADING,
        };
      }
      return state;

    case GET_COLLECTION_REJECTED:
      return {
        ...state,
        status: c.ERROR,
      };

    case GET_COLLECTION_FULFILLED:
      const { contentId, response } = action.payload;
      let content = response;
      if (Array.isArray(content)) {
        content = content.filter(filterCollectionItems);
      }
      if (content?.documentType === 'kids_home') {
        // for kids home page, add the upsell box when appropriate
        if (content.upsell) {
          content.items.splice(1, 0, { componentType: 'kidsBoxUpsell' });
        }
        // kids home page, drop the first trivia card
        const flipCards = content.items?.[0]?.items?.[1]?.items;
        if (
          flipCards &&
          flipCards.length > 1 &&
          flipCards?.[0].fields?.cardLabel === 'trivia'
        ) {
          flipCards.splice(0, 1, {
            id: 'kidsActivitiesOverride',
            documentType: 'kids_recipe',
            asHero: true,
            zone: 'hero',
            visualStyle: 'square',
            title: 'glazed sugar cookies',
            metaData: {
              photo: {
                id: 'ATK Kids/Subscription_Boxes_Young_Chefs_Club/2020/12_Cookies_Box/Glazed_Sugar_Cookies/SFS_IcedCookies-37',
              },
              textOverlayImage: {
                id: 'ATK Kids/Subscription_Boxes_Young_Chefs_Club/2020/12_Cookies_Box/Glazed_Sugar_Cookies/text-GlazedSugarCookie_0',
              },
            },
            slug: 'glazed-sugar-cookies',
            label: 'Recipe',
            componentType: 'DocumentCard',
          });
        }
      }
      return {
        ...state,
        documents: {
          ...state.documents,
          [contentId]: content,
        },
        status: c.READY,
      };

    default:
      return state;
  }
};

export default collections;
