import {
  breakpoints as miseBreakpoints,
  color as miseColors,
  spacing as miseSpacing,
} from 'legacy-mise-ui/styles';

import {
  breakpoints,
  checkbox,
  contentMaxWidth,
  colors,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
  variables,
} from './defaults';

const themeColors = {
  ...colors,
  ...miseColors,
  asphalt: '#191919',
  jet: '#080808',
};

const themeFonts = {
  ...fonts,
};

const themeBreakpoints = {
  ...breakpoints,
  ...miseBreakpoints,
};

const menuTransition = 'transform 0.3s ease-in-out';
const menuFooterHeight = 88;
const phoneBrowserToolbarClearance = 114;
const tabletBrowserToolbarClearance = 65;

const playTheme = {
  siteKey: 'play',
  themeColor: '#d73a15',
  fonts: themeFonts,
  mode: 'dark',
  breakpoints: themeBreakpoints,
  contentMaxWidth,
  colors: themeColors,
  checkbox,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors: {
    ...socialColors,
    emailHover: '#6ba6aa',
  },
  spacing: miseSpacing,
  variables: {
    ...variables,
    menuTransition,
    menuFooterHeight,
    phoneBrowserToolbarClearance,
    tabletBrowserToolbarClearance,
    phoneMenuFooterHeight: menuFooterHeight + phoneBrowserToolbarClearance,
    tabletMenuFooterHeight: menuFooterHeight + phoneBrowserToolbarClearance,
    recipeDetailPaddingTop: '0.4rem',
    recipeDetailPaddingTopOnboarding: '6.4rem',
  },
};

export default playTheme;
