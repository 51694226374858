import {
  legacy_createStore as createStore,
  AnyAction,
  applyMiddleware,
} from 'redux';

import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import combinedReducer from 'config/redux/rootReducer';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

export interface State {
  tick: string;
}

// TODO: type state for redux
const reducer = (state: any, action: AnyAction) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    // Here we can preserve anything from clientside navigation like below
    // if (state.count.count) nextState.count.count = state.count.count;
    return nextState;
  } else {
    return combinedReducer(state, action);
  }
};

// create a makeStore function
const makeStore = () => {
  const store = createStore(reducer, applyMiddleware(promise, thunk));
  return store;
};

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, {
  debug: false,
});
