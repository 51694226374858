import {
  GET_QUICK_VIEW_RELATEDS_PENDING,
  GET_QUICK_VIEW_RELATEDS_FULFILLED,
  GET_QUICK_VIEW_RELATEDS_REJECTED,
  SET_QUICK_VIEW_CONTENT,
  TOGGLE_QUICK_VIEW,
} from '../actions/quickView';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  content: {
    data: null,
    relateds: [],
    type: null,
  },
  isOpen: false,
  status: c.DEFAULT,
};

const quickView = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUICK_VIEW_RELATEDS_PENDING:
      return {
        ...state,
        status: c.LOADING,
      };
    case GET_QUICK_VIEW_RELATEDS_REJECTED:
      return {
        ...state,
        status: c.ERROR,
      };
    case GET_QUICK_VIEW_RELATEDS_FULFILLED:
      return {
        ...state,
        content: {
          ...state.content,
          relateds: action.payload,
        },
        status: c.READY,
      };
    case SET_QUICK_VIEW_CONTENT:
      return {
        ...state,
        content: { ...action.payload.content },
      };

    case TOGGLE_QUICK_VIEW:
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };

    default:
      return state;
  }
};

export default quickView;
