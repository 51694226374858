/* eslint-disable no-console */
import queryString from 'query-string';
import atk from './atk/atk';

const defaultFavorites = {
  lastUpdated: new Date(0),
  fetching: false,
  data: {
    css: null,
    js: null,
  },
};

const favoritesTypeFormatted = {
  article: 'Cms::Page',
  buying_guide: 'ProductCollection',
  buyingGuide: 'ProductCollection',
  'cms::page': 'Article',
  episode: 'Episode',
  equipment_review: 'ReviewSet',
  how_to: 'HowTo',
  recipe: 'Recipe',
  taste_test: 'ReviewSet',
  playlist: 'Playlist',
  video: 'Video',
  guide: 'Cms::Page',
};

// Get a list of items favorited by the user
const getUserFavorites = async (qsParamsObj) => {
  const urlPath = qsParamsObj.collection_slug
    ? `favorite_collections`
    : 'user_favorites/results';
  const url = atk.getUrl(
    'v6',
    `/${urlPath}${qsParamsObj ? `?${queryString.stringify(qsParamsObj)}` : ''}`,
  );
  return atk.get(url);
};

const getRecentlyFavorited = () => {
  const url = atk.getUrl('v6', '/user_favorites/most_recent');
  return atk.get(url);
};

const getRecentlyFavoritedLimit = (limit) => {
  const url = atk.getUrl('v6', `/user_favorites/most_recent?limit=${limit}`);
  return atk.get(url);
};

const getSmartCollection = (dishType) => {
  const url = atk.getUrl(
    'v6',
    `/miso/user_to_attributes${dishType ? `/${dishType}` : '_carousel'}`,
  );
  return atk.get(url);
};

// Get collections and facets data.
const getMetaData = async (siteKey) => {
  const metaDataUrl = atk.getUrl(
    'v6',
    `/user_favorites_meta_data?site_key=${siteKey}`,
  );
  return atk.get(metaDataUrl);
};

// remove a favorited item
const destroyFavorite = async (numberedId) => {
  const url = atk.getUrl('v6', `/user_favorites/${numberedId}`);
  return atk.delete(url, {});
};
// favorite an item
const postFavorite = async (browseSlug, objectId, favoritedFrom) => {
  const numberedId = objectId.replace(/\D/g, '');
  const favoriteType = favoritesTypeFormatted[browseSlug];
  const url = atk.getUrl('v6', '/user_favorites');
  return atk.post(url, {
    site_key: favoritedFrom,
    favoritable_id: numberedId,
    favoritable_type: favoriteType,
  });
};

// get list of collections associated with a favorited item
const getFavoriteCollections = async (browseSlug, objectId) => {
  const url = atk.getUrl(
    'v6',
    `/user_favorites/${browseSlug}/${objectId}/collections`,
  );
  return atk.get(url);
};

const getDocCollectionsActiveKey = async (browseSlug, objectId, siteKey) => {
  try {
    const docCollections = await getFavoriteCollections(browseSlug, objectId);
    const allCollections = await getMetaData(siteKey);

    if (allCollections && docCollections) {
      const transFormedCollectionData = allCollections['meta_data'][
        'collections'
      ].map((collection) => {
        const docCollecion = docCollections.find(
          (c) => collection.name === c.name,
        );
        return {
          ...collection,
          active: docCollecion ? true : false,
        };
      });
      return {
        collections: transFormedCollectionData,
      };
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

const getHighlyRatedCollection = async (qsParamsObj) => {
  const url = atk.getUrl(
    'v6',
    `/user_favorites/top_rated${
      qsParamsObj ? `?${queryString.stringify(qsParamsObj)}` : ''
    }`,
  );
  return atk.get(url);
};

const createCollection = async (collectionName, objectId) => {
  const url = atk.getUrl('v6', '/favorite_collections');
  return atk.post(
    url,
    objectId
      ? {
          collection_name: collectionName,
          id: objectId.replace(/\D/g, ''),
        }
      : {
          collection_name: collectionName,
        },
  );
};

const updateCollection = async (oldCollectionName, updatedName) => {
  const url = atk.getUrl('v6', '/favorite_collections');
  return atk.patch(url, {
    old_collection_slug: oldCollectionName,
    new_collection_name: updatedName,
  });
};

const destroyCollection = async (collectionSlug) => {
  const url = atk.getUrl('v6', '/favorite_collections');
  return atk.delete(url, {
    collection_slug: collectionSlug,
  });
};

// remove item from a specific collection
const destroyCollectionItem = async (collectionSlug, objectId) => {
  const url = atk.getUrl(
    'v6',
    `/favorite_collections/${collectionSlug}/items/${objectId}`,
  );
  return atk.delete(url);
};

// add item to a specific collection
const postCollectionItem = async (collectionSlug, objectId) => {
  const url = atk.getUrl(
    'v6',
    `/favorite_collections/${collectionSlug}/items/${objectId}`,
  );
  return atk.post(url);
};

const getDocumentSuggestions = async ({ start, rows }, excludeParams) => {
  const url = atk.getUrl(
    'v6',
    `/miso/user_to_products${excludeParams ? `?${excludeParams}` : ''}`,
  );

  const { data: { documents = [] } = {}, links: { next } = {} } =
    (await atk.post(url, { start, rows })) || {};

  let exclude = null;
  if (next) {
    exclude = next.split('?').pop();
  }

  return {
    documents,
    exclude,
  };
};

const misoUrl = process.env.MISO_API_URL;
const misoKey = process.env.MISO_API_KEY;
const getMisoExperiment = async (userID, experimentId) => {
  const url = `${misoUrl}/experiments/${experimentId}/events?api_key=${misoKey}`;
  const { in_experiment: includeMe } = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user_id: `${userID}` }),
  })
    .then((res) => res.json())
    .catch((err) => console.error(err)); // eslint-disable-line no-console

  return includeMe;
};

export {
  getSmartCollection,
  getRecentlyFavorited,
  getRecentlyFavoritedLimit,
  getHighlyRatedCollection,
  destroyFavorite,
  getMetaData,
  getUserFavorites,
  defaultFavorites,
  postFavorite,
  getFavoriteCollections,
  postCollectionItem,
  createCollection,
  updateCollection,
  destroyCollection,
  destroyCollectionItem,
  getDocumentSuggestions,
  getMisoExperiment,
  favoritesTypeFormatted,
  getDocCollectionsActiveKey,
};
