import { NextPageContext } from 'next';

const proxiedFor = 'X-Proxied-For';

const absoluteUrlFromInitialProps = (req: NextPageContext['req']) => {
  if (!req?.headers) return '';
  const host =
    req?.headers[proxiedFor] ||
    req?.headers[proxiedFor.toLowerCase()] ||
    req.headers['host'];
  const absoluteUrl = `https://${host}${req.url}`;

  return absoluteUrl;
};

export default absoluteUrlFromInitialProps;
