export const TOGGLE_NAVIGATION_DRAWER = 'TOGGLE_NAVIGATION_DRAWER';
export const SET_NAVIGATION_DRAWER_CONTENT = 'SET_NAVIGATION_DRAWER_CONTENT';

export const toggleNavigationDrawer = (isOpen) => ({
  type: 'TOGGLE_NAVIGATION_DRAWER',
  payload: { isOpen: !isOpen },
});

export const setNavigationDrawerContent = (content) => ({
  type: 'SET_NAVIGATION_DRAWER_CONTENT',
  payload: { content },
});
