import atk from '../lib/api/atk';

export const NEWSLETTER_SUBMISSION_PENDING = 'NEWSLETTER_SUBMISSION_PENDING';
export const NEWSLETTER_SUBMISSION_FULFILLED =
  'NEWSLETTER_SUBMISSION_FULFILLED';
export const NEWSLETTER_SUBMISSION_REJECTED = 'NEWSLETTER_SUBMISSION_REJECTED';

export const newsletterSubmission = ({ email, url }) => ({
  type: 'NEWSLETTER_SUBMISSION',
  payload: {
    data: { email },
    promise: atk.post(url, { atkKidsEmail: email }),
  },
});
