export const cloudinaryName = 'hksqkdlah';

const domain = 'https://res.cloudinary.com/hksqkdlah/image/upload';

const getOptions = (width: number | 'auto', height: number | 'auto') => {
  const w = width === 'auto' ? '' : `,w_${width}`;
  const h = height === 'auto' ? '' : `,h_${height}`;
  return `c_fill,dpr_2.0,f_auto,fl_lossy.progressive.strip_profile,g_faces:auto${h},q_auto:low${w}`;
};

export function cloudinaryURL(
  cloudinaryID: string,
  width: number | 'auto',
  height: number | 'auto',
) {
  // Barista occationally sends already url encoded cloudinary ids
  if (cloudinaryID.includes('%20')) {
    return `${domain}/${getOptions(width, height)}/${cloudinaryID}`;
  }
  return encodeURI(`${domain}/${getOptions(width, height)}/${cloudinaryID}`);
}

export const defaultCloudinaryID = 'fallback-image2_dqglx3';
