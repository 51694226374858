import widgetApi from './api';
import widgetUtils from './utils';

const misoUrl = process.env.MISO_API_URL;
const misoKey = process.env.MISO_API_KEY;

function WidgetsAnalytics() {
  this.events = {
    BUY_NOW_CLICK: 'Buy Now Click',
    COLLECTION_CREATED: 'Favorite Collection Added',
    COLLECTION_DESTROYED: 'Favorite Collection Removed',
    COLLECTION_EDITED: 'Favorite Collection Edited',
    COLLECTION_ITEM_CREATED: 'Favorite Collection Item Added',
    COLLECTION_ITEM_DESTROYED: 'Favorite Collection Item Removed',
    NEWSLETTER_PREFERENCES: 'Newsletter Preferences',
    NEWSLETTER_EMAIL_CAPTURE: 'Email Capture',
    FAVORITE_ADDED: 'Favorite Added',
    FAVORITE_DESTROYED: 'Favorite Removed',
    NEWSLETTER_WIDGET_TOGGLE: 'Newsletter Widget Toggle',
    ONBOARDING: 'Onboarding',
    SEARCH_FORM_SUBMITTED: 'Search Form Submitted',
  };
  this.eventQueue = [];
  this.initialized = false;
  this.initialLocation = window.location;
  this.currentUser = null;
  this.getCurrentUser();
}

WidgetsAnalytics.prototype = {
  getSiteKey() {
    const domain = this.initialLocation.host.split('.').slice(-2).join('.');
    const domainLookup = {
      'americastestkitchen.com': 'atk',
      'cooksillustrated.com': 'cio',
      'cookscountry.com': 'cco',
    };
    return domainLookup[domain] || 'atk';
  },

  parseGlobalHeaderUserObj(obj) {
    const cu = obj.currentUser || {};
    const ua = obj.currentUserAccess || {};
    return {
      email: cu.email || null,
      id: cu.id || null,
      memberships_active: ua.siteMemberships || null,
      memberships_cancelled: ua.cancelledSiteMemberships || null,
      package_name: ua.package || null,
      registrations_active: ua.registrationSiteKeys || null,
      role: ua.role || null,
      segment: cu.user_segment,
    };
  },

  blueconicConfig() {
    const config = {
      site_key: this.getSiteKey(),
      offer_test_variant: widgetUtils.getCookie('otc') || 'default',
      ref: widgetUtils.getUrlParameter('ref'),
      offer_code: widgetUtils.getCookie('offer_code'),
      incode: widgetUtils.getCookie('incode'),
      extcode: widgetUtils.getCookie('extcode'),
      utm_source: widgetUtils.getCookie('utm_source'),
    };
    if (this.currentUser && this.currentUser.email) {
      config.email = this.currentUser.email;
      config.user_id = this.currentUser.id;
      config.role = this.currentUser.role;
      config.package = '';
    }
    return config;
  },

  getCurrentUser() {
    widgetApi.getUser(this.handleGetUserSuccess.bind(this));
  },

  handleGetUserSuccess(user) {
    this.currentUser = this.parseGlobalHeaderUserObj(user);
    window.ATK_USER = this.currentUser;
  },

  startBlueConic() {
    if (typeof window.blueConicTracking === 'undefined') {
      window.blueConicTracking = {
        user: this.blueconicConfig(),
        events: [],
      };
    }
  },

  startMixpanel() {
    if (this.initialized) return;
    if (this.eventQueue.length) {
      this.eventQueue.forEach((evt) => {
        mixpanel.track(evt[0], evt[1], evt[2]);
      });
    }
    this.initialized = true;
  },

  trackMisoInteraction(eventName, options) {
    const misoEvents = {
      FAVORITE_ADDED: 'add_to_collection',
      FAVORITE_DESTROYED: 'remove_from_collection',
    };
    try {
      if (typeof misoEvents[eventName] !== 'undefined') {
        const data = {
          type: misoEvents[eventName],
          product_ids: [`${options.document_type}_${options.document_id}`],
        };
        if (typeof dry !== 'undefined' && dry.user) {
          data.user_id = `${dry.user.currentUser.id}`;
        } else if (mixpanel) {
          data.anonymous_id = mixpanel.get_distinct_id();
        }
        data.type = misoEvents[eventName];
        widgetApi.authenticatedFetch(
          `${misoUrl}/interactions?api_key=${misoKey}`,
          'POST',
          { data: [data] },
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Unable to track MISO favorite event', err);
    }
  },

  trackEvent(eventName, options, callback) {
    if (typeof this.events[eventName] !== 'undefined') {
      if (typeof mixpanel !== 'undefined' && window.mixpanel.__loaded) {
        // eslint-disable-line
        this.startMixpanel();
        mixpanel.track(this.events[eventName], options, callback);
      } else {
        this.eventQueue.push([this.events[eventName], options]);
        if (typeof callback === 'function') callback();
      }
      this.trackMisoInteraction(eventName, options);
      this.startBlueConic();
      const evt = Object.assign(options, {
        name: this.events[eventName],
      });
      window.blueConicTracking.events.push(evt);
      if (
        typeof window.blueConicClient !== 'undefined' &&
        window.blueConicClient.event
      ) {
        const pub = window.blueConicClient.event.publish;
        window.blueConicTracking.events.map((ev) => pub(ev.name));
      }
    }
  },
};

export default WidgetsAnalytics;
