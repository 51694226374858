import algoliasearch from 'algoliasearch';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import qs from 'qs';

import facets from './facets';

const APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '';
const API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN ?? '';
const client = algoliasearch(APP_ID, API_KEY);

const algoliaEnv = process.env.NEXT_PUBLIC_ALGOLIA_ENVIRONMENT || 'development';
const indexPrefix = 'everest_search';
const getIndexName = (context) =>
  `${indexPrefix}${context ? `_${context}` : ''}_${algoliaEnv}`;
const index = client.initIndex(getIndexName());

const latestIndexName = getIndexName('published_date_desc');
const latestIndex = client.initIndex(latestIndexName);

/**
 * Retrieve a random "page" of recipes for the specified book.
 * @param  {String} bookSlug       Book identifier
 * @param  {Integer} documentCount Total recipe count in book
 * @param  {String} recipeSlug     Current recipe slug
 * @return {Object}                Results, filtering out current recipe
 */
const getBookRecipes = async ({ slug, documentCount, recipeSlug }) => {
  const hitsPerPage = 6;
  const pageCount = Math.floor(documentCount / hitsPerPage); // floor is on purpose
  const page = Math.min(Math.floor(Math.random() * pageCount) + 1, 5);
  const config = {
    attributesToHighlight: null,
    attributesToRetrieve: [
      'title',
      'search_url',
      'search_cloudinary_id',
      'search_site_list',
      'search_document_klass',
      'search_document_klass_formatted',
    ],
    enableRules: false,
    facetFilters: [
      ['search_document_klass:recipe'],
      [`search_browse_slugs:${slug}`],
    ],
    hitsPerPage,
    page,
  };
  const response = await index.search(config);
  const documents = response.hits.filter(
    (h) => h.search_url !== `/recipes/${recipeSlug}`,
  );
  return { slug, documents };
};

const standardCardAttributes = [
  'title',
  'search_url',
  'search_cloudinary_id',
  'search_site_list',
  'search_document_klass',
  'search_document_date',
  'search_document_klass_formatted',
  'search_published_date',
  'search_stickers',
  'search_comment_count',
  'search_related_data',
  'search_atk_buy_now_link',
  'search_cio_buy_now_link',
  'search_cco_buy_now_link',
  'search_related_winner_header',
];

/**
 * Get Latest Revewsets data
 */
const getLatestReviews = async () => {
  const hitsPerPage = 9;
  const config = {
    attributesToHighlight: null,
    attributesToRetrieve: standardCardAttributes,
    enableRules: false,
    facetFilters: [
      [
        'search_document_klass:equipment_review',
        'search_document_klass:taste_test',
      ],
      'search_site_list:atk',
    ],
    hitsPerPage,
  };
  const { hits } = await latestIndex.search(config);
  return { hits };
};

const getFeaturedRecipe = (siteKey) => {
  const config = {
    attributesToHighlight: null,
    facetFilters: 'search_document_klass:recipe',
    hitsPerPage: 1,
  };

  const seasonIndexName = getIndexName(
    `${siteKey}_${siteKey === 'cio' ? 'magazine' : 'season'}_desc`,
  );
  const seasonDescIndex = client.initIndex(seasonIndexName);

  const response = seasonDescIndex.search(config);
  const result = Promise.resolve(response).then((r) => r.hits[0]);
  return result;
};

/**
 * Translate a search state object (Instant Search-ish)
 * into a direct Algolia query. `refinementList` is changed from
 * {"search_test_type_list":["Dairy & Eggs"],"search_document_klass":["taste_test"]}
 * to
 * ["search_test_type_list:Dairy & Eggs","search_document_klass:taste_test"]
 * in order to swap from Instant Search params to index.search params
 * @param {*} searchState Object representing search state
 * @returns Array
 */
const getInstantSearchDocuments = (searchState) => {
  const {
    configure: { enableRules = false, filters = '', hitsPerPage = 12 },
    menu = {},
    refinementList = {},
  } = searchState;
  let config = {};
  // transform string into array
  // ex: 'search_document_klass:equipment_review OR search_document_klass:taste_test'
  try {
    let facetFilters = filters?.split(' OR ') || [];
    if (refinementList) {
      // add the facetFilters to the refinement list object
      const refinementFilters = facetFilters.reduce((accum, f) => {
        // break up search_document_klass:equipment_review
        const [attr, val] = f.split(':');

        if (Array.isArray(accum[attr])) {
          // add to existing list
          accum[attr] = [...accum[attr], val];
        } else {
          // or, create a new list
          accum[attr] = [val];
        }
        return accum;
      }, refinementList);

      facetFilters = Object.keys(refinementFilters).reduce((accum, key) => {
        // sometimes qs arguments have empty values, we only care about arrays
        if (Array.isArray(refinementFilters[key])) {
          // using Set b/c duplicates can occur btwn facetFilters and refinementList
          accum = [
            ...accum,
            [...new Set(refinementFilters[key].map((val) => `${key}:${val}`))],
          ];
        }
        return accum;
      }, []);
    }
    if (menu) {
      facetFilters = Object.keys(menu).reduce(
        (accum, key) => [...accum, `${key}:${menu[key]}`],
        facetFilters,
      );
    }
    config = {
      attributesToHighlight: null,
      attributesToRetrieve: standardCardAttributes,
      enableRules,
      facetFilters,
      hitsPerPage,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Error parsing facetFilters and refinementFilters', err);
  }
  return latestIndex.search(config);
};

const magazineIndexes = {
  atk: '',
  cco: 'cco_magazine_desc_',
  cio: 'cio_magazine_desc_',
};

const getLatestMagazine = (siteKey) => {
  const magIdx = magazineIndexes[siteKey];
  let result = Promise.resolve({});
  if (typeof magIdx !== 'undefined') {
    const magazineIndex = client.initIndex(
      `everest_search_${magIdx}${algoliaEnv}`,
    );
    const response = magazineIndex.search({
      attributesToHighlight: null,
      enableRules: false,
      facetFilters: ['search_document_klass:magazine'],
      hitsPerPage: 1,
    });
    result = Promise.resolve(response).then((r) => r.hits[0]);
  }
  return result;
};

const getLatestMagazineDocuments = (siteKey, documentTypes = []) => {
  const config = {
    attributesToHighlight: null,
    facetFilters: [
      documentTypes.map(
        (documentType) => `search_document_klass:${documentType}`,
      ),
    ],
    hitsPerPage: 12,
  };

  const magazineIndexName = getIndexName(`${siteKey}_magazine_desc`);
  const magazineIndex = client.initIndex(magazineIndexName);

  return magazineIndex.search(config);
};

const getAlgoliaDocuments = (
  facetFilters,
  indexSlug = '',
  hitsPerPage = 12,
) => {
  const config = {
    attributesToHighlight: null,
    facetFilters,
    hitsPerPage,
  };

  const algoliaIndexName = getIndexName(indexSlug);
  const algoliaIndex = client.initIndex(algoliaIndexName);
  return algoliaIndex.search(config);
};

/**
 * Facet filters used within equipment/ingredient quick view
 * @type {Array}
 */
const reviewableFacetFilters = [
  [
    'search_document_klass:recipe',
    'search_document_klass:equipment_review',
    'search_document_klass:taste_test',
    'search_document_klass:how_to',
    'search_document_klass:article',
  ],
];

/**
 * Facet filters for finding a related book
 */
const bookFacetFilters = [
  ['search_document_klass:book', 'search_document_klass:cookbook'],
];

/**
 * Removes common words from school course query
 */
const titleBlacklist = [
  'best',
  'better',
  'classic',
  'easy',
  'foolproof',
  'quick',
  'simple',
  'ultimate',
];
const blacklistRegex = new RegExp(titleBlacklist.join('|'), 'g');
const fixCourseQuery = (query) =>
  query.toLowerCase().replace(blacklistRegex, '').trim();

/**
 * Helper method returns valid Algolia query structure
 * @param  {String} query           Query term
 * @param  {Array}  facetFilters    List of facet filters (doc types)
 * @param  {Number} [hitsPerPage=1] Number of results to return
 * @return {Object}                 Algolia query config object
 */
const buildQuery = (query, facetFilters, hitsPerPage = 1) => ({
  indexName: getIndexName(),
  params: {
    attributesToHighlight: null,
    enableRules: false,
    facetFilters,
    hitsPerPage,
    query: query.toString(),
  },
});

/**
 * Get book query for `type`'s context, falling back to `default`
 * @param  {Object} bookQueries Group of queries for different quick view contexts
 * @param  {String} type        Equipment|Ingredient|Episode|Book
 * @return {String}             Query term for Algolia search
 */
const getBookTypeQuery = (bookQueries, type) => {
  const bookQueryData = bookQueries(type);
  return bookQueryData[type] || bookQueryData.default;
};

/**
 * Query for a book - used in Episode QuickView
 * @param  {Object}  bookQueries  One search term for a TV book
 * @return {Array}                Algolia record
 */
const getEpisodeBook = ({ bookQueries }, type) => {
  const bookQuery = getBookTypeQuery(bookQueries, type);
  const queries = [buildQuery(bookQuery, bookFacetFilters)];
  return new Promise((resolve, reject) => {
    client.search(queries, (err, response) => {
      if (err) reject(err);
      resolve(get(response, ['results', 0, 'hits'], null));
    });
  });
};

/**
 * Query for related documents to be displayed in QuickView
 *
 * @param  {Object}  bookQueries  Search terms for books
 * @param  {String} subtitle      Contextually relevant string (reviewable title)
 * @param  {String} documentType  Used for filtering duplicate reviewable
 * @param  {String} documentId    Used for filtering duplicate reviewable
 * @return {Array}                Algolia records
 */
const getReviewableRelateds = (
  { bookQueries, subtitle: query, documentType, documentId },
  type,
) => {
  const bookQuery = getBookTypeQuery(bookQueries, type);
  const queries = [
    buildQuery(query, reviewableFacetFilters, 5),
    buildQuery(bookQuery, bookFacetFilters),
  ];

  return new Promise((resolve, reject) => {
    client.search(queries, (err, response) => {
      if (err) reject(err);

      // parse related documents query response
      let relateds = get(response, ['results', 0, 'hits'], null);
      if (relateds && relateds.length > 0) {
        const currentId = `${documentType}_${documentId}`;
        // filter out any "duplicate" review sets so that we don't show
        // the same taste test displayed in the "winner" slot
        relateds = relateds.filter((h) => h.objectID !== currentId);
      }

      // Add the book result, if present, to the 3rd spot - per GLB-832
      const bookResult = get(response, 'results[1].hits[0]');
      if (bookResult) relateds.splice(2, 1, bookResult);

      resolve(relateds);
    });
  });
};

const getSchoolCourse = debounce(
  (query, isOverride = false) => {
    // clean the incoming query except for overrides
    const cleanQuery = isOverride ? query : fixCourseQuery(query);
    const config = {
      attributesToHighlight: null,
      query: cleanQuery,
      enableRules: false,
      facets: 'search_document_klass',
      facetFilters: [['search_document_klass:course']],
      attributesToRetrieve: [
        'title',
        'description',
        'search_url',
        'search_cloudinary_id',
        'search_page_content',
        'search_related_data',
      ],
      hitsPerPage: 1,
      removeStopWords: true,
    };
    // conditionally allow stop-words for override terms
    if (isOverride) delete config.removeStopWords;
    const response = index.search(config);
    return Promise.resolve(response)
      .then((r) => r.hits[0])
      .then((course) => ({ query, course }));
  },
  500,
  { leading: true, trailing: false },
);

const getValidSubFacets = (parentFacetName, refined) =>
  get(facets, [parentFacetName, refined], []);

/**
 * Translate routing params and query string variables into a useable
 * React instant search state.
 * @returns Object
 */
const getRecipesBrowseState = ({
  asPath,
  docType,
  facetType,
  filters,
  indexType,
  pageSize,
  translations,
}) => {
  /* eslint-disable camelcase */
  const {
    browse: { document_klass_singular, recipes },
  } = translations;

  const { refinementList: parsedRefList = {}, ...parsedPath } = qs.parse(
    asPath.substring(asPath.indexOf('?') + 1),
  );

  /**
   * Some old urls and facet slugs like recipes/browse/salads are returning [0][0] values
   *  for keys like search_browse_slugs. They loop infinately with our to be refactored implementation.
   * This handles those old urls or redirects which will no longer be detected by fault/87606472
   * e.g. https://www.americastestkitchen.com/recipes/browse?refinementList%5Bsearch_browse_slugs%5D%5B0%5D%5B0%5D=grilling&refinementList%5Bsearch_document_klass%5D%5B0%5D=recipe
   * from https://www.americastestkitchen.com/books/healthy-and-delicious-instant-pot
   */
  Object.entries(parsedRefList).forEach(([key, value]) => {
    if (Array.isArray(value?.[0]) && value?.[0].length === 1) {
      parsedRefList[key] = value[0];
    }
  });

  const refinementList = { ...parsedRefList };
  if (facetType && recipes[facetType]) {
    refinementList.search_browse_slugs = [facetType];
  }

  if (docType && document_klass_singular[docType]) {
    refinementList.search_document_klass = [document_klass_singular[docType]];
  }
  /* eslint-enable camelcase */

  return {
    ...parsedPath,
    configure: {
      analytics: true,
      enableRules: false,
      filters,
      hitsPerPage: pageSize,
    },
    basePath: indexType,
    refinementList,
  };
};

/**
 * Translate routing params and query string variables into a useable
 * React instant search state.
 * @returns Object
 */
const getReviewsBrowseState = ({
  docType,
  facetType,
  filters,
  indexType,
  pageSize,
  translations,
}) => {
  /* eslint-disable camelcase */
  const {
    browse: { document_klass_singular, equipment_reviews, taste_tests },
  } = translations;

  const refinementList = {};
  const menu = {};
  const isTrendingOrCurrent =
    indexType === 'trending' || indexType === 'current_season';
  // This first condition covers legacy URLs. they would follow the pattern below:
  // /:docType(ER/TT)/browse/:facetType
  if (docType && document_klass_singular[docType] && !isTrendingOrCurrent) {
    refinementList.search_document_klass = [document_klass_singular[docType]];

    if (
      docType === 'equipment_reviews' &&
      facetType &&
      equipment_reviews[facetType]
    ) {
      menu.search_review_type_list = equipment_reviews[facetType];
    }

    if (docType === 'taste_tests' && facetType && taste_tests[facetType]) {
      menu.search_test_type_list = taste_tests[facetType];
    }
    // This case covers new URLs
    // /reviews/:indexType(browse|buying_guides|equipment_reviews|taste_tests|trending)
  } else if (indexType) {
    const indexDocTypes = ['buying_guides', 'equipment_reviews', 'taste_tests'];
    if (indexDocTypes.includes(indexType)) {
      refinementList.search_document_klass = [
        document_klass_singular[indexType],
      ];
    } else if (indexType === 'trending') {
      refinementList.search_browse_slugs = 'trending';
      refinementList.search_document_klass = [document_klass_singular[docType]];
    } else if (indexType === 'current_season') {
      refinementList.search_review_in_current_season = 'true';
      if (docType)
        refinementList.search_document_klass = [
          document_klass_singular[docType],
        ];
    }
  }
  /* eslint-enable camelcase */

  return {
    configure: {
      analytics: true,
      enableRules: false,
      filters,
      hitsPerPage: pageSize,
    },
    basePath: docType || indexType,
    refinementList,
    menu,
  };
};

/** Get Latest Relevant Reviewset Data */

const getLatestRelatedCarousel = async (facet, typeList) => {
  const formattedFacet =
    facet === 'review_type_list' ? 'equipment_review' : 'taste_test';
  const searchType =
    formattedFacet === 'equipment_review'
      ? 'search_review_type_list'
      : 'search_test_type_list';
  const hitsPerPage = 9;
  const config = {
    attributesToHighlight: null,
    attributesToRetrieve: standardCardAttributes,
    enableRules: false,
    facetFilters: [
      [`search_document_klass:${formattedFacet}`],
      [`${searchType}:${typeList}`],
      ['search_site_list:atk'],
    ],
    hitsPerPage,
  };
  const { hits } = await latestIndex.search(config);
  return { hits };
};

const getRefinedFacet = (refinementList, attribute) =>
  (refinementList && refinementList[attribute]) || null;

const isFacetRefined = (refinementList, attribute, value) => {
  let isRefined = false;
  if (refinementList) {
    const refinedAttribute = getRefinedFacet(refinementList, attribute);
    if (refinedAttribute) {
      if (Array.isArray(refinedAttribute)) {
        isRefined = refinedAttribute.includes(value);
      } else {
        isRefined = refinedAttribute === value;
      }
    }
  }
  return isRefined;
};

const algolia = {
  getAlgoliaDocuments,
  getInstantSearchDocuments,
  getRecipesBrowseState,
  getReviewsBrowseState,
  getFeaturedRecipe,
  getIndexName,
  getBookRecipes,
  getEpisodeBook,
  getLatestMagazine,
  getLatestMagazineDocuments,
  getLatestReviews,
  getLatestRelatedCarousel,
  getReviewableRelateds,
  getSchoolCourse,
  getValidSubFacets,
  getRefinedFacet,
  isFacetRefined,
};

export default algolia;
