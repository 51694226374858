const nClasses = {
  content: 'newsletter-widget-global__content',
  error: 'newsletter-widget-global__error',
  form: 'newsletter-widget-global__form',
  header: 'newsletter-widget-global__header',
  heading: 'newsletter-widget-global__heading',
  intro: 'newsletter-widget-global__intro',
  open: 'open',
  success: 'newsletter-widget-global__success',
  successButton: 'newsletter-widget-global__success--button',
  toggler: 'newsletter-widget-global__toggle',
  togglerClose: 'newsletter-widget-global__toggle-close',
  togglerDestroy: 'newsletter-widget-global__toggle-destroy',
  togglerOpen: 'newsletter-widget-global__toggle-open',
  popoverWrapper: 'newsletter-widget-popover',
  inlineWrapper: 'newsletter-widget-inline',
  inlineSwap: 'newsletter-widget-inline-swap',
};

export default nClasses;
