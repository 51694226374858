import algolia from '../lib/algolia';

export const GET_QUICK_VIEW_RELATEDS_PENDING =
  'GET_QUICK_VIEW_RELATEDS_PENDING';
export const GET_QUICK_VIEW_RELATEDS_FULFILLED =
  'GET_QUICK_VIEW_RELATEDS_FULFILLED';
export const GET_QUICK_VIEW_RELATEDS_REJECTED =
  'GET_QUICK_VIEW_RELATEDS_REJECTED';
export const SET_QUICK_VIEW_CONTENT = 'SET_QUICK_VIEW_CONTENT';
export const TOGGLE_QUICK_VIEW = 'TOGGLE_QUICK_VIEW';

export const getQuickViewRelateds = (type, data) => {
  let promise = Promise.resolve({});
  if (type === 'book') {
    promise = algolia.getBookRecipes(data, type);
  } else if (type === 'episode') {
    promise = algolia.getEpisodeBook(data, type);
  } else {
    // type here is either 'equipment' or 'ingredient'
    promise = algolia.getReviewableRelateds(data, type);
  }
  return {
    type: 'GET_QUICK_VIEW_RELATEDS',
    payload: {
      promise,
    },
  };
};

export const setQuickViewContent = (content) => ({
  type: 'SET_QUICK_VIEW_CONTENT',
  payload: { content },
});

export const toggleQuickView = (isOpen) => ({
  type: 'TOGGLE_QUICK_VIEW',
  payload: { isOpen: !isOpen },
});
