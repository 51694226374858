const color = {
  white: '#ffffff',
  black: '#000000',
  cuttySark: '#57797b',
  tomato: '#d73a15',
  tomatoTint: '#d73a19',
  rust: '#a72e12',
  wildSand: '#f4f4f4',
  whiteSmoke: '#f5f5f5',
  greySmoke: '#ebebeb',
  eclipse: '#3d3d3d',
  mint: '#6ba6aa',
  darkerMint: '#5a8e91',
  darkTeal: '#437072',
  dawnPink: '#f2e4e4',
  turquoise: '#8fdee3',
  seaSalt: '#d1fcff',
  regentGray: '#767f81',
  mediumGray: '#6E6E6E',
  bermudaGray: '#6e89aa',
  silver: '#c2c2c2',
  gunmetal: '#262626',
  gray20: '#333333',
  nobel: '#999999',
  denim: '#416490',
  malibu: '#69a0e1',
  arapawa: '#2e4a69',
  cornflower: '#e6f1ff',
  havelockBlueLight: '#689edf',
  linen: '#fcf9f3',
  dirtyLinen: '#fbebe8',
  squirrel: '#94856B',
  cork: '#3f2b1e',
  bone: '#d9ccA7',
  dijon: '#eed681',
  sand: '#fff5c2',
  email: '#3d3d3d',
  emailHover: '#94856b',
  jade: '#00A26D',
  jet: '#080808',
  facebook: '#527aa1',
  facebookHover: '#43637a',
  palePink: '#f5d7d1',
  pinterest: '#cf5553',
  pinterestHover: '#893b3b',
  twitter: '#79aed1',
  twitterHover: '#557d93',
  miseUiGrayBorder: '#efefef', // only for stories, not components
  frost: '#e9f0f0',
  smokeyQuartz: '#2A2A2A',
  asphalt: '#191919',
  charcoal: '#121212',
  frog: '#408612',
  darkFrog: '#2F650B',
  coldPool: '#1A7AC4',
  darkColdPool: '#0466B0',
  shadyLady: '#979797', // 151 151 151
  darkSlate: '#28494b',
  bluewood: '#374360',
  slate: '#767d8e',
  mineShaft: '#2a2a2a',
  focusRing: '#2A5FCC',
  ivory: '#fffdeb',
  aliceBlue: '#f7faff',
  solitude: '#e9f0f0',
  oysterBay: '#d1fcff',
  solitudeLink: '#e1edff',
  havelockBlue: '#659de1',
  riptide: '#8fdee3',
  robRoy: '#e9cd6a',
  buttermilk: '#fff3b3',
  arrowTown: '#92856e',
  wedgewood: '#48648C',
  tabasco: '#A72E13', // 167, 46, 19
  transparentWhite: 'rgba(255, 255, 255, 0.5)',
  transparentBlack: 'rgba(0, 0, 0, 0.7)',
  transparentGunmetal: 'rgba(38, 38, 38, 0.5)',
  transparentAsphalt: 'rgba(25, 25, 25, 0.5)',
  transparentJet: 'rgba(8, 8, 8, 0.5)',
  transparentWhiteSmoke: 'rgba(245, 245, 245, 0.5)',
  midnight: '#1A3352',
  sienna: '#B25B18',
  violet: '#321A52',
  pacific: '#1775C2',
  goldClay: '#857351',
  darkMagenta: '#521A2D',
  timber: '#405700',
  wintergreenDream: '#4D7A7C',
  blueLagoon: '#005E71',
  teal: '#167A7A',
  deepSea: '#0B3C3D',
  danube: '#5E9ED6',
  bigStone: '#182d48',
  pictonBlue: '#3c9be2',
  morningGlory: '#a0dce2',
  athensGray: '#f9f9fb',
  suvaGray: '#8E8E8E',
  burgundy: '#5C303E',
  caramel: '#6E562A',
  deepPlumb: '#3E285C',
  doveGray: '#6e6e6e',
  chablis: '#fef1e9',
  greenWhite: '#e7eae1',
  queenBlue: '#426491',
  pearlBush: '#ede8de',
  sonicSilver: '#757575',
  alabaster: '#f7f7f7',
  kashmirBlue: '#416491',
  prairieSand: '#9a3721',
  tabascoNew: '#a72e14',
  salsaMexicana: '#a72e14',
  grayishCyan: '#437072',
} as const;

export default color;
