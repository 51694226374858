/**
 * https://raw.githubusercontent.com/crabbly/Print.js/master/src/js/browser.js
 */
const browser = {
  // Firefox 1.0+
  isFirefox: () => typeof InstallTrigger !== 'undefined',
  isIOSFirefox: () => navigator.userAgent.toLowerCase().indexOf('fxios') !== -1,
  // Internet Explorer 6-11
  isIE: () =>
    navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode,
  // Edge 20+
  isEdge: () => !browser.isIE() && !!window.StyleMedia,
  // Chrome 1+
  isChrome: (context = window) => !!context.chrome,
  // At least Safari 3+: "[object HTMLElementConstructor]"
  isSafari: () =>
    Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') >
      0 || navigator.userAgent.toLowerCase().indexOf('safari') !== -1,
  // IOS Chrome
  isIOSChrome: () => navigator.userAgent.toLowerCase().indexOf('crios') !== -1,
};

export default browser;
