import api from '../lib/api';

export const GET_CONTENT_PENDING = 'GET_CONTENT_PENDING';
export const GET_CONTENT_FULFILLED = 'GET_CONTENT_FULFILLED';
export const GET_CONTENT_REJECTED = 'GET_CONTENT_REJECTED';

export const getContent = (contentId, authToken, options = {}, siteKey) => ({
  type: 'GET_CONTENT',
  payload: {
    promise: api.detail(contentId, authToken, options, siteKey),
  },
});

export const getContentV2 = (contentId, authToken, options = {}, siteKey) => {
  return async function (dispatch) {
    dispatch({ type: GET_CONTENT_PENDING });
    return api
      .detail(contentId, authToken, options, siteKey)
      .then((res) => {
        dispatch({
          type: GET_CONTENT_FULFILLED,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({ type: GET_CONTENT_REJECTED });
        // eslint-disable-next-line no-console
        console.warn(err);
      });
  };
};

// EXAMPLE of how we can use without redux
export const getContentV3 = async (
  contentId,
  authToken,
  options = {},
  siteKey,
) => {
  // dispatch({ type: GET_CONTENT_PENDING });
  return api
    .detail(contentId, authToken, options, siteKey)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // dispatch({ type: GET_CONTENT_REJECTED });
      // eslint-disable-next-line no-console
      console.warn(err);
    });
};
