import {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
  variables,
} from './defaults';

const themeColors = {
  ...colors,
};

const themeLineHieghts = {
  ...lineHeights,
};

const themeFontSizes = {
  ...fontSizes,
};

const registrationTheme = {
  siteKey: 'registration',
  themeColor: '#3d3d3d',
  colors: themeColors,
  fonts,
  fontSizes: themeFontSizes,
  lineHeights: themeLineHieghts,
  breakpoints,
  contentMaxWidth: '1136px',
  letterSpacing,
  socialColors,
  variables: {
    ...variables,
  },
};

export default registrationTheme;
