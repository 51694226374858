import { FocusEventHandler, FormEvent, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './searchBar.module.scss';

const cx = classNames.bind(styles);

export type SearchBarOnSubmit = (event: FormEvent, searchValue: string) => void;

export type SearchBarProps = {
  inputId: string;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onSubmit: SearchBarOnSubmit;
};

const SearchBar = ({ inputId, onFocus, onSubmit }: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const labelWrapperClassNames = cx(
    styles.spyglassAndLabel,
    'search-bar-label-wrapper',
  );
  const labelClassNames = cx(styles.label, 'global-header-search-bar-label', {
    [styles.hideLabel]: searchValue.length > 0,
  });
  const inputClassNames = cx(styles.input, 'global-header-search-input');

  return (
    <form
      className={`${styles.form} global-search-form`}
      data-testid="search-bar"
      onSubmit={(event) => {
        onSubmit(event, searchValue);
      }}
    >
      <div className={styles.searchBar}>
        <div className={labelWrapperClassNames}>
          <button
            aria-label={`Submit Search ${
              searchValue.length > 0 ? `for ${searchValue}` : ''
            }`}
            className={styles.spyglassIcon}
            id="search-form-submit-button"
          >
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 30"
            >
              <path
                fill="#97928F"
                d="M3.8 11.2c0-4.1 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5-3.4 7.5-7.5 7.5-7.5-3.3-7.5-7.5m25.5 15.4-8.9-8.9c1.3-1.9 2.1-4.2 2.1-6.5C22.5 5 17.5 0 11.2 0 5 0 0 5 0 11.2a11.18 11.18 0 0 0 17.7 9.1l8.9 8.9c.7.8 2 .9 2.8.1.8-.7.8-1.9-.1-2.7"
              />
            </svg>
          </button>
          <label className={labelClassNames} htmlFor={inputId}>
            <span className={styles.mobileLabel}>Search</span>
            <span className={styles.standardLabel}>
              Search recipes, reviews & more
            </span>
          </label>
        </div>
        <input
          className={inputClassNames}
          id={inputId}
          name="globalHeaderSearchQuery"
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          onFocus={onFocus}
          type="text"
          value={searchValue}
        />
      </div>
    </form>
  );
};

export default SearchBar;
