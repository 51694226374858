import manager from './manager';
import track from './track';

const analytics = {
  initialize: manager.initialize,
  refreshUser: manager.refreshUser,
  track,
};

export default analytics;
