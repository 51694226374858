import {
  GET_BOOK_RECIPES_FULFILLED,
  GET_BOOK_RECIPES_PENDING,
  GET_BOOK_RECIPES_REJECTED,
} from '../actions/bookRecipes';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  data: {},
  status: c.DEFAULT,
};

const bookRecipes = (
  state = initialState,
  { payload: { slug, documents } = {}, type },
) => {
  switch (type) {
    case GET_BOOK_RECIPES_PENDING:
      return {
        ...state,
        status: c.LOADING,
      };

    case GET_BOOK_RECIPES_REJECTED:
      return {
        ...state,
        status: c.ERROR,
      };

    case GET_BOOK_RECIPES_FULFILLED:
      return {
        data: {
          ...state.data,
          [slug]: documents,
        },
        status: c.READY,
      };

    default:
      return state;
  }
};

export default bookRecipes;
