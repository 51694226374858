import cx from 'classnames';

import Disclosure from '../Disclosure/Disclosure';
import LinkList from '../LinkList/LinkList';
import { Typography } from '@americastestkitchen/mise/components/Typography/index';

import styles from './BrandNav.module.scss';

import {
  GlobalHeaderLink,
  SiteKeyType,
  UserNavItemType,
  UserType,
  SiteNavLink,
} from '../shared/types';

export interface BrandNavProps {
  activeSiteNavItem?: SiteKeyType;
  activeUserNavItem?: UserNavItemType;
  headerLinks: SiteNavLink[];
  nextPath?: string;
  user: UserType;
  userCta?: {
    label: string;
    href: string;
  };
  userNav: GlobalHeaderLink[];
  testId?: string;
}

export const BrandNav: React.FC<BrandNavProps> = ({
  activeSiteNavItem,
  headerLinks,
  nextPath,
  testId,
  user = { name: '', isLoggedIn: false },
  userCta,
  userNav,
}: BrandNavProps) => {
  const { name, isLoggedIn } = user;
  return (
    <div className={styles.wrapper} id="brand-nav" data-testid="brand-nav">
      <Disclosure
        activeIcon="downChevron"
        buttonClass={cx(styles.button, styles.mobile_nav_toggle)}
        icon="downChevron"
        iconClass={styles.button_icon}
        id="mobile-nav"
        title="Our Sites"
      >
        <div className={cx(styles.link_list_nav, styles.left)}>
          <LinkList ariaLabel="brand nav" list={headerLinks} />
        </div>
      </Disclosure>

      <nav
        aria-label="brand nav"
        className={styles.brand_nav}
        data-testid={testId}
      >
        <ul className={cx(styles.list, 'sb-unstyled')}>
          {headerLinks.map(({ id, href, label, target }) => (
            <li
              key={id}
              className={cx(
                styles.item,
                id === activeSiteNavItem && styles.active,
              )}
            >
              <a href={href} className={styles.link} target={target ?? '_self'}>
                <Typography size="sm" type="dense" weight="bold">
                  {label}
                </Typography>
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <div className={cx(styles.user, 'sb-unstyled')}>
        {isLoggedIn ? (
          <>
            <a href="/favorites" className={styles.button}> {/* eslint-disable-line */}
              <svg
                className={styles.ribbon_icon}
                fill="currentColor"
                aria-hidden="true"
                focusable="false"
                role="img"
                viewBox="0 0 15 29"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.32709 21.1527L1 27.77V1H13.6829V27.77L7.32709 21.1527Z"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinejoin="round"
                />
              </svg>
              <Typography size="sm" type="dense" weight="bold">
                Favorites
              </Typography>
            </a>
            <Disclosure
              activeIcon="downChevron"
              buttonClass={styles.button}
              icon="downChevron"
              iconClass={styles.button_icon}
              id="user-nav"
              title={name}
            >
              <div className={cx(styles.link_list_nav, styles.right)}>
                <LinkList ariaLabel="user nav" list={userNav} />
              </div>
            </Disclosure>
          </>
        ) : (
          <>
            <a href="/support" className={cx(styles.button, styles.inverse, styles.hideMobile)}> {/* eslint-disable-line */}
              Support
            </a>
            {userCta && (
              <a
                href={userCta.href}
                className={cx(styles.button, styles.inverse, styles.hideTablet)}
              >
                {userCta.label}
              </a>
            )}
            <a href={`/sign_in?next=${nextPath}`} className={styles.button}>
              Log In
            </a>
          </>
        )}
      </div>
    </div>
  );
};
