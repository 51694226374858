import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DryInline from './DryInline';
import DryBaseJs from './DryBaseJs';

const Dry = ({ siteKey }) => {
  return siteKey ? (
    <Fragment>
      <DryInline />
      <DryBaseJs />
    </Fragment>
  ) : null;
};

Dry.propTypes = {
  siteKey: PropTypes.string.isRequired,
};

export default Dry;
