// DEV note - traceur doesn't like trailing commas - don't use them

/**
 * Custom Error object for when document has been previously favorited.
 * @param       {String} message
 * @constructor
 */
function ExistingFavorite(message) {
  this.name = 'ExistingFavorite';
  this.message = message || 'This item is already in your favorites.';
  this.stack = new Error().stack;
}
ExistingFavorite.prototype = Object.create(Error.prototype);
ExistingFavorite.prototype.constructor = ExistingFavorite;

/**
 * Custom Error object for when a collection already exists.
 * @param       {String} message
 * @constructor
 */
function ExistingCollection(message) {
  this.name = 'ExistingFavorite';
  this.message = message || 'This item is already in your favorites.';
  this.stack = new Error().stack;
}
ExistingCollection.prototype = Object.create(Error.prototype);
ExistingCollection.prototype.constructor = ExistingCollection;

const widgetApi = {
  constants: {
    apiCredentialDigest: 'YXRrOmV6QkQyQXdqUW9zNjNFWmJwQ1VlWUF0dA==',
    baseUrl: '/api/v6',
    endpoints: {
      favorite_collections: 'favorite_collections',
      favorites: 'user_favorites',
      favorites_intersection: 'user_favorites/intersection',
      token: 'token',
      user: 'user',
    },
  },
  /**
   * Create CORS compliant XHR request.
   * https://www.html5rocks.com/en/tutorials/cors/
   * @param  {String} url            Url to request
   * @param  {String} [method='GET'] GET|POST|PUT|DELETE
   * @return {XMLHttpRequest}
   */
  createCORSRequest: function createCORSRequest(url, method = 'GET') {
    let xhr = new (window.XMLHttpRequest || window.ActiveXObject)(
      'MSXML2.XMLHTTP.3.0',
    );
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else if (typeof XDomainRequest !== 'undefined') {
      xhr = new XDomainRequest(); // eslint-disable-line
      xhr.open(method, url);
    }
    xhr.timeout = 5000;
    xhr.ontimeout = () => {
      throw new Error('Server did not respond in a timely manner');
    };
    return xhr;
  },

  /**
   * Fetch the api authentication token. Once request finishes, call the
   * success or error callbacks. Successful response sets a cookie w/token
   * which is included in the retry xhr call - we don't need to parse the response.
   * @param  {function} successCallback
   * @param  {function} errorCallback
   * @return {undefined}
   */
  getApiToken: function getApiToken(successCallback) {
    const xhr = this.createCORSRequest(
      `${this.constants.baseUrl}/${this.constants.endpoints.token}`,
    );
    xhr.setRequestHeader(
      'Authorization',
      `Basic ${this.constants.apiCredentialDigest}`,
    );
    xhr.onload = successCallback;
    xhr.onerror = function onRetryError() {
      throw new Error('Error fetching API token.');
    };
    xhr.send();
  },

  getUser: function getUser(successCallback) {
    if (typeof dry !== 'undefined') {
      dry.events.subscribe('user:get', successCallback);
    }
  },

  /**
   * Parse xhr response for messageText and apply callbacks.
   * @param  {XMLHttpRequest} xhr
   * @param  {function} successCallback
   * @param  {function} errorCallback
   * @return {undefined}
   */
  handleAuthenticatedResponse: function handleAuthenticatedResponse(
    xhr,
    successCallback,
    method,
  ) {
    if (xhr.status >= 500) {
      throw new Error(`Unexpected error in API call ${xhr.responseText}`);
    } else {
      const responseJson = JSON.parse(xhr.responseText);
      const messageText = responseJson.messages
        ? responseJson.messages.pop()
        : '';
      // delete returns 204 no content when successful
      if ([200, 201].includes(xhr.status) || method === 'DELETE') {
        if (typeof successCallback === 'function')
          successCallback(responseJson);
      } else if (
        xhr.status === 400 &&
        messageText.includes('User favorite already exists')
      ) {
        throw new ExistingFavorite(messageText);
      } else {
        throw new Error(messageText);
      }
    }
  },

  /**
   * When original request failed due to invalid api token (401 response)
   * we retry the original request. Called after fetching new api token cookie.
   * @param  {String} url               Url to request
   * @param  {String} method            GET|POST|PUT|DELETE
   * @param  {Function} successCallback Do something on success
   * @param  {Function} errorCallback   Do something on error
   */
  retryAuthenticatedFetch: function retryAuthenticatedFetch(
    url,
    method,
    data,
    successCallback,
  ) {
    const xhr = this.createCORSRequest(url, method, data);
    xhr.onload = () => {
      try {
        this.handleAuthenticatedResponse(xhr, successCallback, method);
      } catch (err) {
        if (err instanceof ExistingFavorite) {
          successCallback();
        } else {
          throw err;
        }
      }
    };
    xhr.onerror = () => {
      throw new Error('Retry request failed.');
    };
    if (data) {
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(data));
    } else {
      xhr.send();
    }
  },

  /**
   * Send CORS compliant request to API, including one retry for missing API auth token.
   * @param  {String} url               Url to request
   * @param  {String} method            GET|POST|PUT|DELETE
   * @param  {Function} successCallback Do something on success
   * @param  {Function} errorCallback   Do something on error
   * @return {undefined}
   */
  authenticatedFetch: function authenticatedFetch(
    url,
    method,
    data = null,
    successCallback,
    errorCallback,
  ) {
    try {
      const xhr = this.createCORSRequest(url, method);
      if (typeof errorCallback !== 'function') {
        errorCallback = (err) => console.log(err); // eslint-disable-line no-console
      }

      // Response handlers.
      xhr.onload = () => {
        try {
          // User might not have the api auth token, fetch token cookie and retry xhr
          if (xhr.status === 401) {
            this.getApiToken(
              () =>
                this.retryAuthenticatedFetch(
                  url,
                  method,
                  data,
                  successCallback,
                ),
              errorCallback,
            );
            // otherwise, handle success or failure
          } else {
            this.handleAuthenticatedResponse(xhr, successCallback, method);
          }
        } catch (err) {
          if (err instanceof ExistingFavorite) {
            successCallback();
          } else {
            errorCallback(err);
          }
        }
      };

      xhr.onerror = function onRequestError() {
        throw new Error('Unexpected network error occurred.');
      };
      if (data) {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(data));
      } else {
        xhr.send();
      }
    } catch (err) {
      window.console && console.log(e); // eslint-disable-line
      errorCallback(err.message || 'Unexpected and unknown error occurred.');
    }
  },
};

export default widgetApi;
