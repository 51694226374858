import { createContext, useContext } from 'react';

interface MixpanelProperties {
  [key: string]: string;
}

interface AddPropertiesArgs {
  data: [string, string][];
}

interface AdjustPropertyArgs {
  propertyName: string;
  propertyValue: string;
}

type RemovePropertyArgs = Omit<AdjustPropertyArgs, 'propertyValue'>;

export const properties: MixpanelProperties = {};
const MixpanelContext = createContext<MixpanelProperties>({});

export const useMixpanelProvider = () => {
  const addProperties = ({ data }: AddPropertiesArgs) => {
    data.forEach((el) => {
      const [propertyName, propertyValue] = el;
      properties[propertyName] = propertyValue;
    });
  };

  const adjustSpecificProperty = ({
    propertyName,
    propertyValue,
  }: AdjustPropertyArgs) => {
    properties[propertyName] = propertyValue;
  };

  const removeProperty = ({ propertyName }: RemovePropertyArgs) => {
    delete properties[propertyName];
  };

  const retrieveProperty = (name: string) => {
    return properties[name];
  };

  const retrievePropertiesList = (nameList: string[]) => {
    const data: {
      [key: string]: string;
    } = {};
    nameList.forEach((name) => {
      if (properties[name]) {
        data[name] = properties[name];
      }
    });
    return data;
  };

  return {
    addProperties,
    adjustSpecificProperty,
    removeProperty,
    retrieveProperty,
    retrievePropertiesList,
  };
};

export const MixpanelProvider = MixpanelContext.Provider;

export const useMixpanelContext = () => {
  return useContext(MixpanelContext);
};
