import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

const DisableTrackingContext = createContext({});

export const DisableTrackingProvider = ({ children, ...restProps }) => (
  <DisableTrackingContext.Provider value={{ ...restProps }}>
    {children}
  </DisableTrackingContext.Provider>
);

DisableTrackingProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

function useDisableTrackingProvider() {
  const context = useContext(DisableTrackingContext);
  return context;
}

export default useDisableTrackingProvider;
