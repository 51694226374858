const breakpoints = {
  xlgDesktop: 1440,
  lgDesktop: 1136,
  desktop: 992,
  tabletPortrait: 768,
  tablet: 768,
  tabletLandscape: 1024,
  largePhoneMin: 377,
  phoneSmallMax: 375,
  phone: 0,
};

const contentMaxWidth = {
  phone: '100%',
  tablet: '56rem',
  desktop: '70.4rem',
};

const colors = {
  alto: '#DFDFDF', // 223, 223, 223
  altoTude: '#d0d0d0',
  amethyst: '#54479E',
  amethystShade: '#3D3285',
  arapawa: '#2e4a69', // 46, 74, 105
  atkAkaroa: '#d9cca7',
  asphalt: '#191919',
  black: '#000000',
  blumine: '#15657d',
  bridesmaid: '#F9DED6', // 249 222 214
  brownPod: '#402C1A',
  butteryWhite: '#fffdeb',
  charlotte: '#8fdee3', // 143 222 227
  coldPool: '#1A7AC4',
  cork: '#3f2b1e',
  danube: '#5E9ED6',
  darkColdPool: '#0466B0',
  concrete: '#F3F3F3', // 243 243 243
  cornflower: '#1D78CB', // 97 149 237
  eclipse: '#3D3D3D', // 61 61 61
  darkGrayBlue: '#507D7F', // 80, 125, 127
  darkerMint: '#5a8e91',
  darkTeal: '#4d7a7c',
  denim: '#416490', // 65, 100, 144
  dustyRose: '#e7dce1',
  facebook: '#527AA1', // 82, 122, 161
  fog: '#E2E2E2', // 226, 226, 226
  frog: '#408612',
  frost: '#e9f0f0',
  darkFrog: '#2F650B',
  darkSmog: '#383838',
  gainsboro: '#E8E8E8', // 216 216 216
  gainsboroDark: '#D8D8D8', // 216 216 216
  grapeShade: '#645B98',
  graphite: '#686868',
  grayBlue: '#5a9699', // 90, 150, 153
  harley: '#D33619', // 211, 54, 25
  icy: '#3D99E0',
  lightGray: '#d6d6d6', // 214 214 214
  linen: '#FBEBE8', // 251, 235, 232
  jade: '#00A26D', // 0 162 109
  jadeIsh: '#00a16c', // 0 161 108
  jadeIshAlpha: 'rgba(0, 161, 108, 0.1)',
  merlot: '#8c687a',
  morningDew: '#dde4e0',
  mystic: '#e9f0f0',
  nero: '#262626', // 35, 31, 32
  nobel: '#999999', // 153, 153, 153
  olive: '#587e19', // 88, 126, 25
  rust: '#A72E12', // 167 46 18
  shadyLady: '#979797', // 151 151 151
  summerSky: '#3d95e4', // 61 149 228
  pinterest: '#CF5553', // 207, 85, 83
  plum: '#7a4e63',
  regentGray: '#767f81', // 118 127 129
  rosemary: '#557262',
  sage: '#7b9688',
  seafoam: '#37b992', // 55, 185, 146
  silver: '#C2C2C2', // 194 194 194
  smokeyBlue: '#3077B6',
  smog: '#525151',
  solitude: '#e5e9ed',
  stormcloud: '#7A7A7A', // 122, 122, 122
  sunglow: '#fcbe36',
  suvaGray: '#8E8E8E', // 142 142 142
  tabasco: '#A72E13', // 167, 46, 19
  transparent: 'transparent',
  turquoise: '#8fdee3',
  tomato: '#D73A15', // 215 58 21
  twitter: '#79AED1', // 121, 174, 209
  wasabi: '#7EA042', // 126, 160, 66
  white: '#FFFFFF', // 255 255 255
  whiteSmoke: '#F5F5F5', // 246, 246, 246
  zircon: '#f7faff',
};

const socialColors = {
  email: colors.eclipse,
  emailHover: '#94856b',
  facebook: '#527aa1',
  facebookHover: '#43637a',
  pinterest: '#cf5553',
  pinterestHover: '#893b3b',
  twitter: '#79aed1',
  twitterHover: '#557d93',
};

const fonts = {
  clb: 'Clarendon Bold,serif',
  gdn: 'gooddog-new, sans-serif',
  mwr: 'Merriweather, serif',
  pnb: 'proximaNovaBold, sans-serif',
  pnr: 'proximaNovaRegular, sans-serif',
};

const fontSizes = {
  xxsm: '8px',
  xsm: '1rem',
  sm: '1.2rem',
  md: '1.4rem',
  lg: '1.6rem',
  xlg: '1.8rem',
  xxlg: '2.3rem',
  xxxlg: '3rem',
  xxxxlg: '3.7rem',
};

const letterSpacing = {
  xxsm: '0.2px',
  xsm: '0.8px',
  sm: '1.2px',
  md: '1.6px',
  lg: '1.9px',
  xlg: '2.6px',
  xxlg: '3px',
  xxxlg: '6px',
};

const lineHeights = {
  xxsm: 0.8,
  xsm: 1,
  sm: 1.15,
  md: 1.22,
  lg: 1.4,
  xlg: 1.6,
  xxlg: 1.8,
};

const checkbox = {
  checkboxBg: 'white',
  checkboxBorder: 'silver',
  checkboxBgHover: 'gainsboro',
  checkboxBorderHover: 'silver',
  checkboxBgChecked: 'gray',
  checkboxBorderChecked: 'gray',
  checkboxBgHoverChecked: 'gray',
  checkboxBorderHoverChecked: 'gray',
  checkboxBgDisabled: 'dimgray',
  checkboxBorderDisabled: 'darkslategrey',
};

const menuTransition = 'transform 0.3s ease-in-out';
const menuFooterHeight = 88;
const phoneBrowserToolbarClearance = 114;
const tabletBrowserToolbarClearance = 65;

module.exports = {
  breakpoints,
  contentMaxWidth,
  colors,
  checkbox,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
  variables: {
    menuTransition,
    menuFooterHeight,
    phoneBrowserToolbarClearance,
    tabletBrowserToolbarClearance,
    phoneMenuFooterHeight: menuFooterHeight + phoneBrowserToolbarClearance,
    tabletMenuFooterHeight: menuFooterHeight + phoneBrowserToolbarClearance,
  },
};
