import kidsTheme from './kids';

const {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
  variables,
} = kidsTheme;

const themeBreakpoints = {
  ...breakpoints,
  sm: 340,
  md: 696,
  lg: 848,
  xlg: 976,
  desktopMinusOne: breakpoints.desktop - 1,
};

const columns = {
  sm: 162,
  lg: 272,
};

const gutters = {
  md: 16,
};

const menuTransition = 'transform 0.3s ease-in-out';
const menuFooterHeight = 88;
const phoneBrowserToolbarClearance = 114;
const tabletBrowserToolbarClearance = 65;
const contentWidthSmall = '34rem';
const contentWidthMedium = '73.6rem';
const contentWidthLarge = '84.8rem';
const contentWidthXlarge = '97.6rem';

const themeVariables = {
  ...variables,
  contentWidthSmall,
  contentWidthMedium,
  contentWidthLarge,
  contentWidthXlarge,
  menuTransition,
  menuFooterHeight,
  phoneBrowserToolbarClearance,
  tabletBrowserToolbarClearance,
  phoneMenuFooterHeight: menuFooterHeight + phoneBrowserToolbarClearance,
  tabletMenuFooterHeight: menuFooterHeight + phoneBrowserToolbarClearance,
};

const kidsSearch = {
  siteKey: 'kidsSearch',
  themeColor: '#3d3d3d',
  colors,
  columns,
  fonts,
  fontSizes,
  gutters,
  lineHeights,
  breakpoints: themeBreakpoints,
  contentMaxWidth: '1136px',
  letterSpacing,
  socialColors,
  variables: themeVariables,
};

export default kidsSearch;
