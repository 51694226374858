import DocumentNav, {
  DocumentNavProps,
} from './partials/DocumentNav/DocumentNav';
import { BrandNav, BrandNavProps } from './partials/BrandNav/BrandNav';
import MainNav, { MainNavProps } from './partials/MainNav/MainNav';

export interface GlobalHeaderProps {
  brandNav: BrandNavProps;
  documentNav: DocumentNavProps;
  mainNav: MainNavProps;
  isDarkMode?: boolean;
  onClick: React.MouseEventHandler;
}

export const GlobalHeader: React.FC<GlobalHeaderProps> = ({
  brandNav,
  documentNav,
  mainNav,
  isDarkMode,
  onClick,
}: GlobalHeaderProps) => {
  return (
    <div
      className={isDarkMode ? 'global-header-dark-mode' : ''}
      id="global-header-wrapper"
      data-testid="global-header"
      onClick={onClick}
    >
      <BrandNav {...brandNav}></BrandNav>
      <MainNav {...mainNav} isDarkMode={isDarkMode} />
      <DocumentNav {...documentNav} />
    </div>
  );
};

export default GlobalHeader;
