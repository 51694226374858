function bouncex() {
  const bouncexEvt = document.createEvent('Event'); // eslint-disable-line no-undef
  bouncexEvt.initEvent('bouncex', true, true);

  // don't fire bouncex event on initial page load - only subsequent
  // pages initiated via React Router
  let initialPage = true;

  const trackRouterChange = () => {
    if (initialPage) {
      initialPage = false;
    } else {
      window.dispatchEvent(bouncexEvt); // eslint-disable-line no-undef
    }
  };

  return {
    trackRouterChange,
  };
}

export default bouncex;
