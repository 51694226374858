import widgetUtils from './widgetUtils/utils';

const favoritesTemplates = {
  popoverTemplate(triggerEl, innerHTML, classes = [], maxWidth = 300) {
    const winWidth = window.innerWidth;
    const isMobile =
      document.body.classList.contains('phone') || winWidth <= 375;
    const pos = widgetUtils.getPosition(triggerEl);
    const triggerWidth = triggerEl.offsetWidth;
    const gutterWidth = 16; // min gutter width
    let arrowPosition = 50; // % from left edge of popover

    const fitsRight =
      pos.x + triggerWidth / 2 + maxWidth / 2 < winWidth - gutterWidth;
    const fitsLeft = pos.x - gutterWidth + triggerWidth / 2 - maxWidth / 2 > 0;
    const fitsCenter = fitsRight && fitsLeft;

    const styles = [
      `top: ${pos.y + window.pageYOffset + triggerEl.offsetHeight + 15}px`,
    ];
    let styleBlock = '';
    let arrowStyle = '';

    styles.push(`width: ${maxWidth}px`);
    // fits with enough space on either side of the trigger to center the popover
    if (fitsCenter) {
      classes.push('centered');
      styles.push(`left: ${pos.x + triggerWidth / 2}px`);

      // fits to the right (the trigger is near the left margin)
    } else if (fitsRight) {
      arrowPosition =
        ((pos.x - gutterWidth + triggerWidth / 2) / maxWidth) * 100; // eslint-disable-line max-len
      arrowStyle = `left: ${arrowPosition}%`;
      styles.push(`left: ${gutterWidth}px`);

      // fits to the left (the trigger is enear the right margin)
    } else if (fitsLeft) {
      // since we're anchored to the right side of the page, we need to back out
      // the ARROW position so that we can set the styles using 'left' - renders more reliably
      arrowPosition =
        ((maxWidth -
          (winWidth - gutterWidth - (pos.x + triggerWidth) + triggerWidth) +
          triggerWidth / 2) / // eslint-disable-line max-len
          maxWidth) *
        100; // eslint-disable-line max-len
      arrowStyle = `left: ${arrowPosition}%`;
      styles.push(`left: auto; right: ${gutterWidth}px`);
    }

    if (!fitsCenter || isMobile) {
      styleBlock = `
      <style>
        .favorites-popover::before { ${arrowStyle} }
        .favorites-popover::after { ${arrowStyle} }
      </style>`;
    }

    return `
      <div
        class="favorites-popover ${classes.join(' ')}"
        style="${styles.join(';')};"
      >
        ${innerHTML}
        ${styleBlock}
      </div>
    `;
  },
};

export default favoritesTemplates;
