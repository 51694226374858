/* eslint-disable no-new */
import ATKAutocomplete from './autocomplete';
import FavoritesPopoverApp from './popover-app';
import FavoritesToggleApp from './toggle-app';
import widgetUtils from './widgetUtils/utils';
import { getCookies } from 'cookies-next';

export const favsOnReady = (callback) => {
  window.addEventListener('recent-auth', callback, false);
  callback();
};

export const runFavorites = () => {
  const { user_token } = getCookies();

  if (user_token) {
    const appEl = document.querySelector('body');
    const siteKey = widgetUtils.getSiteKey();
    if (!appEl.classList.contains(siteKey)) {
      appEl.classList.add(siteKey);
    }
    if (typeof FavoritesPopoverApp !== 'undefined') {
      new FavoritesPopoverApp(appEl);
    }
    if (typeof FavoritesToggleApp !== 'undefined') {
      new FavoritesToggleApp(appEl);
    }
  }
  if (typeof ATKAutocomplete !== 'undefined') {
    new ATKAutocomplete('#global-header-main-search');
  }
};
