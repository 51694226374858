import nClasses from './classnames';
import nCopy from './copy';
import nTemplates from './templates';
import NewsletterForm from './form';
import WidgetsAnalytics from '../FavoritesWidget/widgetUtils/analytics';
import widgetUtils, { WidgetState } from '../FavoritesWidget/widgetUtils/utils';

/**
 * When loaded onto a page (for mobile widths only), this widget will create a
 * small fixed-position widget at the bottom of the screen to collect emails.
 * Only renders for anonymous or registrant users.
 * Does not register if `newsletter_submitted` cookie is present
 * @constructor
 */
function NewsletterPopoverWidget(user, appEl, newsletterType, options) {
  this.appEl = appEl;
  this.newsletterType = newsletterType;
  this.nCopy = nCopy[this.newsletterType];
  this.options = options || {};
  this.state = new WidgetState({
    open: true,
    user: null,
  });
  const isMobile = window.innerWidth <= 668;
  // very basic test for recipe detail page
  const isRecipeDetail = /recipes\/[\d+]/.test(document.location.pathname);
  // pin pop will load on a 404 recipe because the pathname has '/recipes'
  // use this to make sure the page content is actually present
  const contentLoaded = document.querySelector('.detail-page-main');
  const hasPaywall = Boolean(document.querySelector('.has-paywall'));
  const hasMarketingHat = Boolean(document.querySelector('.hero-hat'));

  let doRender =
    appEl &&
    isMobile &&
    isRecipeDetail &&
    Boolean(contentLoaded) &&
    !hasPaywall;

  if (doRender) {
    // do not render if user has submitted form previously
    if (widgetUtils.getCookie(`${newsletterType}_newsletter_submitted`)) {
      doRender = false;
    } else if (!user && isRecipeDetail && hasMarketingHat) {
      doRender = false;
      // people who pay should not see the form
    } else if (user) {
      // pac free means it's free based on content rule in Nitro
      const isPacFree = contentLoaded
        ? contentLoaded.dataset.pacFree === 'true'
        : false;
      const {
        currentUserAccess: { segment },
      } = user;
      const isRegistrant = segment === 'registrant';
      const isPayingMember = !isRegistrant && segment !== 'anonymous';
      // paying members never see
      // registrants only see if not a Nitro doc
      if (
        isPayingMember ||
        (hasMarketingHat && isRegistrant && isPacFree === false)
      ) {
        doRender = false;
      }
    }

    if (!doRender) return;

    this.analytics = new WidgetsAnalytics();
    this.widgetEl = document.createElement('div');
    this.appEl.appendChild(this.widgetEl);
    this.form = new NewsletterForm(
      this.widgetEl,
      this.analytics,
      this.nCopy,
      this.newsletterType,
      this.options,
    );
    this.render();
    document.body.classList.add(this.bodyClass);
    this.handleClick = widgetUtils.debounce(
      this.handleClick.bind(this),
      250,
      true,
    );
    this.widgetEl.addEventListener('click', this.handleClick);
  }
}

NewsletterPopoverWidget.prototype = {
  bodyClass: 'newsletter-pin-pop',
  destroy() {
    this.widgetEl.removeEventListener('click', this.handleClick);
    this.form.destroy();
    this.widgetEl.remove();
    document.body.classList.remove(this.bodyClass);
  },
  /**
   * Getter for open
   */
  get open() {
    return this.state.get('open');
  },
  /**
   * Set open in state
   */
  set open(value) {
    if (value !== this.open) {
      const method = this.open ? 'remove' : 'add';
      this.widgetEl
        .querySelector(`.${nClasses.popoverWrapper}`)
        .classList[method](nClasses.open);
      this.state.set('open', value);
      this.trackToggleEvent();
    }
  },
  /**
   * Opens/closes the widget
   */
  toggle() {
    this.open = !this.open;
  },
  /**
   * Click event to capture the 'toggle' intent
   */
  handleClick(evt) {
    if (evt.target.closest(`.${nClasses.toggler}`)) {
      if (this.form.status === 'SUCCESS') {
        this.destroy();
      } else {
        this.toggle();
      }
    }
  },
  /**
   * Does what you would think it would do
   */
  render() {
    const form = this.form.render();
    const params = Object.assign(
      { form, nCopy: this.nCopy },
      this.state.freeze(),
    );
    this.widgetEl.innerHTML = nTemplates.fixedContainer(params);
  },
  trackToggleEvent() {
    this.analytics.trackEvent('NEWSLETTER_WIDGET_TOGGLE', { open: this.open });
  },
};

export default NewsletterPopoverWidget;
