import http from 'http';
import https from 'https';
import queryString from 'query-string';
import { getEndpoint } from './endpoints';

http.globalAgent.maxSockets = 100;

const api = {
  catchError(err) {
    console.error('Unexpected error in API: ', err.message, err.statusCode); // eslint-disable-line
    return {
      error: err,
      status: err.message.indexOf('timeout') !== -1 ? 503 : 500,
    };
  },
  async fetchToken() {
    let token = null;
    try {
      const tokenOptions = {
        method: 'GET',
        headers: {
          Authorization: 'Basic YXRrOmV6QkQyQXdqUW9zNjNFWmJwQ1VlWUF0dA==',
        },
        mode: 'cors',
      };

      const base = api.getUrlBase('v4');
      const tokenUrl = this.getUrl(base, '/token');
      const response = await fetch(tokenUrl, tokenOptions).catch((err) => {
        console.error('TOKEN FETCH ERROR', err); // eslint-disable-line
      });
      if (response && response.json) {
        const json = await response.json();
        token = json.token;
      }
    } catch (err) {
      console.error('JSON TOKEN PARSING ERROR'); // eslint-disable-line
      console.error(err); // eslint-disable-line
    }
    return token;
  },
  async getAuthorizationHeader() {
    const token = await this.fetchToken();
    return `Token token="${token}", client="atk"`;
  },
  async getOptions(requestType, authToken, secure = true, url, args = {}) {
    const { body, ua, ip, referer, userToken, ...restOptions } = args;
    // params key contains get parameters, don't use them here
    delete restOptions.params;
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'X-Band-Name': 'eCBiYW5kIG5hbWU',
      },
      method: requestType,
      referrerPolicy: 'origin',
      timeout: 15000,
      ...restOptions,
    };

    if (authToken) {
      options.headers['X-Auth-Token'] = authToken;
    }

    if (userToken) {
      options.headers['X-Access-Token'] = userToken;
    }

    if (referer) {
      options.headers['X-Referer'] = referer;
    }

    if (typeof body !== 'undefined') {
      options.body = JSON.stringify(body);
    }

    /**
     * If User-Agent contains cXensebot (Piano bot) or Googlebot, add in X-Scraper
     * header to make sure non-paywalled content is returned
     */
    if (ip && ua?.includes('cXensebot')) {
      options.headers['X-Scraper'] = `Cxense|${args.ip}`;
    } else if (ip && ua?.includes('Googlebot')) {
      options.headers['X-Scraper'] = `Googlebot|${args.ip.split(',')[0]}`;
    }

    let apiAuthToken = null;
    if (url.includes('api/v4')) {
      apiAuthToken = await this.getAuthorizationHeader();
    }

    if (typeof window === 'undefined') {
      const agentOptions = {
        keepAlive: true,
        timeout: 15000,
        scheduling: 'fifo',
      };
      if (!global.fetchAgents) {
        global.fetchAgents = {
          http: null,
          https: null,
        };
      }
      if (secure && !global.fetchAgents.https) {
        global.fetchAgents.https = new https.Agent(agentOptions);
      } else if (!secure && !global.fetchAgents.http) {
        global.fetchAgents.http = new http.Agent(agentOptions);
      }
      options.agent = secure
        ? global.fetchAgents.https
        : global.fetchAgents.http;
    } else {
      options.mode = 'cors';
    }
    if (apiAuthToken) options.headers.Authorization = apiAuthToken;
    return options;
  },

  async request(url, method, authToken, ...options) {
    let statusCode;
    let response;
    const secure = url.indexOf('https') !== -1;
    try {
      const requestOptions = await this.getOptions(
        method,
        authToken,
        secure,
        url,
        ...options,
      );
      response = await fetch(url, requestOptions);
      statusCode = response.status;

      // retry once for timeouts
      if (statusCode === 503) {
        console.error('RETRY', url); // eslint-disable-line
        response = await fetch(url, requestOptions);
        statusCode = response.status;
      }

      const isError = statusCode >= 400;
      if (typeof response.json !== 'undefined') {
        const payload = await response.json().catch((err) => {
          console.error('REQUEST RESPONSE.JSON PARSING ERROR', url); // eslint-disable-line
          console.error(err); // eslint-disable-line
        });
        if (isError) {
          const err = new Error(`REQUEST STATUS ERROR: ${statusCode} - ${url}`);
          err.statusCode = statusCode;
          return Promise.reject(err);
        }
        return payload;
      }
    } catch (err) {
      this.catchError(err);
      err.statusCode = statusCode;
      return Promise.reject(err);
    }
    return Promise.reject(
      new Error('Unexpected and unknown error in api.request'),
    );
  },
  siteKeyHostMap: {
    atk: 'americastestkitchen',
    cco: 'cookscountry',
    cio: 'cooksillustrated',
  },
  /**
   * @param {string} endpoint
   * @param {?string=} siteKey
   * @returns {string|undefined}
   */
  getUrlBase(endpoint, siteKey) {
    let base = getEndpoint(endpoint);
    if (!base) return undefined;

    // comments needs to be added to kraken
    if (endpoint === 'comments') return base;
    if (
      typeof document !== 'undefined' &&
      !document.location.host.includes('www-test')
    ) {
      if (document.location.hostname !== 'localhost') {
        const parser = document.createElement('a');
        parser.href = base;
        // IE11's `pathname` here doesn't include a leading slash - make sure to add one.
        base = parser.pathname === '/' ? '' : parser.pathname;
        if (parser.pathname.length > 0 && parser.pathname.charAt(0) !== '/') {
          base = `/${parser.pathname}`;
        }
      } else {
        base = '';
      }
      // if a siteKey is passed then we need to convert to domain name
      // this is important when the api uses the domain of the request
      // url as a query parameter/local variable (paywalls, etc)
    } else if (siteKey && typeof this.siteKeyHostMap[siteKey] !== 'undefined') {
      const host = this.siteKeyHostMap[siteKey];
      base = base.replace(
        /(https:\/\/)([^.]+).([^/]+.com)/,
        `$1$2.${host}.com`,
      );
    }
    return base;
  },
  getUrl(base, path, params) {
    const hasParams = params && Object.keys(params).length > 0;
    const qsArgs = hasParams ? queryString.stringify(params) : '';
    return `${base}${path}${qsArgs.length > 0 ? `?${qsArgs}` : ''}`;
  },
  getPaginationUrl(path, params) {
    const hasParams = params && Object.keys(params).length > 0;
    const qsArgs = hasParams ? queryString.stringify(params) : '';
    return `${path}${qsArgs.length > 0 ? `?${qsArgs}` : ''}`;
  },
};

// PUBLIC INTERFACE
const atk = {
  catchError: api.catchError,
  getUrl(endpoint, path, params, siteKey) {
    const base = api.getUrlBase(endpoint, siteKey);
    return api.getUrl(base, path, params);
  },
  getPaginationUrl(path, params) {
    return api.getPaginationUrl(path, params);
  },
  get(url, authToken, options = {}) {
    return api.request(url, 'GET', authToken, options);
  },
  index(url, authToken) {
    return api.request(url, 'GET', authToken);
  },
  patch(url, body, authToken) {
    return api.request(url, 'PATCH', authToken, { body });
  },
  post(url, body, authToken) {
    return api.request(url, 'POST', authToken, { body });
  },
  delete(url, body, authToken) {
    return api.request(url, 'DELETE', authToken, { body });
  },
};

export default atk;
