enum UserSegmentTypes {
  MULTISITE = 'multisite', // -- should have access to everything
  APP_ACCESS = 'app_access', // -- should have access to everything (no favorites, or comments, recommendations)
  ANONYMOUS = 'anonymous', // -- in app purchase visible
  STANDARD_MEMBER = 'standard_member', // -- should see call customer service screen
  STANDARD_OTHER = 'standard_other', // -- should see call customer service screen
  ANONYMOUS_LOGGED_IN = 'anonymous_logged_in', // -- in app purchase visible
  CANCELLED = 'canceled', // -- in app purchase visible
  CANCELLED_MEMBER = 'cancelled_member', // -- in app purchase visible
  CANCELLED_OTHER = 'cancelled_other', // -- in app purchase visible
  MEMBER = 'member', // -- in app purchase visible
  REGISTRANT = 'registrant', // -- in app purchase visible
  SCHOOL = 'school', // -- in app purchase visible
  UNKNOWN = 'unknown', // -- default if a user isn't logged in
  LOADING = 'loading', // -- is AuthContext inital state until response
}

export default UserSegmentTypes;
