import React from 'react';

const DryBase = `
function DryApiService() {
  this.apiToken = dry.getCookie('api_authorization_token');
}

DryApiService.prototype = {
  apiToken: null,
  env: {
    apiClient: 'atk',
    apiKey: 'ezBD2AwjQos63EZbpCUeYAtt',
    timeout: 5000,
  },
  /**
   * Generate fully qualified API url based on current environment
   * @param  {String} pathname URL Stem - must include leading forward slash
   * @param  {String} siteKey  Site for which we are fetching data
   * @return {String}          Final Url
   */
  getUrl: function (pathname, siteKey) {
    var path = pathname.charAt(0) === '/' ? pathname : '/' + pathname,
        hostname = window.location.hostname,
        url = path;

    if (hostname.indexOf('localhost') !== -1) {
      url = 'https://www-staging.cooksillustrated.com' + path;
    } else if (hostname.indexOf('www-test') !== -1) {
      var siteDomain = hostname.split('.').slice(-2).join('.');
      url = 'https://www-test.' + siteDomain + path;
    } else if (hostname.indexOf('school') !== -1) {
      url = 'https://' + hostname.replace('school', 'www') + path;
    }
    if (url.indexOf('site_key=') === -1 && url.indexOf('api/v4') !== -1) {
      if (!siteKey) siteKey = dry.siteKey;
      var joiner = url.indexOf('?') === -1 ? '?' : '&';
      url = url + joiner + 'site_key=' + siteKey;
    }
    return url;
  },
  /**
   * Fetch detail page data from api
   * @param  {String} site_key        Site for which we are fetching data
   * @param  {String} document_type   recipe, taste_test, etc
   * @param  {String} document_id     ID/Slug
   * @param  {Function} onSuccess     Callback when document is successfully fetched
   * @param  {Function} onError       Callback when error occurs
   */
  getDocument: function (site_key, document_type, document_id, onSuccess, onError) {
    DryApiService.prototype.get(
      this.getUrl('/api/v4' + '/' + document_type + '/' + document_id, site_key),
      onSuccess,
      onError
    );
  },
  /**
   * HTTP GET request
   * @param  {String}   url       URL to GET
   * @param  {Function} onSuccess Callback when all went well
   * @param  {Function} onError   Callback when all did not go well
   * @param  {Object}   options   Request config object
   */
  get: function (url, onSuccess, onError, options) {
    if (typeof options === 'undefined') options = {};
    options.method = 'GET';
    return this.fetch(url, onSuccess, onError, options);
  },
  /**
   * HTTP POST request
   * @param  {String}   url       URL to GET
   * @param  {Function} onSuccess Callback when all went well
   * @param  {Function} onError   Callback when all did not go well
   * @param  {Object}   options   Request config object
   */
  post: function (url, onSuccess, onError, options) {
    if (typeof options === 'undefined') options = {};
    options.method = 'POST';
    return this.fetch(url, onSuccess, onError, options);
  },
  /**
   * V4 API requests require a 'token', which validates our client as trusted
   * @param  {Function} onSuccess Callback when all went well
   * @param  {Function} onError   Callback when all did not go well
   */
  fetchApiToken: function (onSuccess, onError) {
    this.fetchingToken = true;
    return window.dry.api.doFetch(
      this.getUrl('/api/v4/token'),
      function (tokenResponse) {
        self.apiToken = tokenResponse.token;
        self.fetchingToken = false;
        onSuccess && onSuccess();
      },
      function () {
        self.fetchingToken = false;
        self.apiToken = null;
        onError && onError();
      },
      { basicAuth: this.env.apiClient + ':' + this.env.apiKey }
    );
  },
  /**
   * Check apiToken, fetching if necessary, then fetches requested url
   * @param  {String}   url       Fully qualified URL
   * @param  {Function} onSuccess Callback, receives response data
   * @param  {Function} onError   Callback, receives error
   * @param  {Object}   options   Request data (authToken, POST data, etc)
   */
  fetch: function (url, onSuccess, onError, options) {
    if (!window.dry.api.apiToken) {
      return window.dry.api.fetchApiToken(
        function () {
          return window.dry.api.doFetch(url, onSuccess, onError, options);
        },
        onError
      );
    } else {
      return window.dry.api.doFetch(url, onSuccess, onError, options);
    }
  },
  /**
   * Creates and sends a CORS network request.
   * NOTE: ASSUMES A JSON RESPONSE!!!
   * @param  {String}   url       Fully qualified URL
   * @param  {Function} onSuccess Callback, receives response data
   * @param  {Function} onError   Callback, receives error
   * @param  {Object}   options   Request data (authToken, POST data, etc)
   */
  doFetch: function (url, onSuccess, onError, options) {
    try {
      if (typeof options === 'undefined') options = {};

      // apiToken verifies client authenticity - i.e. not user creds
      options.apiToken = {
        token: this.apiToken,
        client: this.env.apiClient,
      };

      // authToken is the user session cookie
      var authToken = dry.getCookie('auth_token');
      if (authToken) options.authToken = authToken;

      var xhr = this.createCORSRequest(url, options);
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 400) {
          var data = JSON.parse(xhr.responseText);
          var messageText = data.messages && Array.isArray(data.messages) ? data.messages.pop() : null;
          if (messageText) {
            throw new Error(messageText);
          } else if (typeof onSuccess === 'function') {
            return onSuccess(data, xhr.response);
          }
        // 401 means our api token was old/bad, reset and try again
        } else if (xhr.status === 401) {
          self.apiToken = null;
          self.fetch(url, onSuccess, onError, options);
        } else {
          throw new Error('Error (' + xhr.status + ') returned from ' + url + ': ' + xhr.responseText);
        }
      }
      if (options.data && options.method === 'POST') {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(options.data));
      } else {
        xhr.send();
      }
    } catch (err) {
      console.error(err);
      if (typeof onError === 'function') {
        return onError(err);
      }
    }
  },
  /**
   * Creates and returns a cross browser CORS network request handle
   * @param  {String}   url       Fully qualified URL
   * @param  {Object}   options   Optional request config
   */
  createCORSRequest: function (url, options) {
    var opts = options || {};
    var method = opts.method || 'GET';
    var xhr = new (window.XMLHttpRequest || window.ActiveXObject)('MSXML2.XMLHTTP.3.0');
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    }
    xhr.timeout = opts.timeout || this.env.timeout;
    xhr.ontimeout = opts.ontimeout || function () {
      throw new Error('Server did not respond in a timely manner');
    };
    xhr.onerror = options.onerror || function onerror() {
      throw new Error('Error fetching ' + url);
    };
    // User auth token
    if (typeof opts.authToken !== 'undefined') {
      xhr.setRequestHeader('X-Auth-Token', opts.authToken);
    }
    // API basic authentication
    if (typeof opts.basicAuth !== 'undefined') {
      xhr.setRequestHeader('Authorization', 'Basic ' + btoa(opts.basicAuth));
    }
    // API token authentication
    if (typeof opts.apiToken !== 'undefined') {
      xhr.setRequestHeader('Authorization', 'Token token="' + opts.apiToken.token + '", client="' + opts.apiToken.client + '"');
    }
    return xhr;
  },
}

/**
 * Dry helper lib - named after 21 Dry Dock Ave...and (D)on't (R)epeat (Y)ourself
 * Purpose: crutch to help us get off of jQuery
 */
function Dry(win, doc) {
  var ready = false;

  /** START private functions */

  /* https://gist.github.com/Tomalak/818a78a226a0738eaade */
  function isNodeList(nodes) {
    var stringRepr = Object.prototype.toString.call(nodes);

    return typeof nodes === 'object'
      && /^[object (HTMLCollection|NodeList|Object)]$/.test(stringRepr)
      && typeof nodes.length !== 'undefined'
      && (nodes.length === 0 || (typeof nodes[0] === 'object' && nodes[0].nodeType > 0));
  }

  /**
   * Returns true if element is a dom node
   * @param  {Object}  element should be return value from query selector
   * @return {Boolean}
   */
  function isElement(val) {
    return val instanceof Element || val instanceof Document;
  }

  /**
   * Ensure els is a NodeList or Array (must iterate).
   * Accepts string (css selector),
   * list of elements (result of a querySelectorAll)
   * or a single DOM node
   * @param  {String|Element|NodeList} els
   * @param  {Element}                 context Optional query selector context
   * @return {NodeList|Array}
   */
  function toNodeList(val, context) {
    if (typeof context === 'undefined') context = doc;
    var nodeList = [];
    if (isNodeList(val)) {
      nodeList = val;
    } else if (isElement(val)) {
      nodeList = [val];
    } else if (typeof val === 'string' || !val) {
      nodeList = context.querySelectorAll(val || '#should---never---match');
    }
    return nodeList;
  }
  /** END private functions */

  /**
   * Limits the number of times a method can be called
   */
  this.debounce = function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(this, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(this, args);
    };
  };

  /**
   * Shortcut to doc ready - like jQuery ready
   */
  this.onReady = function (cb) {
    if (ready) {
      cb();
      this.events.subscribe('dom:ready', cb);
    } else {
      this.events.subscribe('dry:ready', cb);
    }
  };

  /**
   * Cross browser node list (or array) iteration
   * https://toddmotto.com/ditch-the-array-foreach-call-nodelist-hack/
   * dry.each('.matches-many', function (){}, )
   * dry.each('.matches-many', function (){}, '.header')
   * dry.each(myNodeList, function (){}, )
   * dry.each(myData, function (){}, )
   *
   * @param  {Array|NodeList|String}  val       Node list or array. Also could be css selector
   * @param  {Function}               callback  Function to execute for each item in list
   * @param  {Object}                 scope     Scope within which to execute the callback
   */
  this.each = function each(val, fn, scope) {
    if (typeof val === 'string') {
      if (typeof scope === 'string') scope = document.querySelector(scope);
      if (!scope) scope = doc;
      val = scope.querySelectorAll(val);
    } else if (isElement(val)) {
      val = [val];
    }
    for (var i = 0; i < val.length; i++) {
      fn.call(win, val[i], i);
    }
    return this;
  };

  /**
   * Fade Out - http://vanilla-js.com/
   * @param  {Element} el Element to be faded
   */
  this.fadeOut = function fadeOut(el, callback) {
    var hasCb = typeof callback === 'function';
    if (!el) {
      hasCb && callback();
      return;
    }
    if (typeof el === 'string') {
      el = document.querySelector(el);
    }
    var raf = win.requestAnimationFrame;
    var s = el.style;
    s.opacity = 1;
    (function fade() {
      if ((s.opacity -= 0.1) < 0) {
        s.display = 'none';
        hasCb && callback();
      } else {
        raf && raf(fade) || setTimeout(fade, 16);
      }
    }());
    return this;
  };

  /**
   * Even though we don't support IE11, let's not blow up
   * the site b/c we want to do .startsWith.
   * @param {*} val     Base string
   * @param {*} compare String that might be at the beginning of val
   */
  this.startsWith = function (val, compare) {
    return val.indexOf(compare) === 0;
  };

  /**
   * Return commonly used 'siteKey' value based on a hostname
   * @param  {String} hostname Which site do you want the key for?
   * @return {String}          Site key
   */
  this.getThemeSiteKey = function getSiteKey() {
    var siteKey = this.siteKeyFromPathname();
    var path = this.domainUrlFromSubfolderUrl(document.location.pathname);
    if ((this.startsWith(path, '/episode'))
        || (this.startsWith(path, '/show'))
        || (this.startsWith(path, '/video'))
        || (this.startsWith(path, '/proof'))
        || (this.startsWith(path, '/podcast'))
        || (this.startsWith(path, '/skill'))
        || (this.startsWith(path, '/whats-eating-dan'))
        || (this.startsWith(path, '/perfectly-seasonal'))
    ) {
      siteKey = 'play';
    }
    return siteKey;
  };

  /**
   * Get a cookie value
   * @param  {String} cname Cookie name
   * @return {String}       Cookie value
   */
  this.getCookie = function getCookie(cname) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + cname + '=');
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  /**
   * Set a value for a cookie
   * https://www.w3schools.com/js/js_cookies.asp
   * @param  {String}  cname   Cookie name
   * @param  {String}  cvalue  Cookie value
   * @param  {Integer} exdays  Days until expiration
   */
  this.setCookie = function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  };

  // https://davidwalsh.name/query-string-javascript
  this.getUrlParameter = function getUrlParameter(paramName) {
    var name = paramName.replace(/[[]/, '\\[').replace(/[]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/ +/g, ' '));
  };

  this.now = function now() {
    var f = function f(n) { return n < 10 ? '0' + n : n; };
    var date = new Date();
    return date.getUTCFullYear() + '-' + f(date.getUTCMonth() + 1) + '-' + (f(date.getUTCDate()) + 'T' + f(date.getUTCHours()) + ':') + (f(date.getUTCMinutes()) + ':' + f(date.getUTCSeconds()));
  };

  /**
   * Helper method - removes html...vaguely
   * @param  {String} val HTML content
   * @return {String}     Plain text content
   */
  this.stripHTML = function (val) {
    val = val.replace(/&nbsp;/gi, '');
    return val.replace(/(<([^>]+)>)/ig, '');
  };

  /**
   * Removes classname if element already has classname
   * Adds classname if element does not have classname
   * @param  {String}           cls  css classname to be added
   * @param  {NodeList|String}  els        NodeList or css selector
   * @param  {Element}          context    optional css selector context
   */
  this.toggleClass = function toggleClass(cls, els, context) {
    this.each(toNodeList(els, context), function (el) {
      var fn = el.classList.contains(cls) ? 'remove' : 'add';
      el.classList[fn](cls);
    });
    return this;
  };

  /**
   * Returns true if element already has css class
   * @param  {String}   classname  CSS classname to look for
   * @param  {Element}  el         Element to examine
   */
  this.hasClass = function hasClass(classname, el) {
    return el.classList.contains(classname);
  };

  /**
   * General note - we are deliberately avoiding arguments.slice
   * http://code.fitness/post/2015/11/javascript-function-arguments-do-not-slice.html
   */

  /**
   * Add or remove a node classname
   * ex: modifyClass('my-class-name', document.querySelector('.my-selector'))
   * ex: modifyClass('my-class-name', '.my-selector')
   *
   * @param  {Function}     fn   add or remove
   * @param  {String}       cls  css classname
   * @param  {String|Node}  el   css selector or DOM node
   */
  this.modifyClass = function modifyClass(/* fn, els, classes */) {
    var args = new Array(arguments.length);
    for (var i = 0; i < args.length; ++i) {
      args[i] = arguments[i];
    }
    var fn = args[0],
        els = args[1],
        classes = args.slice(2);
    if (classes.length) {
      this.each(toNodeList(els), function (el) {
        el.classList && el.classList[fn].apply(el.classList, classes);
      });
    }
    return this;
  };

  /**
   * Add or remove css classname (single or multiple) to DOM node
   * ex: addClass(document.querySelector('.my-selector'), 'class-one')
   * ex: addClass(.my-selector', 'class-one', 'class-two')

   * @param  {String|Node}  el   css selector or DOM node
   * @param  {String}       cls  css classname
   * @param  {String}       cls  css classname (optional)...
   */
  this.addClass = function (/* els, classes */) {
    var args = new Array(arguments.length + 1);
    args[0] = 'add'; // as in classList.add
    for (var i = 0; i < arguments.length; ++i) {
      args[i + 1] = arguments[i];
    }
    this.modifyClass.apply(this, args);
    return this;
  };

  /** same as above, but opposite! */
  this.removeClass = function () {
    var args = new Array(arguments.length + 1);
    args[0] = 'remove'; // as in classList.remove
    for (var i = 0; i < arguments.length; ++i) {
      args[i + 1] = arguments[i];
    }
    this.modifyClass.apply(this, args);
    return this;
  };

  /** helper for hiding based on ATK GH css */
  this.hide = function (els) {
    this.modifyClass('add', els, 'hidden');
    return this;
  };

  /** helper for unhiding based on ATK GH css */
  this.unhide = function (els) {
    this.modifyClass('remove', els, 'hidden');
    return this;
  };

  /** helper for marking as active based on ATK GH css */
  this.activate = function (els) {
    this.modifyClass('add', els, 'active');
    return this;
  };

  /** helper for removing active designation based on ATK GH css */
  this.deactivate = function (els) {
    this.modifyClass('remove', els, 'active');
    return this;
  };

  this.toggleActive = this.toggleClass.bind(this, 'active');
  this.toggleHidden = this.toggleClass.bind(this, 'hidden');
  this.isActive = this.hasClass.bind(this, 'active');

  /** helper for updating aria-expanded attribute for toggle buttons */
  this.toggleAriaExpanded = function (el) {
    var expanded = el.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';
    el.setAttribute('aria-expanded', expanded);
  };

  // pub/sub is declared/inserted into body so that it's immediately
  // available for all JS code. Make sure we include that code here.
  if (win.dry) {
    for (var nextKey in win.dry) {
      this[nextKey] = win.dry[nextKey];
    }
  }

  this.user = null;
  this.DOMContentLoaded = false;
  this.mpLoaded = false;

  this.setup = function () {
    /**
     * Loosely identify device based on screen size
     * @param  {Integer} bodyWidth Width of the screen
     * @return {String}           mobile/tablet/desktop
     */
    const getDeviceType = (bodyWidth) => {
      var deviceType = 'mobile';
      if (bodyWidth > 1100) {
        deviceType = 'desktop';
      } else if (bodyWidth > 768) {
        deviceType = 'tablet';
      }
      return deviceType;
    };

    this.deviceType = getDeviceType(document?.body?.clientWidth);
    
    window.dry.api = new DryApiService();
    window.dry.userService = new DryUserService();

    ready = true;
    window.dry.events.publish('dry:ready');

    window.dry.events.subscribe('dom:loaded', function () {
      window.dry.events.publish('dry:dom:loaded');
    });
  };
}

Dry.prototype = {
  get segment() {
    return this.userService ? this.userService.segment : 'anonymous';
  },
  get contentAccessUserSegment() {
    return this.userService ? this.userService.determineContentAccessUserSegment() : 'anonymous';
  },
};

/**
 * @typedef {'atk'|'cio'|'cco'} SiteKey
 */

Dry.prototype.siteKeyFromPathname = siteKeyFromPathname;
/**
 * @returns {SiteKey}
 */
function siteKeyFromPathname() {
  if (typeof window === 'undefined') {
    throw Error('siteKeyFromPathname should only be called on browser');
  }

  const pathname = window.location.pathname;
  if (this.startsWith(pathname, '/cooksillustrated')) return 'cio';
  if (this.startsWith(pathname, '/cookscountry')) return 'cco';
  return 'atk';
}

/**
 * Gets siteKey from either a subfoldered relative url, or an absolute url of either type.
 * @param {string} url
 * @returns {'atk'|'cio'|'cco'|''}
 */
Dry.prototype.domainSiteKeyFromUrl = function domainSiteKeyFromUrl(url) {
  // relative url subfoldered
  if (this.startsWith(url, '/')) {
    if (this.startsWith(url, '/cookscountry')) return 'cco';
    if (this.startsWith(url, '/cooksillustrated')) return 'cio';
    return 'atk';
  }

  // full url subfoldered
  const split = url.split('.com', 2);
  const hostname = split[0];
  const pathname = split[1];
  if (hostname.includes('americastestkitchen')) {
    if (this.startsWith(pathname, '/cookscountry')) return 'cco';
    if (this.startsWith(pathname, '/cooksillustrated')) return 'cio';
    return 'atk';
  }

  // full url old domains
  if (hostname.includes('cookscountry')) return 'cco';
  if (hostname.includes('cooksillustrated')) return 'cio';
  return '';
};

/**
 * Gets old pre-subfolder url for plugging into old code with error prone url matching.
 * Works for relative and absolute urls.
 * A lot of old logic compares urls. Its usually easier to pass old versions of urls
 *  than rewrite the comparisons.
 * @param {string} url
 * @return {string}
 */
Dry.prototype.domainUrlFromSubfolderUrl = function domainUrlFromSubfolderUrl(url) {
  // relative urls subfoldered (remove subfolder path)
  if (this.startsWith(url, '/')) {
    return url.replace('/cooksillustrated', '').replace('/cookscountry', '');
  }

  // full urls non-subfoldered (no-change)
  if (!url.includes('americastestkitchen.com')) return url;

  // full urls atk subfoldered (no-change)
  const siteKey = this.domainSiteKeyFromUrl(url);
  if (siteKey === 'atk') return url;

  // full urls cio/cco subfoldered (replace domain name and remove subfolder path)
  const domainName = this.domainFromSiteKey(siteKey);
  return url
    .replace('americastestkitchen.com/' + domainName, domainName + '.com');
};

/**
 * For domain name comparisons using siteKey
 * @param {'atk'|'cio'|'cco'|''} siteKey
 * @returns {'americastestkitchen'|'cooksillustrated'|'cookscountry'|''}
 */
Dry.prototype.domainFromSiteKey = function domainFromSiteKey(siteKey) {
  switch (siteKey) {
  case 'atk': return 'americastestkitchen';
  case 'cio': return 'cooksillustrated';
  case 'cco': return 'cookscountry';
  default: return '';
  }
};

Dry.prototype.subfolderUrl = subfolderUrl;
/**
 * Skips urls that aren't relative (starting with /)
 * Skips already subfoldered urls
 * Skips other specified urls i.e. /gift
 * @param {string} url
 * @param {SiteKey} siteKey
 * @returns {string}
 */
function subfolderUrl(url, siteKey) {
  siteKey = siteKey || this.siteKeyFromPathname();
  if (
    !this.startsWith(url, '/')
    || this.startsWith(url, '/cookscountry')
    || this.startsWith(url, '/cooksillustrated')
    || this.startsWith(url, '/gift')
    || this.startsWith(url, '/sign_in')
    || this.startsWith(url, '/sign_out')
  ) {
    return url;
  }

  // WEB-813: cio tv listing should point to atk
  if (siteKey === 'cio' && this.startsWith(url, '/tv_schedule')) return url;

  if (siteKey === 'cio') return '/cooksillustrated' + url;
  if (siteKey === 'cco') return '/cookscountry' + url;

  return url;
}

/**
 * @param {string} url
 * @returns {string}
 */
Dry.prototype.subfolderUrlQueryRefinement = subfolderUrlQueryRefinement;
function subfolderUrlQueryRefinement(url) {
  try {
    var siteKey = this.siteKeyFromPathname();
    var urlObj = new URL(url, window.location.origin);
    return urlObj.toString();
  } catch (_) {
    return url;
  }
}

window.dry = new Dry(window, document);

/**
 * Fire setup ASAP, which means as soon as code
 * in _dry_inline.html.erb has run in the browser.
 * setTimeout is used so that dry as an object has been
 * completely processed before setup is called
 */
if (window.dry.DOMContentLoaded === true) {
  window.dry.setup();
} else {
  window.dry.events.subscribe('dry:events', dry.setup);
}

/* eslint-disable */
/**
 * DryUserService is responsible for publishing
 * user data to all JS code. See bottom of function
 * for parsing/fetching logic.
 */
function DryUserService() {
  var win = window;

  function getDefaultUser() {
    return {
      currentUser: null,
      currentUserAccess: {
        dfpMembershipString: 'anonymous',
        role: 'guest',
        segment: 'anonymous',
      },
      deviceContext: {
        device: dry.deviceType
      }
    };
  }

  var user = getDefaultUser();
  var notify = {
    /**
     * Notify audience that an error occurred
     */
    error: function () {
      dry.events.publish('user:get:error', user);
    },
    /**
     * Notify audience that user request is pending
     */
    pending: function () {
      dry.events.publish('user:get:pending', user);
    },
    /**
     * Notify audience that user request is complete
     */
    success: function () {
      dry.user = user;
      dry.events.publish('user:get', user);
    },
    /**
     * Notify audience that the user is empty/default
     */
    anonymous: function () {
      dry.user = getDefaultUser();
      dry.events.publish('user:get', dry.defaultUser);
    }
  }

  /**
   * See link from next method
   */
  function b64DecodeUnicode (str) {
    return decodeURIComponent(
      Array.prototype.map.call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
  }

  /**
   * Parse a jwt token, returning the raw user object
   * See: https://stackoverflow.com/a/30106551
   * @param  {String} token  Fully encoded JWT Token
   * @return {String}        Fully decoded JWT Token data
   */
  function parseJwt(token) {
    var decoded = b64DecodeUnicode(
      token.split('.')[1].replace('-', '+').replace('_', '/')
    );
    return JSON.parse(decoded);
  }

  /**
   * For development of onboarding only - should remove later?
   */
  var defaultSurvey = {
    activeAt: Date.now(),
    completionType: '',
    location: '',
    name: '',
    viewedAt: null,
    optoutDate: null,
    surveyId: 1
  };

  /**
   * Transform user_token structure to our dry.user structure
   * @param  {Object}  userToken  Raw user data from user_token
   * @return {Object}             Normalized user object
   */
  function setUserFromJwtToken(userData) {
    try {
      // lack of createdAt means they need a new user_token cookie from api
      // returning immediately will prompt an api call to fetch a new user_token
      if (!userData.survey) return;
      user = {
        currentUser: {
          fullName: userData.first_name + ' ' + userData.last_name,
          firstName: userData.first_name,
          email: userData.email,
          externalId: userData.external_id,
          id: userData.id,
          idDigest: userData.id_digest
        },
        currentUserAccess: {
          activeCDSMembership: userData.active_cds_membership,
          package: userData.package_name,
          role: userData.role,
          registrationSiteKeys: userData.active_registrations || [],
          siteMemberships: userData.active_memberships || [],
          cancelledSiteMemberships: userData.cancelled_memberships || [],
          dfpMembershipString: userData.dfp_membership_string,
          segment: userData.segment,
        },
        deviceContext: {
          device: dry.deviceType
        },
        survey: userData.survey || defaultSurvey,
      };
      notify.success();
    } catch (err) {
      console.error('JWT token parsing has failed:', err);
      handleUserError();
    }
  }

  /**
   * Callback which fires upon successfully fetching a new user token.
   * Notifies audience of successful data retrieval
   * @param  {Object} response  User token data
   * @return {Object}           Normalized user object
   */
  function handleUserSuccess(response) {
    setUserFromJwtToken(response.user_token);
  }

  /**
   * Callback which fires upon v4 user error
   * @return {Object} default user object
   */
  function handleUserError() {
    notify.error();
    notify.anonymous();
  }

  /**
   * Retrieves data from the v4 api
   * @return {Promise} Promise from api
   */
  function fetch() {
    notify.pending();
    var userUrl = dry.api.getUrl('midas/api/v1/user-token');
    dry.api.get(userUrl, handleUserSuccess, handleUserError);
  }

  function update() {
    /**
     * 1) jwt cookie/page embed -> parse -> publish
     * 2) no jwt -> page data -> parse -> publish
     * 3) no jwt -> no page data -> api call -> parse -> publish
     */
    var doRefresh = document.location.href.includes('refreshUserToken=true');
    var jwtToken = dry.getCookie('user_token');
    if (!doRefresh && jwtToken) setUserFromJwtToken(parseJwt(jwtToken));

    // fetch the user if missing or incomplete user_token
    if (!user.currentUser) {
      if (dry.getCookie('auth_token')) {
        fetch();
      } else {
        notify.anonymous(); // empty user!
      }
    }
  }

  /**
   * POST request to merge mixpanel user identities
   * This request always returns 200 - no need for callbacks
   */
  function mixpanelMerge() {
    var authToken = dry.getCookie('auth_token');
    var mergeUrl = dry.api.getUrl('api/v4/mixpanel_merge');
    dry.api.post(mergeUrl);
  }

  function enableMerge() {
    dry.events.subscribe('mixpanel:merge', mixpanelMerge);
  }

  dry.defaultUser = getDefaultUser();
  update();
  enableMerge();

  return {
    get data() {
      return user;
    },
    get segment() {
      return user.currentUserAccess.segment;
    },
    fetch: fetch,
    update: update,
    activeMemberships: function () {
      return user.currentUserAccess.siteMemberships || [];
    },
    activeRegistrations: function () {
      return user.currentUserAccess.registrationSiteKeys || [];
    },
    cancelledMemberships: function () {
      return user.currentUserAccess.cancelledSiteMemberships || [];
    },
    currentSite: function () {
      return dry.siteKey || 'atk';
    },
    isLoggedIn: function () {
      return user.currentUser !== null;
    },
    isRegistrant: function () {
      var activeRegistration = this.isRegisteredOn(this.currentSite());
      var noCancellations = this.cancelledMemberships().length === 0
        || (this.cancelledMemberships().length === 1 && this.isCancelledMemberOf('school'));

      return (activeRegistration && noCancellations);
    },
    isMultisite: function () {
      return (this.isMemberOf('atk') && this.isMemberOf('cco') && this.isMemberOf('cio'));
    },
    isPremium: function () {
      return (this.isMemberOf('cookbook_collection') && (this.isMemberOf('atk') || this.isMemberOf('cco') || this.isMemberOf('cio')));
    },
    isMember: function () {
      // don't care about standard vs premium yet
      return this.activeMemberships().indexOf(this.currentSite()) !== -1;
    },
    isStandardMemberOther: function () {
      if (this.activeMemberships().length === 0 || this.isMember() || (this.activeMemberships().length === 1 && this.isMemberOf('school'))) {
        return false;
      }
      return true;
    },
    isCancelledMember: function () {
      return (this.isCancelledMemberOf(this.currentSite()) && this.activeMemberships().length === 0);
    },
    isCancelledMemberOther: function () {
      return (this.activeMemberships().length === 0
        && this.cancelledMemberships().length > 0
        && !this.isCancelledMemberOf(this.currentSite()));
    },
    isMemberOf: function (site_key) {
      return (this.activeMemberships().indexOf(site_key) !== -1);
    },
    isRegisteredOn: function (site_key) {
      return (this.activeRegistrations().indexOf(site_key) !== -1);
    },
    isCancelledMemberOf: function (site_key) {
      return (this.cancelledMemberships().indexOf(site_key) !== -1);
    },
    determineContentAccessUserSegment: function() {
      var segment = 'anonymous';
      if (this.isLoggedIn()) {
        // Determine known user segment
        if (this.isMultisite()) {
          segment = 'multisite';
        } else if (this.isPremium()) {
          segment = 'multisite';
        } else if (this.isMember()) {
          segment = 'standard_member';
        } else if (this.isStandardMemberOther()) {
          segment = 'standard_other';
        } else if (this.isCancelledMember()) {
          segment = 'cancelled_member';
        } else if (this.isCancelledMemberOther()) {
          segment = 'cancelled_other';
        } else if (this.isRegistrant()) {
          segment = 'registrant';
        } else {
          segment = 'anonymous_logged_in';
        }
      }
      return segment;
    },
  };
};
`;

const DryBaseJs = () => {
  return (
    <script
      id="global-base-js-url"
      key="global-base-js-url"
      defer
      dangerouslySetInnerHTML={{
        __html: DryBase,
      }}
    />
  );
};

export default DryBaseJs;
