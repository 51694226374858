import {
  GET_COURSE_FULFILLED,
  GET_COURSE_PENDING,
  GET_COURSE_REJECTED,
} from '../actions/courses';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  data: {},
  status: c.DEFAULT,
};

const courses = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE_PENDING:
      return {
        ...state,
        status: c.LOADING,
      };

    case GET_COURSE_REJECTED:
      return {
        ...state,
        status: c.ERROR,
      };

    case GET_COURSE_FULFILLED:
      const { query, course } = action.payload;
      return {
        data: {
          ...state.data,
          [query]: course,
        },
        status: c.READY,
      };

    default:
      return state;
  }
};

export default courses;
