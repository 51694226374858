import cloudinary from 'cloudinary-core';
import { cloudinaryName } from './cloudinary';

const cloudName = cloudinaryName;
/** @type {import('cloudinary-core').Cloudinary} */
const cloudinaryInstance = cloudinary.Cloudinary.new({
  cloud_name: cloudName,
});

const baseImageConfig = {
  crop: 'fill',
  dpr: 2.0,
  fetch_format: 'auto',
  flags: ['lossy', 'progressive', 'strip_profile'],
  gravity: 'faces:auto',
  quality: 'auto:low',
  secure: true,
};

const isString = (obj) =>
  Object.prototype.toString.call(obj) === '[object String]';

const isFloat = (n) => Number(n) === n && n % 1 !== 0;

/**
 * Some Contentful-cloudinary images are coming back with decimal
 * aspect ratios - we want to convert those into standard string-based
 * values.
 * @param  {Integer|Float|String} aspectRatio
 * @return {String}               Normalized aspect ratio - 16:9, 1:1
 */
const normalizeAR = (aspectRatio) => {
  let [width, height] = [1, 1];
  if (aspectRatio) {
    if (isString(aspectRatio) && aspectRatio.includes(':')) {
      [width, height] = aspectRatio.split(':');
    } else if (isFloat(aspectRatio)) {
      width = Math.floor(aspectRatio * 10);
      height = 10;
    }
  }
  return `${width}:${height}`;
};

const getDefaultImageConfig = () => ({
  cloud_name: cloudName,
  crop: 'fill',
  dpr: 2.0,
  fetch_format: 'auto',
  flags: 'lossy',
  gravity: 'faces',
  quality: 'auto:low',
  secure: true,
});

const imageConfig = {
  blank(aspectRatio = null) {
    const ratio = normalizeAR(aspectRatio);
    const [width, height] = ratio.split(':');
    const config = { height, width, crop: 'scale' };
    const { cloud_name, secure } = baseImageConfig; // eslint-disable-line camelcase
    if (aspectRatio) config.aspect_ratio = aspectRatio;
    return {
      ...config,
      cloud_name,
      secure,
    };
  },
  bookAd(options = {}) {
    return {
      ...baseImageConfig,
      ...options,
      gravity: 'center',
    };
  },
  brandIcon() {
    return {
      cloud_name: baseImageConfig.cloud_name,
      secure: true,
    };
  },
  commentPhoto() {
    return {
      crop: 'scale',
      fetch_format: 'auto',
      secure: true,
      width: 250,
    };
  },
  commentPhotoModal() {
    return {
      crop: 'scale',
      fetch_format: 'auto',
      secure: true,
      width: 500,
    };
  },
  cookingModeImage(options = {}) {
    return {
      ...imageConfig.default(options),
      aspectRatio: '3:2',
      width: 400,
    };
  },
  default(options = {}) {
    const config = { ...options };
    const { aspectRatio, height, width } = options;
    if (width && height && aspectRatio) {
      delete config.aspectRatio;
    }
    // set a sensible max-width if no dimensions were provided
    if (!width && !height) {
      config.width = 400;
    }
    return {
      ...baseImageConfig,
      ...config,
    };
  },
  documentCard(options = {}) {
    const config = {
      background: 'black',
      ...options,
      effect: 'gradient_fade:20',
      gravity: 'faces:center',
      y: options.height ? `-${Math.round(options.height * 0.75)}` : '-0.25',
    };
    const { aspectRatio, height, width } = options;
    if (width && height && aspectRatio) {
      delete config.aspectRatio;
    }
    return {
      ...baseImageConfig,
      ...config,
    };
  },
  documentSuggestion() {
    return {
      ...getDefaultImageConfig(),
      width: 300,
    };
  },
  documentTitleImage(options = {}) {
    const { cloud_name, secure } = baseImageConfig; // eslint-disable-line camelcase
    if (!options.width) {
      options.width = 150;
    }
    return {
      ...options,
      cloud_name,
      crop: 'scale',
      dpr: '2.0',
      fetch_format: 'auto',
      quality: 'auto:eco',
      secure,
    };
  },
  figure(options = {}) {
    return {
      ...imageConfig.thumbnail(options),
      dpr: '1.0',
    };
  },
  quickViewImage(options = {}) {
    return {
      ...imageConfig.default(options),
      width: 200,
    };
  },
  thumbnail(options = {}) {
    const config = { ...options };
    const { aspectRatio, height, size = 'large', width } = options;
    if ((width && height && aspectRatio) || aspectRatio === 'auto') {
      delete config.aspectRatio;
    }
    // set a sensible max-width
    if (!width && !height) {
      config.width = size === 'small' ? 90 : 270;
    }

    return {
      ...baseImageConfig,
      ...config,
    };
  },
  sponsor() {
    return {
      ...baseImageConfig,
      fetch_format: 'png',
      width: 150,
    };
  },
  videoThumbnail(options = {}) {
    return {
      ...baseImageConfig,
      aspect_ratio: '16:9',
      gravity: 'faces:center',
      width: 1200,
      ...options,
    };
  },
  searchResultImage(displayFormat, aspectRatio = '1:1', ...args) {
    let size = displayFormat === 'grid' ? 200 : 60;
    // magazine covers
    if (aspectRatio === '5:6') {
      size = 200;
    }
    return {
      ...getDefaultImageConfig(...args),
      aspect_ratio: aspectRatio,
      height: size,
    };
  },
  skillHero() {
    return {
      ...baseImageConfig,
      height: 465,
      width: 1136,
      background: 'black',
      effect: 'gradient_fade:20',
      gravity: 'faces:center',
      y: '-465',
    };
  },
  skillCard() {
    return {
      ...baseImageConfig,
      aspect_ratio: '56:33',
      gravity: 'faces:center',
      width: 400,
    };
  },
  bottomGradientFade(options) {
    return {
      effect: 'gradient_fade',
      y: '-0.5',
      ...options,
    };
  },
  videoTest(options) {
    return {
      secure: true,
      width: 400,
      resource_type: 'video',
      quality: 'auto:low',
      ...options,
    };
  },
  platformsGif(options) {
    return {
      background: '#FFFFFF',
      width: 40,
      ...options,
    };
  },
};

/**
 * Returns url for placeholder png for lazy loaded images
 * @param  {String} [aspectRatio=null] Aspect ratio for generated image
 * @return {String}                    Fully qualified url for a Cloudinary image
 */
const getBlankImage = (aspectRatio = null) =>
  cloudinaryInstance.url('blank_glkq7m', imageConfig.blank(aspectRatio));

/**
 * Returns Cloudinary url.
 * If second argument is a string, assume it matches a
 * configuration key (thumbnail, etc).
 * Pass remaining arguments (usually a config object)
 * to the specified template (or the default template)
 * Examples:
 * `getImageUrl('<someCloudinaryId>', 'thumbnail', { width: 75 })`
 *    => returns a 75x75 thumbnail
 * `getImageUrl('<someCloudinaryId>', { aspectRatio: '16:9', height: 300 })`
 *    => returns a 16:9 image that is 300px tall
 *  NOTE: documentation for valid options:
 *      https://cloudinary.com/documentation/image_transformation_reference
 * @param  {String} cloudinaryId Cloudinary primary key
 * @param  {Array} configArgs    Capture remaining arguments as array
 * @return {String}              Fully qualified url for a Cloudinary image
 */
const getImageUrl = (cloudinaryId, ...configArgs) => {
  if (!cloudinaryId) return null;
  const configKey =
    typeof configArgs[0] === 'string' ? configArgs.shift() : 'default';
  const key = imageConfig[configKey] ? configKey : 'default';
  const config = imageConfig[key].call(this, ...configArgs);
  if (config.aspectRatio) config.aspectRatio = normalizeAR(config.aspectRatio);
  return cloudinaryInstance.url(cloudinaryId, config);
};

const getGifSrcSet = (cloudinaryId, configKey, options) => {
  const formats = { mp4: 'mp4', webm: 'webm', gif: 'gif', poster: 'jpg' };
  let config;
  return Object.keys(formats).reduce((acc, fmt) => {
    config = imageConfig[configKey].call(this, {
      ...options,
      fetch_format: formats[fmt],
    });
    acc[fmt] = cloudinaryInstance.url(cloudinaryId, config);
    return acc;
  }, {});
};

export default cloudinaryInstance;

export {
  baseImageConfig,
  getGifSrcSet,
  imageConfig,
  getBlankImage,
  getImageUrl,
};
