import React from 'react';

import BlueconicRelateds from './components/BlueconicRelateds';
import Script from 'next/script';

// .env.local setting to cleanup error logs
const disable = process.env.NEXT_PUBLIC_DISABLE_BLUECONIC === 'true';

const Blueconic = () =>
  disable ? null : (
    <>
      {/* Lee said okay to not preload in case Sarah D asks lol */}
      {/* <link
        as="script"
        href="//cdn.blueconic.net/atk.js"
        key="blueconic-preload"
        rel="preload"
      /> */}
      <Script defer key="blueconic-js" src="//cdn.blueconic.net/atk.js" />
    </>
  );

Blueconic.Relateds = BlueconicRelateds;

export default Blueconic;
