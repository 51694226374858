import {
  REGISTRATION_SUBMISSION_PENDING,
  REGISTRATION_SUBMISSION_FULFILLED,
  REGISTRATION_SUBMISSION_REJECTED,
} from '../actions/registration';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

export const initialState = {
  data: null,
  error: null,
  status: c.DEFAULT,
};

const registration = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_SUBMISSION_PENDING:
      return {
        data: action.payload.data,
        error: null,
        status: c.LOADING,
      };

    case REGISTRATION_SUBMISSION_REJECTED:
      return {
        ...state,
        error: action.payload.statusCode,
        status: c.ERROR,
      };

    case REGISTRATION_SUBMISSION_FULFILLED:
      return {
        data: { ...state.data, ...action.payload },
        error: null,
        status: c.COMPLETE,
      };

    default:
      return state;
  }
};

export default registration;
