const nCopy = {
  dinnertonight: {
    header: 'Sign up for Dinner Tonight',
    intro:
      'What to cook and how many tbsp are in a shallot? Answers in your inbox.',
    form: {
      errors: {
        empty: 'Email is required.',
        invalid: 'Please provide a valid email address.',
        unknown:
          'We are unable to process your request at this time. Please try again later.',
      },
      placeholder: 'Enter your email address',
      submit: 'SIGN ME UP',
      success: {
        button: 'CLOSE',
        text: 'Keep an eye on your inbox, and we wish you a tasty, easy dinner.',
        title: 'All signed up!',
      },
    },
    privacy:
      'America’s Test Kitchen will not sell, rent, or disclose your email address to third parties unless otherwise notified. Your email address is required to identify you for free access to content on the site. You will also receive free newsletters and notification of America’s Test Kitchen specials.',
  },
  ntk: {
    header: 'Notes From the Test Kitchen',
    intro:
      'The latest seasonal recipes and notes from our experts delivered every Friday.',
    form: {
      errors: {
        empty: 'Email is required.',
        invalid: 'Please provide a valid email address.',
        unknown:
          'We are unable to process your request at this time. Please try again later.',
      },
      placeholder: 'Enter your email address',
      submit: 'SIGN ME UP',
      success: {
        button: 'CLOSE',
        text: 'Now you have yet another reason to look forward to Fridays . . .',
        title: 'All set!',
      },
    },
    privacy:
      'America’s Test Kitchen will not sell, rent, or disclose your email address to third parties unless otherwise notified. Your email address is required to identify you for free access to content on the site. You will also receive free newsletters and notification of America’s Test Kitchen specials.',
  },
  shortcutstodinner: {
    header: 'Shortcuts to Dinner',
    intro:
      'One ingredient per issue, twice a week, for six weeks. Perfect for breaking up that cooking rut.',
    form: {
      errors: {
        empty: 'Email is required.',
        invalid: 'Please provide a valid email address.',
        unknown:
          'We are unable to process your request at this time. Please try again later.',
      },
      placeholder: 'Enter your email address',
      submit: 'SIGN ME UP!',
      success: {
        button: 'CLOSE',
        text: 'Your first issue of Shortcuts to Dinner is on its way to your inbox now.',
        title: 'Thanks for signing up!',
      },
    },
    privacy:
      'America’s Test Kitchen will not sell, rent, or disclose your email address to third parties unless otherwise notified. Your email address is required to identify you for free access to content on the site. You will also receive free newsletters and notification of America’s Test Kitchen specials.',
  },
};

export default nCopy;
