import nClasses from './classnames';

const nStyles = {
  formWidget() {
    return `
      .${nClasses.intro} { margin-bottom: 16px; padding-right: 25px; }
      .${nClasses.form}.success ~ .${nClasses.intro} { display: none; }
      .${nClasses.form} small { display: block; font-size: 10px; font-style: italic; line-height: 14px; overflow: hidden; width: 100%; }
      .${nClasses.form} input, .${nClasses.form} button { display: block; padding: 0; width: 100%; }
      .${nClasses.form} input[type="email"] { background: #fff; border: 1px solid #c2c2c2; border-radius: 0; color: #3d3d3d; font: 16px "Merriweather", serif; margin-bottom: 8px; outline: none; padding: 15px 10px; -webkit-appearance: none; }
      .${nClasses.form} input::placeholder { color: #3d3d3d; font: italic 16px "Merriweather", serif; }
      .${nClasses.form} button[type="submit"] { background-color: #3d95e4; border: 0; box-shadow: 4px 4px 8px 0 rgba(119, 119, 119, 0.53); color: #fff; cursor: pointer; font: 17px/14px "proximaNovaBold", sans-serif; height: 50px; letter-spacing: 1.36px; margin-bottom: 14px; text-align: center; -webkit-appearance: none; }
      .${nClasses.form} button[type="submit"]:hover { background-color: #1D78CB; }
      .${nClasses.form} button span { font-size: 17px; padding-left: 5px; }
      .${nClasses.form} [disabled] input { color: #999; cursor: wait; opacity: 0.9; }
      .${nClasses.success} h3 { color: #3d3d3d; font: 23px/1.3 "proximaNovaBold", sans-serif; text-align: center; }
      .${nClasses.success} p { color: #3d3d3d; font: 16px/1.63 "Merriweather", serif; margin: 0 15px 20px; text-align: center; }
      .${nClasses.success} button { background-color: #3d3d3d; color: #fff; font: 18px/1 "proximaNovaBold", sans-serif; height: 50px; letter-spacing: 2.88px; position: static; text-align: center; width: 100%; }
      .${nClasses.error} { color: red; font-weight: bold; margin-bottom: 8px; }
    `;
  },
  inlineWidget() {
    return `
      .${nClasses.heading} { font: 23px/1.13 "proximaNovaBold", sans-serif; }
      .${nClasses.inlineWrapper} { background-color: #fff; color: #3d3d3d; font: 16px/26px "Merriweather", serif; margin-bottom: 20px; max-width: 400px; }
      body.atk .${nClasses.inlineWrapper} { background-color: #f6f6f6;}
      body.cio .${nClasses.inlineWrapper} { background-color: #fcf9f3;}
      .${nClasses.success} { background-color: #f6f6f6; padding: 24px 16px 16px; }
      body.atk .${nClasses.success} { background-color: #f6f6f6;}
      body.cio .${nClasses.success} { background-color: #fcf9f3;}
    `;
  },
  popoverWidget() {
    return `
      body.body-fixed .${nClasses.popoverWrapper} { display: none; }
      .${nClasses.toggler} { cursor: pointer; }
      .${nClasses.popoverWrapper} { background-color: #fff; box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.16); color: #3d3d3d; display: block; position: fixed; bottom: 0; width: 100%; font: 16px/26px "Merriweather", serif; z-index: 50;}
      .${nClasses.popoverWrapper} header { font: 18px/1.44 "proximaNovaBold", sans-serif; padding: 18px 10px; position: relative; }
      .${nClasses.popoverWrapper} .${nClasses.content} { height: auto; max-height: 0; display: flex; flex-direction: column-reverse; overflow: hidden; transition: max-height 0.2s ease-out; padding: 0 16px; position: relative; }
      .${nClasses.content} .${nClasses.heading} { font: 23px/1.13 "proximaNovaBold", sans-serif; margin-bottom: 5px; max-width: 85%; }
      .${nClasses.content} .${nClasses.header} { display: none; }
      .${nClasses.toggler} .${nClasses.heading} { background-image: linear-gradient(transparent 91%, #3d95e4 91%); border: none; color: inherit; cursor: pointer; text-decoration: none; transition: background 0.2s ease-in-out; }
      .${nClasses.toggler} { position: absolute; }
      svg.${nClasses.toggler} { right: 10px; top: 25px; }
      .${nClasses.content} div.${nClasses.toggler} { position: absolute; padding: 10px; right: 10px; top: 0; }
      .${nClasses.header} .${nClasses.toggler} { top: 50%; transform: translateY(-50%); display: block; }
      .${nClasses.togglerOpen} { width: 25px; height: 25px; }
      .${nClasses.togglerClose} { display: none; }
      .${nClasses.togglerClose} svg { height: 10px; width: 17px; }
      .${nClasses.togglerDestroy} { display: none; height: 20px; width: 20px; }
      .${nClasses.open} header { padding: 0; }
      .${nClasses.open} .${nClasses.toggler} { transform: none; }
      .${nClasses.open} .${nClasses.togglerOpen} { display: none; }
      .${nClasses.open} .${nClasses.togglerClose} { display: block; }
      .${nClasses.form}.success ~ .${nClasses.header} { display: none; }
      .${nClasses.form}.success ~ .${nClasses.togglerClose} { display: none; }
      .${nClasses.form}.success ~ .${nClasses.togglerDestroy} { display: block; }
      .${nClasses.open} .${nClasses.content} { max-height: 360px; padding: 16px; }
      .${nClasses.open} .${nClasses.content} .${nClasses.header} { display: block; }
      .${nClasses.open} .${nClasses.toggler} .${nClasses.heading} { display: none; }
      .${nClasses.success} h3 { margin: 35px 0 8px; }
      .${nClasses.success} button { margin-bottom: 85px; }
    `;
  },
};

export default nStyles;
