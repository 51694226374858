import { breakpoints as miseBreakpoints } from 'legacy-mise-ui/styles';

import {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
  variables,
} from './defaults';

const themeBreakpoints = {
  ...breakpoints,
  ...miseBreakpoints,
};

const themeColors = {
  ...colors,
  akaroa: '#d1c4a1',
  bone: '#D9CCA7',
  bronze: '#402c18',
  cement: '#817154',
  coffee: '#766A55',
  cork: '#3f2b1e',
  desert: '#f1ece5',
  dijon: '#EED681',
  granite: '#8C7C60',
  gullGray: '#ACAEAF',
  ivoryWhite: '#F1ECE5',
  linen: '#FCF9F3',
  olive: '#627C33',
  pineCone: '#766A56',
  quincy: '#625640',
  sand: '#FFF5C2',
  spanishWhite: '#DDD2B2',
  squirrel: '#94856B',
  wheatfield: '#E1D8BF',
  vanilla: '#CBBD95',
};

const themeLineHieghts = {
  ...lineHeights,
  xxxsm: '0.5',
  xxxlg: '2.8',
};

const themeFontSizes = {
  ...fontSizes,
  xxxxlg: '3.7rem',
  xxxxxlg: '4.6rem',
};

const cio = {
  siteKey: 'cio',
  themeColor: '#3d3d3d',
  checkbox: {
    checkboxBg: themeColors.white,
    checkboxBorder: themeColors.bone,
    checkboxBgHover: themeColors.ivoryWhite,
    checkboxBorderHover: themeColors.bone,
    checkboxBgChecked: themeColors.squirrel,
    checkboxBorderChecked: themeColors.squirrel,
    checkboxBgHoverChecked: themeColors.squirrel,
    checkboxBorderHoverChecked: themeColors.squirrel,
  },
  colors: themeColors,
  fonts,
  fontSizes: themeFontSizes,
  lineHeights: themeLineHieghts,
  breakpoints: themeBreakpoints,
  contentMaxWidth: '1136px',
  letterSpacing,
  socialColors,
  variables: {
    ...variables,
    recipeDetailPaddingTop: '3.2rem',
    recipeDetailPaddingTopOnboarding: '6.4rem',
  },
};

export default cio;
