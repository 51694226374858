import nClasses from './classnames';
import nStyles from './styles';

const nTemplates = {
  form(params) {
    const { api = '/newsletter/signup', email, error, nCopy, status } = params;
    const disabledAttr = status === 'SUBMITTING' ? ' disabled' : '';
    const formHtml =
      status !== 'SUCCESS'
        ? `
      <form action="${api}" id="newsletter-widget-form-global" method="POST" novalidate>
        <input aria-label="Email Address" id="newsletter-widget-form__email" placeholder="${
          nCopy.form.placeholder
        }" type="email"${disabledAttr} value="${email}" />
        ${error ? `<div class="${nClasses.error}">${error}</div>` : ''}
        <button type="submit"${disabledAttr}>${
            nCopy.form.submit
          }<span>▸</span></button>
      </form>`
        : '';
    return `
      <section class="${nClasses.form} ${status.toLowerCase()}">
        ${formHtml}
        ${this.success(params)}
        ${this.privacy(params)}
      </section>
    `;
  },
  success({ status, context, nCopy }) {
    return status === 'SUCCESS'
      ? `
      <div class="${nClasses.success}">
        <h3>${nCopy.form.success.title}</h3>
        <p>${nCopy.form.success.text}</p>
        ${
          context === 'popover'
            ? `
          <button class="${nClasses.successButton} ${nClasses.toggler}">
            ${nCopy.form.success.button}
          </button>
        `
            : ''
        }
        </div>
    `
      : '';
  },
  privacy({ status, nCopy }) {
    return status !== 'SUCCESS' ? `<small>${nCopy.privacy}</small>` : '';
  },
  fixedContainer(params) {
    const { form, open, nCopy } = params;

    return `
      <style>${nStyles.popoverWidget()}${nStyles.formWidget()}</style>
      <div class="${nClasses.popoverWrapper}${open ? ` ${nClasses.open}` : ''}">
        <header class="${nClasses.header} ${nClasses.toggler}">
          <span class="${nClasses.heading}">${nCopy.header}</span>
          <svg class="${nClasses.toggler} ${
      nClasses.togglerOpen
    }" width="25" height="25">
            <g transform="translate(-293 -906)">
              <circle cx="12.5" cy="12.5" r="12.5" fill="#3d95e4" transform="translate(293 906)"/>
              <path fill="none" stroke="#fffdfd" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3" d="M299.5 920.5l6.4-4 6.4 4"/>
            </g>
          </svg>
        </header>
        <section class="${nClasses.content}">
          ${form}
          <section class="${nClasses.intro}">
            <p>${nCopy.intro}</p>
          </section>
          <header class="${nClasses.header}">
            <div class="${nClasses.heading}">${nCopy.header}</div>
          </header>
          <div class="${nClasses.toggler} ${nClasses.togglerClose}">
            <svg width="16.934" height="7.836" viewBox="0 0 16.934 7.836">
              <defs><style>.cls-close{fill:none;stroke:#3d3d3d;stroke-linecap:round;stroke-miterlimit:10;stroke-width:3px}</style></defs>
              <path d="M0 0l4 6.4-4 6.4" class="cls-close" transform="rotate(90 6.4 8.467)"/>
            </svg>
          </div>
          <div class="${nClasses.toggler} ${nClasses.togglerDestroy}">
            <svg width="19.243" height="19.243" viewBox="0 0 19.243 19.243">
              <defs><style>.cls-destroy{fill:none;stroke:#3d3d3d;stroke-linecap:round;stroke-width:3px}</style></defs>
              <g transform="translate(-340.379 -531.379)">
                <path d="M0 0l15 15" class="cls-destroy" transform="translate(342.5 533.5)"/>
                <path d="M15 0L0 15" class="cls-destroy" transform="translate(342.5 533.5)"/>
              </g>
            </svg>
          </div>
        </section>
      </div>
    `;
  },
  inlineContainer({ form, nCopy }) {
    return `
      <style>${nStyles.inlineWidget()}${nStyles.formWidget()}</style>
      <div class="${nClasses.inlineWrapper}">
        <header class="${nClasses.header} ${nClasses.toggler}">
          <span class="${nClasses.heading}">${nCopy.header}</span>
        </header>
        <section class="${nClasses.content}">
          <section class="${nClasses.intro}">
            <p>${nCopy.intro}</p>
          </section>
          <section class="${nClasses.form}">
            ${form}
          </section>
        </section>
      </div>
    `;
  },
};

export default nTemplates;
