import widgetUtils from './widgetUtils/utils';
import WidgetsAnalytics from './widgetUtils/analytics';
import {
  renderAutocompleteHelpText,
  renderHiddenStatusText,
} from './aria-utilities';

/**
 * Utility for renderHiddenStatusText calls.
 * @param {HTMLInputElement} input textbox element.
 * @param {number} count number of results.
 */
function renderResultsStatus(input, count) {
  const postfixText =
    'Four additional options below results for filter options.';
  const textContent =
    count !== 0 ? `${count} results are available. ${postfixText}` : '';
  renderHiddenStatusText(input, textContent);
}

/**
 * Add initialized aria attributes for comboxbox aria 1.2
 * https://www.w3.org/TR/wai-aria-1.2/#combobox
 *
 * @param {HTMLInputElement} inputElement  input element for autocomplete search.
 * @param {HTMLDivElement} listboxElement  autocomplete list.
 */
function initializeComboboxAria(inputElement, listboxElement) {
  inputElement.setAttribute('role', 'combobox');
  inputElement.setAttribute('aria-expanded', 'false');
  inputElement.setAttribute('aria-owns', listboxElement.id);
  inputElement.setAttribute('aria-haspopup', 'listbox');
  inputElement.setAttribute('aria-autocomplete', 'list');
  inputElement.setAttribute('aria-controls', listboxElement.id);
  inputElement.setAttribute('aria-activedescendant', '');

  listboxElement.setAttribute('role', 'listbox');

  renderAutocompleteHelpText(
    inputElement,
    'Auto suggestions will appear as you type, use up and down arrow then enter to select.',
  );
  renderResultsStatus(inputElement, 0);
}

/**
 * Update aria selection attributes for input and options.
 * @param {HTMLInputElement} inputElement input element for autocomplete search
 * @param {HTMLDivElement} listboxElement autocomplete list.
 * @param {HTMLAnchorElement | null} selectedElement  null if no selected element
 */
function updateComboboxAria(inputElement, listboxElement, selectedElement) {
  const previous = listboxElement.querySelector('[aria-selected="true"]');
  if (previous) previous.removeAttribute('aria-selected');

  if (selectedElement) selectedElement.setAttribute('aria-selected', 'true');

  inputElement.setAttribute(
    'aria-activedescendant',
    selectedElement ? selectedElement.id : '',
  );
}

/**
 * Attach focus out listeners, with requirement to keep autocomplete listbox open for
 *  the click event to happen.
 * @param {HTMLInputElement | null} inputElement input element for autocomplete search
 * @param {HTMLInputElement | null} listboxElement autocomplete list.
 * @param {() => void} closeCallback callback to request close
 */
function addEventFocusoutInput(inputElement, listboxElement, closeCallback) {
  if (!inputElement || !listboxElement) return;

  inputElement.addEventListener('focusout', () => {
    document.addEventListener(
      'focusin',
      () => {
        if (!listboxElement.contains(document.activeElement)) {
          closeCallback();
        }
      },
      { once: true },
    );
  });
}

function ATKAutocomplete(inputSelector) {
  this.input = null;
  this.form = null;
  this.headerHeight = 0;
  this.maxHits = 4;

  this.inputSelector = inputSelector;
  this.state = {
    focused: null,
    categories: [],
    inputPos: {
      x: 0,
      y: 0,
    },
    open: false,
    hits: [],
    nbHits: 0,
    query: null,
  };

  this.initializeInput();
  if (this.input) {
    this.debouncedSearch = widgetUtils.debounce(this.search.bind(this), 250);
    this.boundOnSearchComplete = this.onSearchComplete.bind(this);
    this.boundSetCategories = this.setCategories.bind(this);
    this.initializeResultsContainer();
    this.getHeaderHeight();
    this.analytics = new WidgetsAnalytics();
    if (typeof window.algoliasearch === 'undefined') {
      widgetUtils.loadScriptFile(
        'cdn.jsdelivr.net/algoliasearch/3/algoliasearchLite.min.js',
        () => typeof window.algoliasearch !== 'undefined',
        this.initializeAlgolia.bind(this),
      );
    } else {
      this.initializeAlgolia();
    }
  }
}

ATKAutocomplete.prototype = {
  get hits() {
    return this.state.hits;
  },

  set hits(data) {
    this.state.hits = data;
  },

  get categories() {
    return this.state.categories;
  },

  set categories(data) {
    this.state.categories = data;
  },

  get nbHits() {
    return this.state.nbHits;
  },

  set nbHits(data) {
    this.state.nbHits = data;
  },

  get inputPos() {
    return this.state.inputPos;
  },

  set inputPos(coords) {
    this.state.inputPos = coords;
  },

  get open() {
    return this.state.open;
  },

  set open(doOpen) {
    if (doOpen) {
      if (this.open) {
        this.update();
      } else {
        this.show();
      }
    } else if (this.open) {
      this.hide();
    }
    this.state.open = doOpen;
  },

  getKeyCode(evt) {
    return evt.keyCode ? evt.keyCode : evt.which;
  },

  get keyDownFunctions() {
    return {
      27: this.handleEscapeKey,
      38: this.focusPreviousResult,
      40: this.focusNextResult,
    };
  },

  get keyUpFunctions() {
    return {
      38: () => null,
      40: () => null,
    };
  },

  getKeyFunction(evt, keyEventType) {
    const fn = this[keyEventType][this.getKeyCode(evt)];
    return widgetUtils.isFunction(fn) ? fn : null;
  },

  baseImgUrl:
    'https://res.cloudinary.com/hksqkdlah/image/upload/ar_1:1,c_fill,dpr_auto,f_auto,fl_lossy,g_faces,h_55,q_auto',

  initializeAlgolia() {
    const APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '';
    const API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_TOKEN ?? '';
    this.client = algoliasearch(APP_ID, API_KEY);

    this.index = this.client.initIndex(
      'production_suggestions_query_suggestions',
    );
    this.categoryIndex = this.client.initIndex(
      `everest_search_${process.env.NEXT_PUBLIC_ALGOLIA_ENVIRONMENT}`,
    );
  },

  initializeResultsContainer() {
    this.el = document.createElement('div');
    this.el.id = 'listboxResults';
    this.el.classList.add('document-autocomplete');

    initializeComboboxAria(this.input, this.el);

    this.el.addEventListener('click', this.handleContainerClick.bind(this));
    document.addEventListener('click', this.handleClickClose.bind(this));
    window.addEventListener(
      'scroll',
      widgetUtils.debounce(() => {
        if (window.pageYOffset > this.headerHeight) this.open = false;
      }),
      50,
    );
    window.addEventListener(
      'resize',
      widgetUtils.debounce(this.handleResize.bind(this), 250),
    );
    this.el.addEventListener(
      'mouseover',
      widgetUtils.debounce(this.handleContainerHover.bind(this), 50),
    );
    this.input.parentNode.parentNode.appendChild(this.el);
    this.input.parentNode.parentNode.classList.add(
      `${this.getSiteKey()}-autocomplete-container`,
    );

    addEventFocusoutInput(this.input, this.el, this.hide.bind(this));
  },

  getHeaderHeight() {
    const toolbar = document.querySelector('#brand-nav');
    const section = document.querySelector('.main-nav');
    this.headerHeight = toolbar?.offsetHeight + section?.offsetHeight;
  },

  handleClickClose(evt) {
    // close unless hitting enter on focused result or clicking dropdown links
    const clickable = [
      'search-form-submit-button',
      'document-autocomplete__bolded',
      'document-autocomplete__category-label',
      'document-autocomplete__category-in',
      'document-autocomplete__title',
      'document-autocomplete__categories',
    ];
    if (
      clickable.indexOf(evt.target.className) === -1 &&
      clickable.indexOf(evt.target.id) === -1
    ) {
      this.open = false;
    }
  },

  handleResize() {
    this.el.style.width = `${this.input.parentElement.offsetWidth}px`;
  },

  initializeInput() {
    this.input = document.querySelector(this.inputSelector);
    if (this.input) {
      this.input.setAttribute('autocomplete', 'off');
      this.input.addEventListener('keydown', this.handleKeyDown.bind(this));
      this.input.addEventListener('keyup', this.handleKeyUp.bind(this));
      this.inputPos = widgetUtils.getPosition(this.input);

      this.form = this.input.closest('form');
      this.form.setAttribute('autocomplete', 'off');
      this.form.addEventListener('submit', this.handleFormSubmit.bind(this));
    }
  },

  blur() {
    this.focused = null;
  },

  getSiteKey() {
    const { host, pathname } = document.location;
    let siteKey = 'cio';
    if (
      pathname.includes('/podcast') ||
      pathname.includes('/show') ||
      pathname.includes('/skill') ||
      pathname.includes('/video') ||
      pathname.includes('/proof') ||
      pathname.includes('/episode') ||
      pathname.includes('/whats-eating-dan') ||
      pathname.includes('/perfectly-seasonal')
    ) {
      siteKey = 'play';
    } else if (host.indexOf('americastestkitchen') !== -1) {
      siteKey = 'atk';
    } else if (host.indexOf('cookscountry') !== -1) {
      siteKey = 'cco';
    }
    return siteKey;
  },

  isSelected(idx) {
    return this.focused === idx;
  },

  get focused() {
    return this.state.focused;
  },

  set focused(idx) {
    this.state.focused = idx;
  },

  get query() {
    return this.state.query;
  },

  set query(str) {
    this.state.query = str.trim();
  },

  focusNextResult(evt) {
    if (this.open) {
      evt.preventDefault();
      if (this.focused === null) {
        this.focused = 0;
      } else if (this.focused < this.hits.length + this.categories.length) {
        this.focused += 1;
      }
      this.update();
    } else if (this.query) {
      this.input.value = this.query;
      this.search(this.query);
    }
  },

  focusPreviousResult(evt) {
    if (this.open) {
      evt.preventDefault();
      if (this.focused > 0) {
        this.focused -= 1;
      } else {
        this.blur();
      }
      this.update();
    }
  },

  getFocusedResult() {
    return this.el.querySelector(`[data-idx="${this.focused}"]`);
  },

  handleContainerClick(evt) {
    const result = evt.target.closest('a');
    if (result) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      const idx = parseInt(result.dataset.idx, 10);
      this.mixpanelEvent(idx, () => {
        document.location.href = result.href;
      });
    }
  },

  mixpanelEvent(idx, callback) {
    const data = {
      name: 'Search Form Submitted',
      location: 'header autocomplete',
    };
    data.term = idx && this.hits[idx] ? this.hits[idx].query : this.query;
    this.analytics.trackEvent('SEARCH_FORM_SUBMITTED', data, callback);
  },

  handleContainerHover(evt) {
    if (evt.target.closest('a') && this.focused !== null) {
      const result = this.getFocusedResult();
      if (result) result.classList.remove('focused');
      this.blur();
    }
  },

  handleEscapeKey(evt) {
    evt.preventDefault();
    if (this.focused !== null) {
      this.blur();
      this.update();
    } else if (this.open) {
      this.open = false;
    }
    return false;
  },

  handleFormSubmit(evt) {
    const focusedResult = this.getFocusedResult();

    if (focusedResult) {
      evt.preventDefault();
      evt.stopImmediatePropagation();
      this.mixpanelEvent(this.focused, () => {
        document.location.href = focusedResult.querySelector('a').href;
      });
      return false;
    }
    return true;
  },

  handleKeyDown(evt) {
    let retval = true;
    const keyDownCallback = this.getKeyFunction(evt, 'keyDownFunctions');
    if (keyDownCallback) {
      retval = keyDownCallback.call(this, evt);
    }
    return retval;
  },

  handleKeyUp(evt) {
    const query = this.input.value;
    const keyUpCallback = this.getKeyFunction(evt, 'keyUpFunctions');
    const keyDownCallback = this.getKeyFunction(evt, 'keyDownFunctions');
    if (keyUpCallback) {
      keyUpCallback.call(this, evt);
    } else if (
      query.length >= 3 &&
      (!this.open || this.query !== query) &&
      !keyDownCallback
    ) {
      this.debouncedSearch(this.input.value);
    } else if (this.open) {
      this.open = false;
    }
  },

  hide() {
    this.el.classList.remove('open');
    this.input.setAttribute('aria-expanded', 'false');
    renderResultsStatus(this.input, 0);
    this.el.innerHTML = '';
    this.blur();
  },

  onSearchComplete(err, content) {
    if (err) {
      console.error(err); // eslint-disable-line no-console
    } else if (content && content.hits.length > 0) {
      this.hits = (content.hits && content.hits.slice(0, this.maxHits)) || [];
      this.nbHits = content.nbHits;
    } else {
      this.hits = [];
      this.nbHits = 0;
    }
  },

  setCategories(err, content) {
    if (err) {
      console.error(err); // eslint-disable-line no-console
    } else {
      if (content && content.hits.length > 0) {
        const facets = content.facets.search_document_klass;
        const searchableFacets = Object.entries(widgetUtils.searchableFacets);
        const searchableFacetsCompact = searchableFacets.filter(
          (a) => facets[a[0]] !== undefined,
        );
        this.categories = searchableFacetsCompact
          .sort((a, b) => facets[b[0]] - facets[a[0]])
          .slice(0, 4); // eslint-disable-line
      } else {
        this.categories = [];
      }
      this.open = Boolean(this.hits.length && this.categories.length);
    }
  },

  categoriesTemplate(query) {
    return `
      <div class="document-autocomplete__categories">
        <span class="document-autocomplete__highlighted">
          ${query}
        </span>
      </div>`;
  },

  resultTemplate(hit, idx, setSize, focused) {
    const highlightResult = hit._highlightResult.query.value; // eslint-disable-line
    const url = `/search?q=${hit.query}`;
    // temp ?: for deploy
    const href = dry.subfolderUrlQueryRefinement
      ? dry.subfolderUrlQueryRefinement(url)
      : url;

    return `
      <div
        data-idx="${idx}"
        id="result-${idx}"
        role="option"
        aria-posinset="${idx + 1}"
        aria-setsize="${setSize + 4}"
        aria-label="${hit.query}"
      >
        <a
          tabindex="-1"
          class="document-autocomplete__result${focused ? ' focused' : ''}"
          href="${href}"
        >
          <span class="document-autocomplete__result-content">
            <span class="document-autocomplete__title">
              ${highlightResult}
            </span>
          <span>
        </a>
      </div>
      `;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resultsTemplate(query, nbHits, hits, categories) {
    const setSize = hits.length;
    return `
      <div class="document-autocomplete__results">
        ${hits
          .map((hit, idx) =>
            this.resultTemplate(hit, idx, setSize, this.isSelected(idx)),
          )
          .join('')}
      </div>
       ${this.categoriesTemplate(query)}
    `;
  },

  searchOptions() {
    const options = {
      attributesToHighlight: ['query'],
      filters: 'NOT exclude_from_search:true',
      highlightPreTag: '<span class="document-autocomplete__bolded">',
      highlightPostTag: '</span>',
      hitsPerPage: this.maxHits,
      query: this.query,
    };
    return options;
  },

  search(query) {
    this.query = query;
    if (this.query) {
      this.index.search(this.searchOptions(), this.boundOnSearchComplete);
      this.categoryIndex.search(
        { query, facets: 'search_document_klass' },
        this.boundSetCategories,
      );
    }
  },

  show() {
    this.el.innerHTML = this.resultsTemplate(
      this.query,
      this.nbHits,
      this.hits,
      this.categories,
    );
    this.el.style.width = `${this.input.parentElement.offsetWidth}px`;
    this.el.classList.add('open');
    this.input.setAttribute('aria-expanded', 'true');
    renderResultsStatus(this.input, this.hits.length);
  },

  update() {
    this.el.innerHTML = this.resultsTemplate(
      this.query,
      this.nbHits,
      this.hits,
      this.categories,
    );
    const focusedResult =
      this.focused !== null ? this.getFocusedResult() : null;

    updateComboboxAria(this.input, this.el, focusedResult);
    renderResultsStatus(this.input, this.hits.length);

    if (this.focused !== null) {
      if (focusedResult) {
        const resultOffset = focusedResult.offsetTop;
        const elHeight = this.el.offsetHeight;
        const elScrollTop = this.el.scrollTop;
        if (resultOffset > elScrollTop + elHeight) {
          this.el.scrollTop =
            resultOffset - (elHeight - focusedResult.offsetHeight);
        } else if (resultOffset < elScrollTop) {
          this.el.scrollTop = resultOffset;
        }
      }
    }
  },
};

export default ATKAutocomplete;
