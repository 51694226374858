const constants = {
  ATK: "America's Test Kitchen",
  PAGE_SIZE: 40,
  STATUS_CODES: {
    COMPLETE: 'COMPLETE', // process has been completed
    DEFAULT: 'DEFAULT', // has never been changed
    DISABLED: 'DISABLED', // manually changed to disabled
    ERROR: 'ERROR', // bad things happen
    INITIALIZING: 'INITIALIZING', // building/loading resources
    LOADING: 'LOADING', // actively searching, performing user initiated action
    READY: 'READY', // it's all good in the hood,
  },
  TRACKING_VENDORS: {
    default: ['blueconic', 'google', 'mixpanel', 'bouncex', 'miso'],
    kids: ['mixpanel', 'bouncex'],
  },
};

export default constants;
