import widgetUtils from './widgetUtils/utils';

/**
 * Aria Elements: Screen reader only elements
 */

const SR_ONLY =
  'position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border: 0;';

/**
 * Makes configured div not attached to parent.
 * @returns {HTMLDivElement} the rendered sibling, use for modification of attribtues.
 */
function makeIdentifiableScreenReaderElement() {
  const describedbyElement = document.createElement('div');
  describedbyElement.style = SR_ONLY;
  describedbyElement.setAttribute('tabindex', '-1');
  describedbyElement.setAttribute('aria-hidden', 'false');
  return describedbyElement;
}

/**
 * @param {HTMLElement} element the element to add a screen reader only element to.
 * @param {string} postfix the id value to append to the target id hyphenated.
 * @returns {HTMLDivElement} the rendered sibling, use for modification of attribtues.
 */
function renderIdentifiableScreenReaderSibling(element, postfix) {
  const SiblingId = element.id ? `${element.id}-${postfix}` : postfix;
  let describedbyElement = document.getElementById(SiblingId);

  if (!describedbyElement) {
    describedbyElement = makeIdentifiableScreenReaderElement();
    describedbyElement.id = SiblingId;

    // alert reader on button elements inside of anchor tags read
    //  entire anchor tag content (i.e. all card content) when
    //  alert message is read.
    const closestAnchor = element.closest('a');
    // New addition to espresso: favorites alert wrapper
    // Going forward this element will be placed at the top of espresso pages.
    // Fixes a bug with reading alerts when favoriting FeatureCards.
    // FeatureCards are wrapped entirely by an anchor tag, which caused alert
    // updates to re-read the entire feature card's contents when users
    // toggled the favorites button while using a screen reader.
    const favoritesAlert = document.getElementById('favorite-alert-wrapper');
    let siblingElement = element;
    if (favoritesAlert) {
      favoritesAlert.appendChild(describedbyElement);
    } else {
      if (closestAnchor) siblingElement = closestAnchor;
      siblingElement.parentElement.appendChild(describedbyElement);
    }
  }

  return describedbyElement;
}

/**
 * Render hidden component that reads help text for searchboxes that have autocomplete.
 * @param {HTMLInputElement} inputElement  the element to append help text.
 * @param {string} textContent  the content for the help text description
 * @returns {void}
 */
function renderAutocompleteHelpText(inputElement, textContent) {
  const describedbyElement = renderIdentifiableScreenReaderSibling(
    inputElement,
    'describedby',
  );
  inputElement.setAttribute('aria-describedby', describedbyElement.id);
  describedbyElement.textContent = textContent;
}

/**
 * Render hidden component that reads alert text for actions.
 * @param {HTMLElement} element  the element to append alert text.
 * @param {string} textContent  the content for the help text description
 * @returns {void}
 */
function renderHiddenAlertText(element, textContent) {
  const alertElement = renderIdentifiableScreenReaderSibling(element, 'alert');
  alertElement.setAttribute('role', 'alert');
  alertElement.setAttribute('aria-live', 'polite');
  alertElement.textContent = textContent;
}

/** Estimated time for screen reader to announce textbox change. */
const SR_INPUT_DURATION = 1500;

/** Debounce element text content for after input annoucement. */
const debounceStatusText = widgetUtils.debounce((element, textContent) => {
  element.textContent = textContent;
}, SR_INPUT_DURATION);

/**
 * Render hidden status role component for status updates.
 * @param {HTMLElement} element  the element to append status text.
 * @param {string} textContent  the status content for the status text message.
 * @returns {void}
 */
function renderHiddenStatusText(element, textContent) {
  const statusElement = renderIdentifiableScreenReaderSibling(
    element,
    'status',
  );
  statusElement.setAttribute('role', 'status');
  statusElement.setAttribute('ari-live', 'polite');
  debounceStatusText(statusElement, textContent);
  if (!textContent) statusElement.textContent = '';
}

export {
  renderAutocompleteHelpText,
  renderHiddenAlertText,
  renderHiddenStatusText,
};

/** Aria State: button management */

/**
 * Update button element aria state for toggle buttons.
 * @param {HTMLButtonElement} buttonEl target element
 * @param {boolean} pressed aria-pressed value
 * @param {string | null} label aria-label value;
 *  falsy values remove label to read from element context.
 */
function renderToggleButtonState(buttonEl, pressed, label) {
  if (buttonEl.nodeName !== 'BUTTON' && buttonEl.nodeName !== 'A') return;

  buttonEl.setAttribute('aria-pressed', pressed);

  if (label) {
    buttonEl.setAttribute('aria-label', label);
  } else {
    buttonEl.removeAttribute('aria-label');
  }
}

export { renderToggleButtonState };
