import nClasses from './classnames';
import nCopy from './copy';
import nTemplates from './templates';
import NewsletterForm from './form';
import WidgetsAnalytics from '../FavoritesWidget/widgetUtils/analytics';
import widgetUtils from '../FavoritesWidget/widgetUtils/utils';

/**
 * When loaded onto a page (for mobile widths only), this widget will create a
 * small fixed-position widget at the bottom of the screen to collect emails.
 * Only renders for anonymous or registrant users.
 * Does not register if `newsletter_submitted` cookie is present
 * @param       {[type]} appEl [description]
 * @constructor
 */
function NewsletterInlineWidget(user, targetEl, newsletterType, options) {
  if (!targetEl) return;
  this.targetEl = targetEl;
  this.newsletterType = newsletterType;
  this.nCopy = nCopy[this.newsletterType];
  this.options = options || {};
  const newsletterParam =
    document.location.href.indexOf('newsletter=true') !== -1;

  let doRender = true;
  // do not render if user has submitted form previously
  if (widgetUtils.getCookie(`${newsletterType}_newsletter_submitted`)) {
    doRender = false;
  } else if (user && newsletterParam === false) {
    const {
      currentUserAccess: { segment },
    } = user;
    if (segment !== 'registrant' && segment !== 'anonymous') {
      doRender = false;
    }
  }

  if (doRender) {
    this.analytics = new WidgetsAnalytics();
    this.form = new NewsletterForm(
      this.targetEl,
      this.analytics,
      this.nCopy,
      this.newsletterType,
      this.options,
    );
    this.render();
    this.hideSwappedContent();
    window.onpopstate = () => this.destroy();
  }
}

NewsletterInlineWidget.prototype = {
  /**
   * Optionally, consumers can have DOM elements that
   * should be hidden when this component initializes.
   */
  hideSwappedContent() {
    const swaps = document.querySelectorAll(`.${nClasses.inlineSwap}`);
    [].slice.call(swaps).forEach((s) => {
      s.style.display = 'none';
    });
  },
  destroy() {
    this.targetEl.removeEventListener('click', this.handleClick);
  },
  render() {
    const form = this.form.render();
    const html = nTemplates.inlineContainer({
      form,
      nCopy: this.nCopy,
      context: 'inline',
    });
    this.targetEl.innerHTML = html;
  },
};

export default NewsletterInlineWidget;
