import { useMemo } from 'react';
import queryString from 'query-string';

import analytics from 'lib/analytics';
import useAppContext from 'lib/hooks/useAppContext';
import { DocumentNavProps } from 'components/CVMigratedNav/GlobalHeader/partials/DocumentNav/DocumentNav';
import { MainNavProps } from 'components/CVMigratedNav/GlobalHeader/partials/MainNav/MainNav';
import { HamburgerMenuProps } from 'components/CVMigratedNav/GlobalHeader/partials/HamburgerMenu/HamburgerMenu';
import { BrandNavProps } from 'components/CVMigratedNav/GlobalHeader/partials/BrandNav/BrandNav';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import useHeaderCodes from './useSiteNavCodes';
import userCtaData, { UserSegment } from './userCtas';

import { recipeMegaMenu, reviewsMegaMenu } from './megaMenuData';

import useUserState from 'lib/hooks/useUserState';

export type DeviceTypes = 'desktop' | 'tablet' | 'phone';

const toggleHamburgerEffect = (
  hamburgerButton?: React.RefObject<HTMLButtonElement>,
  hamburgerContent?: React.RefObject<HTMLDivElement>,
) => {
  const buttonEl = hamburgerButton?.current;
  const contentEl = hamburgerContent?.current;

  if (buttonEl && contentEl) {
    if (buttonEl.getAttribute('aria-expanded') === 'true') {
      disableBodyScroll(contentEl);
    } else {
      enableBodyScroll(contentEl);
    }
  }
};

export type BrandKey = 'atk' | 'cco' | 'cio' | 'play';

const onFocusSearch: React.FocusEventHandler<HTMLInputElement> = () => {
  analytics.track('SEARCH_FORM_FOCUS', {
    location: 'header',
  });
};

const onClickSiteNav = (el: HTMLElement) => {
  let type = el.textContent?.toLowerCase();
  if (type === 'reviews') {
    type = 'product reviews';
  } else if (type === 'watch now') {
    type = 'play';
  }

  const segments = document.location.pathname.split('/');
  let document_type = null;
  if (segments[1] === 'cookscountry' || segments[1] === 'cooksillustrated') {
    if (segments.length > 2) {
      document_type = segments[2];
    } else {
      document_type = 'homepage';
    }
  } else {
    document_type =
      segments.length > 1 && segments[1] ? segments[1] : 'homepage';
  }

  analytics.track('SITE_NAV_CLICK', {
    document_type,
    location: document.location.href,
    type,
  });
};

const onClickMegaMenuTrigger = (el: HTMLElement) => {
  const isOpen = el.getAttribute('aria-expanded') === 'true';
  const { megaMenuType: type } = el.dataset;
  if (isOpen) {
    analytics.track('MEGA_MENU_CLOSE', { type });
  } else {
    analytics.track('MEGA_MENU_OPEN', { type });
  }
};

const onClickMegaMenuSubCategory = (el: HTMLElement) => {
  const { category, subcategory, type } = el.dataset;
  analytics.track('MEGA_MENU_CLICK', {
    category_name: category,
    sub_category_name: subcategory,
    type,
  });
};

const onClickMegaMenuFacet = (el: HTMLElement) => {
  const { type, subcategory, facet } = el.dataset;
  analytics.track('MEGA_MENU_CLICK', {
    type,
    sub_category_name: subcategory,
    facet_value: facet,
  });
};

const onClickMegaMenuSupportingLink = (el: HTMLElement) => {
  const { type } = el.dataset;
  analytics.track('MEGA_MENU_CLICK', {
    type,
  });
};

const onClickMegaMenuPromotion = (el: HTMLElement) => {
  const { type, doctype } = el.dataset;
  analytics.track('MEGA_MENU_CLICK', {
    type,
    document_type: doctype,
  });
};

const onClickGlobalHeader = (event: React.MouseEvent, isPlay: boolean) => {
  const targetClicked = event.target;
  if (targetClicked instanceof HTMLElement) {
    const siteNavLinkClicked = targetClicked.closest('.site-nav-link');
    if (siteNavLinkClicked) {
      onClickSiteNav(siteNavLinkClicked as HTMLElement);
    }

    const megaMenuButtonClicked = targetClicked.closest('.mega-menu-button');
    if (megaMenuButtonClicked) {
      onClickMegaMenuTrigger(megaMenuButtonClicked as HTMLElement);
    }

    const megaMenuSubClicked = targetClicked.closest('.mega-menu-sub-category');
    if (megaMenuSubClicked) {
      onClickMegaMenuSubCategory(megaMenuSubClicked as HTMLElement);
    }

    const megaMenuFacetClicked = targetClicked.closest(
      '.mega-menu-category-facet',
    );
    if (megaMenuFacetClicked) {
      onClickMegaMenuFacet(megaMenuFacetClicked as HTMLElement);
    }

    const megaMenuSupportingLink = targetClicked.closest(
      '.mega-menu-supporting-item',
    );
    if (megaMenuSupportingLink) {
      onClickMegaMenuSupportingLink(megaMenuSupportingLink as HTMLElement);
    }

    const megaMenuPromotion = targetClicked.closest('.mega-menu-promotion');
    if (megaMenuPromotion) {
      onClickMegaMenuPromotion(megaMenuPromotion as HTMLElement);
    }

    if (isPlay) {
      const userCta = targetClicked.closest('.user-cta-play-override');
      if (userCta) {
        if (document.location.href.includes('cookscountry')) {
          event.preventDefault();
          window.location.href = `/cookscountry${userCta.getAttribute('href')}`;
        } else if (document.location.href.includes('cooksillustrated')) {
          event.preventDefault();
          window.location.href = `/cooksillustrated${userCta.getAttribute(
            'href',
          )}`;
        }
      }
    }
  }
};

const onSubmitSearch = (event: React.FormEvent, searchValue: string) => {
  event.preventDefault();

  analytics.track(
    'SEARCH_FORM_SUBMITTED',
    {
      location: 'header',
      name: 'Search Form Submitted',
      term: searchValue,
    },
    { transport: 'sendBeacon' },
  );

  window.location.href = queryString.stringifyUrl({
    url: '/search',
    query: { q: searchValue },
  });
};

const documentNavLinks = (
  brandKey: BrandKey,
  deviceType: DeviceTypes,
  pathname: string,
) =>
  ({
    atk: () => [
      {
        href: '/recipes',
        isActive: pathname.includes('/recipes'),
        label: 'Recipes',
        megaMenuConfig: deviceType === 'desktop' ? recipeMegaMenu : undefined,
      },
      {
        href: '/reviews',
        isActive:
          pathname.includes('/reviews') ||
          pathname.includes('/equipment_reviews') ||
          pathname.includes('/taste_tests'),
        label: 'Reviews',
        megaMenuConfig: deviceType === 'desktop' ? reviewsMegaMenu : undefined,
      },
      {
        href: '/articles',
        isActive: pathname.includes('/articles'),
        label: 'Articles',
      },
      {
        href: '/episodes',
        isActive: false,
        label: 'Watch Now',
        showPlayIcon: true,
      },
    ],
    cco: () => [
      {
        href: '/recipes',
        isActive: pathname.includes('/recipes'),
        label: 'Recipes',
      },
      {
        href: '/reviews',
        isActive:
          pathname.includes('/reviews') ||
          pathname.includes('/equipment_reviews') ||
          pathname.includes('/taste_tests'),
        label: 'Reviews',
      },
      {
        href: '/cookscountry/articles',
        isActive: pathname.includes('/articles'),
        label: 'Articles',
      },
      {
        href: '/cookscountry/episodes',
        isActive: false,
        label: 'Watch Now',
        showPlayIcon: true,
      },
      {
        href: '/cookscountry/magazines',
        isActive: false,
        label: 'Magazine',
      },
    ],
    cio: () => [
      {
        href: '/recipes',
        isActive: pathname.includes('/recipes'),
        label: 'Recipes',
      },
      {
        href: '/reviews',
        isActive:
          pathname.includes('/reviews') ||
          pathname.includes('/equipment_reviews') ||
          pathname.includes('/taste_tests'),
        label: 'Reviews',
      },
      {
        href: '/cooksillustrated/buying_guides',
        isActive:
          pathname.includes('buying_guides') ||
          pathname.includes('buying-guides'),
        label: 'Buying Guides',
      },
      {
        href: '/cooksillustrated/videos',
        isActive: false,
        label: 'Watch Now',
        showPlayIcon: true,
      },
      {
        href: '/cooksillustrated/magazines',
        isActive: false,
        label: 'Magazine',
      },
    ],
    play: () => [
      {
        href: '/shows',
        isActive:
          pathname.includes('/shows') ||
          pathname.includes('/episodes') ||
          pathname.includes('/videos'),
        label: 'Shows',
        subLinks: [
          {
            href: '/episodes',
            isActive:
              pathname.includes('/episodes') &&
              !pathname.includes('/cookscountry') &&
              !pathname.includes('/cooksillustrated'),
            label: "America's Test Kitchen",
          },
          {
            href: '/cookscountry/episodes',
            isActive: pathname.includes('/cookscountry'),
            label: "Cook's Country",
          },
          {
            href: '/cooksillustrated/videos',
            isActive: pathname.includes('/cooksillustrated'),
            label: "Cook's Illustrated",
          },
        ],
      },
      {
        href: '/podcasts',
        isActive: pathname.includes('/podcasts'),
        label: 'Podcasts',
      },
      {
        href: '/skills',
        isActive: pathname.includes('/skills'),
        label: 'Skills',
      },
    ],
  }[brandKey]());

const useGlobalHeaderConfig = (
  brandKey: BrandKey,
  pathname: string,
  asPath: string,
) => {
  const user = useUserState();
  let isLoggedIn = false;
  let userName = '';
  let logInOutLink = {
    label: 'Log In',
    href: `/sign_in?next=${asPath}`,
  };
  let segment: UserSegment = 'anonymous';
  const brandKeyOverride = brandKey === 'play' ? 'atk' : brandKey;
  const { deviceType } = useAppContext();

  if (user) {
    segment = user.segment as UserSegment;
    isLoggedIn = segment !== 'anonymous';
    userName = user?.firstName as string;
    if (deviceType === 'phone' || userName?.length < 1) userName = 'Account';
    if (isLoggedIn) logInOutLink = { label: 'Log Out', href: '/sign_out' };
  }

  const userNav = [
    {
      href: '/support',
      label: 'Support',
    },
    {
      href: '/user',
      label: 'Account',
    },
    logInOutLink,
  ];

  const userCtas = useMemo(() => {
    return {
      links: userCtaData[brandKeyOverride][segment],
    };
  }, [brandKeyOverride, segment]);

  let userCta = undefined;
  if (
    segment === 'anonymous' ||
    segment === 'registrant' ||
    segment === 'cancelled_member' ||
    segment === 'cancelled_other' ||
    segment === 'former'
  ) {
    userCta = userCtas?.links[0];
  }

  const documentLinks = useMemo(() => {
    return documentNavLinks(brandKey, deviceType, pathname);
  }, [brandKey, deviceType, pathname]);

  let magazineAd = undefined;
  if (brandKey === 'cco') {
    magazineAd = {
      altText: "Cover for the latest issue of the Cook's Country magazine.",
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=265426&cds_response_key=IKH17F1A0',
      imgSrc:
        'https://res.cloudinary.com/hksqkdlah/image/upload/w_148,h_178/CC_DJ_Cover_ulzcqg',
    };
  }

  if (brandKey === 'cio') {
    magazineAd = {
      altText: "Cover for the latest issue of the Cook's Illustrated magazine.",
      href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=265399&cds_response_key=ICT16E1B0',
      imgSrc:
        'https://res.cloudinary.com/hksqkdlah/image/upload/w_136,h_165/unnamed_h6a0k9',
    };
  }

  const headerLinks = useHeaderCodes(deviceType, brandKey);

  const hamburgerMenu: HamburgerMenuProps = {
    list: [logInOutLink, ...documentLinks],
    magazineAd,
    userCtas,
    toggleEffect: toggleHamburgerEffect,
    headerLinks,
  };

  const brandNav: BrandNavProps = {
    activeSiteNavItem: brandKeyOverride,
    activeUserNavItem: '',
    user: { isLoggedIn, name: userName },
    userCta,
    userNav,
    headerLinks,
    nextPath: asPath,
  };

  const documentNav: DocumentNavProps = {
    links: documentLinks,
  };

  const mainNav: MainNavProps = {
    brand: brandKey,
    hamburgerMenu,
    onFocusSearch,
    onSubmitSearch,
    userCtas,
  };

  return {
    brandNav,
    documentNav,
    mainNav,
    onClick: (e: React.MouseEvent) => {
      onClickGlobalHeader(e, brandKey === 'play');
    },
  };
};

export default useGlobalHeaderConfig;
