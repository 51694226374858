/* translate ATK event names to Mixpanel lingo */
const events = {
  BROWSE_RESULT_CLICKED: 'product_image_view',
  COLLECTION_ITEM_CLICKTHROUGH: 'product_image_view',
  DOCUMENT_VIEWED: 'product_detail_page_view',
  INLINE_VIDEO_PLAY: 'watch',
  PLAYLIST_CLICK: 'watch',
  REVIEW_VIDEO_CLICK: 'product_image_view',
  SEARCH_FORM_SUBMITTED: 'search',
  VIDEO_INLINE_PLAY: 'watch',
  RECOMMENDATION_ADDED: 'like',
  RECOMMENDATION_REJECTED: 'dislike',
  RECOMMENDATION_VIEWED: 'impression',
  FAVORITE_ADDED: 'add_to_collection',
  FAVORITE_DESTROYED: 'remove_from_collection',
  FAVORITE_REMOVED: 'remove_from_collection',
};

// adding an event
// 1) add the key/value pair above
// 2) add matching entry to analytics/track.js
// 3) call analytics.track(YOUR_KEY, yourData) from somewhere

export default events;
