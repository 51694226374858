import { UserTokenState } from 'components/integrations/Mixpanel/parseUserClient';
import UserSegmentTypes from 'enums/UserSegmentTypes';
import User from 'types/user';

class UserService {
  private static instance: UserService;

  private _user: User;

  constructor() {
    this._user = this.initializeUser();
  }

  public user(): User {
    return this._user;
  }

  public static getInstance(): UserService {
    if (!UserService.instance) {
      return new UserService();
    }
    return UserService.instance;
  }

  public anonUser(): User {
    const anonUser: User = {
      id: undefined,
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      role: '',
      segment: UserSegmentTypes.ANONYMOUS,
      active_memberships: [],
      active_registrations: [],
      cancelled_memberships: [],
      active_cds_membership: false,
      created_at: '',
      assignedGroup: null,
      package_name: '',
      auth_token: '',
    };

    return anonUser;
  }

  public async setUserFromAccessToken(accessToken: string): Promise<void> {
    const decodedJwt: JSON = this.parseToken(accessToken);
    const registeredUser = this.userFromJson(decodedJwt);

    this._user = registeredUser;
  }

  private parseToken(token: string): JSON {
    const [, base64Payload] = token.split('.');
    const payloadAsString = atob(base64Payload);
    return JSON.parse(payloadAsString);
  }

  private userFromJson(userAs: JSON): User {
    let u = userAs;

    if (typeof u === 'string') {
      u = this.parseToken(u);
    }

    const userAsJson = u as unknown as UserTokenState;

    const email = userAsJson?.email;
    const createdAt = undefined;
    const user: User = {
      id: userAsJson?.id,
      firstName: userAsJson?.first_name,
      lastName: userAsJson?.last_name,
      fullName: `${userAsJson?.first_name} ${userAsJson?.last_name}`,
      email,
      role: userAsJson?.role,
      created_at: createdAt,
      segment: userAsJson?.segment,
      active_memberships: userAsJson?.active_memberships,
      active_registrations: userAsJson?.active_registrations,
      active_cds_membership: userAsJson?.active_cds_membership,
      cancelled_memberships: userAsJson?.cancelled_memberships,
      package_name: userAsJson?.package_name,
      auth_token: userAsJson?.auth_token,
    };

    return user;
  }

  private initializeUser(): User {
    return {
      id: 0,
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      role: '',
      segment: UserSegmentTypes.LOADING,
      active_memberships: [],
      active_registrations: [],
      active_cds_membership: false,
      cancelled_memberships: [],
      created_at: '',
      assignedGroup: null,
      package_name: '',
      auth_token: '',
    };
  }
}

export default UserService.getInstance();
