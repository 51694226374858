import { useEffect, useState } from 'react';
import UserService from 'services/user.service';
import { getCookie } from 'cookies-next';

/**
 * @typedef {'anonymous' | 'registrant' | 'singleSite' | 'former' | 'multisite'} UserSegment
 * @typedef {{
 *  activeMemberships: string[];
 *  activeRegistrations: string[];
 *  cancelledMemberships: string[];
 *  dfpMembershipString: string | null;
 *  email: string | null;
 *  firstName: string | null;
 *  id: number | null;
 *  name: string | null;
 *  packageName: null
 *  role: string | null;
 *  segment: string | null;
 *  adSegment: UserSegment | null;
 *  activeCDSMembership: boolean;
 * }} UserState
 */

/**
 * @returns {UserState | undefined} undefined while async loading user
 */
export default function useUserState() {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const accessToken = getCookie('user_token');
        if (!accessToken || accessToken === 'undefined') {
          const anonymousUserState = UserService.anonUser();
          setUser(anonymousUserState);
        } else {
          UserService.setUserFromAccessToken(`${accessToken}`);
          const v6User = UserService.user();

          setUser(v6User);
        }
      } catch (e) {
        setUser(anonymousUserState);
      }
    };

    fetchUser();
  }, []);

  return user;
}
