import type { BrowserOptions, NodeOptions, EdgeOptions } from '@sentry/nextjs';

type Options = BrowserOptions | NodeOptions | EdgeOptions;

export const sentrySharedOptions: Options = {
  dsn: 'https://d56c2292fee1b23a40a1946aa73c2564@o4505306997587968.ingest.us.sentry.io/4508218677526528',
  debug: false,
  environment: process.env.ENVIRONMENT_NAME,
  enabled: ['staging-1', 'production'].some(
    (env) => env === process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  ),
};
