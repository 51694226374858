import api from '../lib/api';

export const REGISTRATION_SUBMISSION_PENDING =
  'REGISTRATION_SUBMISSION_PENDING';
export const REGISTRATION_SUBMISSION_FULFILLED =
  'REGISTRATION_SUBMISSION_FULFILLED';
export const REGISTRATION_SUBMISSION_REJECTED =
  'REGISTRATION_SUBMISSION_REJECTED';

export const submitRegistration = ({ data, siteKey }) => ({
  type: 'REGISTRATION_SUBMISSION',
  payload: {
    data,
    promise: api.registration(data, siteKey),
  },
});
