import useAppContext from 'lib/hooks/useAppContext';
import { parseUserToken, ReducedUserTokenState } from 'lib/utils/parseUser';
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';

export function useUserHeader() {
  const { serversideUserToken } = useAppContext();
  const [user, setUser] = useState<ReducedUserTokenState>(
    parseUserToken(serversideUserToken),
  );

  /**
   * Instead of waiting for dry to load and possibly failing to get the subscription call working, set a retry interval
   * (necessary because some auth strategies set user_token as http only and requires the dry revalidation call to send user_token)
   */
  useEffect(() => {
    let tries = 1;

    const interval = setInterval(() => {
      const cookies = new Cookies();
      const token = cookies.get('user_token');

      if (token) {
        const userClient = parseUserToken(token);
        setUser(userClient);
        window.clearInterval(interval);
      } else {
        tries++;

        if (tries > 5) {
          window.clearInterval(interval);
        }
      }
    }, 2_000);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  return user;
}
