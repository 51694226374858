import manager from './manager';

/**
 * @param {string} eventName
 * @param {?Record<string,any>} eventOptions
 * @param  {...any} restArgs
 * @returns {void}
 */

const track = (
  eventName: string,
  eventOptions?: Record<string, any>,
  ...restArgs: any
): void => {
  const managerTrackEvent = () => {
    return manager.trackEvent(
      eventName,
      {
        ...eventOptions,
        project: 'espresso',
        pathname: window.location.pathname,
      },
      ...restArgs,
    );
  };

  // tracking should only ever happen within browser context
  if (typeof window === 'undefined') {
    return;
  }
  if (!window?.mixpanel?.__loaded) {
    if (typeof restArgs[0] === 'function') {
      const callback = restArgs[0];
      callback();
      managerTrackEvent();
    } else {
      managerTrackEvent();
    }
    return;
  } else {
    managerTrackEvent();
  }
};

export default track;
