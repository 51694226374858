const index = {
  /**
   * Align element horizontally centered compared to the offset parent
   * @param  {String} [position='absolute'] Absolute or relative position
   * @return {String}                       CSS Text
   */
  alignHorizontalMiddle(position = 'absolute') {
    return `
      left: 50%;
      position: ${position};
      transform: translateX(-50%);
    `;
  },
  /**
   * Align element vertically centered compared to the offset parent
   * @param  {String} [position='absolute'] Absolute or relative position
   * @return {String}                       CSS Text
   */
  alignVerticalMiddle(position = 'absolute') {
    return `
      position: ${position};
      top: 50%;
      transform: translateY(-50%);
    `;
  },
  /**
   * Align element at the top compared to the offset parent
   * @param  {String} [position='absolute'] Absolute or relative position
   * @return {String}                       CSS Text
   */
  alignVerticalTop(position = 'absolute') {
    return `
      top: 0;
      position: ${position};
      transform: translateY(0);
    `;
  },
  /**
   * Align element vertically and horizontally centered compared to offset parent
   * @param  {String} [position='absolute'] Absolute or relative position
   * @return {String}                       CSS Text
   */
  alignXYMiddle(position = 'absolute') {
    return `
      left: 50%;
      position: ${position};
      top: 50%;
      transform: translate(-50%, -50%);
    `;
  },
  /**
   * Constrains the width/height of an element to a specified aspect ratio
   * @param  {String} [ratio='16:9'] Width:Height aspect ratio
   * @return {String}                CSS Text
   */
  aspectRatio(ratio = '16:9') {
    const [width, height] = ratio.split(':');
    return `
      display: block;
      height: 0;
      overflow: hidden;
      padding: 0;
      padding-bottom: ${(height / width) * 100}%;
      position: relative;
    `;
  },
  checkbox({
    checkboxBg = 'white',
    checkboxBorder = 'silver',

    checkboxBgHover = 'gainsboro',
    checkboxBorderHover = 'silver',

    checkboxBgChecked = 'gray',
    checkboxBorderChecked = 'gray',
    checkboxBgHoverChecked = 'gray',
    checkboxBorderHoverChecked = 'gray',

    checkboxBgDisabled = 'dimgray',
    checkboxBorderDisabled = 'darkslategrey',
  }) {
    return `
      /* Hover and focus states */
      .control--checkbox:hover input ~ .control__indicator {
        background: ${checkboxBgHover};
        border: 1px solid ${checkboxBorderHover};
      }

      .control--checkbox {
        cursor: pointer;
        display: block;
        margin-bottom: 10px;
        min-height: 20px;
        padding-right: 3px;
        position: relative;
        padding-left: 30px;

        input {
          opacity: 0;
          position: absolute;
          z-index: -1;
        }

        /* Disabled tick colour */
        .input:disabled ~ .control__indicator::after {
          border-color: ${checkboxBorderDisabled};
        }

        /* Disabled tick colour */
        .input:disabled ~ span {
          color: ${checkboxBorderDisabled};
        }

        /* Checked state */
        input:checked ~ .control__indicator {
          background: ${checkboxBgChecked};
          border: 2px solid ${checkboxBorderChecked};
        }

        /* Hover state whilst checked */
        &:hover input:not([disabled]):checked ~ .control__indicator {
          background: ${checkboxBgHoverChecked};
          border-color: ${checkboxBorderHoverChecked};
        }

        input:focus ~ .control__indicator {
          border: 2px solid -webkit-focus-ring-color;
          border: 2px solid #5E9ED6;
          outline: 5px auto -webkit-focus-ring-color;

          body.atk & {
            border: 2px solid #3d3d3d;
            outline: 5px auto #3d3d3d;
          }

          body.cco & {
            border: 2px solid #000000;
            outline: 5px auto #000000;
          }

          body.cio & {
            border: 2px solid #3f2b1e;
            outline: 5px auto #3f2b1e;
          }
        }

        /* Disabled state */
        input:disabled ~ .control__indicator {
          pointer-events: none;
          opacity: 0.6;
          background: ${checkboxBgDisabled};
        }

        /* Show check mark */
        input:checked ~ .control__indicator::after {
          display: block;
        }
      }

      .control__indicator {
        background: ${checkboxBg};
        border: 1px solid ${checkboxBorder};
        height: 20px;
        left: 0;
        position: absolute;
        top: 3px;
        width: 20px;

        /* Checkbox tick */
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
          content: '';
          display: none;
          height: 8px;
          left: 7px;
          position: absolute;
          top: 4px;
          transform: rotate(45deg);
          width: 4px;
        }
      }

      .control-label {
        color: black;
        display: inline-block;
        vertical-align: middle;
      }
    `;
  },
  /**
   * Makes a circle out of an element
   * @param  {String} [color='#000'] Color value - hex/rgb/rgba
   * @param  {String} [size='10rem'] Width and Height of the circle
   * @return {String}                CSS Text
   */
  circle(color = '#000', size = '10rem') {
    return `
      background-color: ${color};
      border-radius: 50%;
      height: ${size};
      width: ${size};
    `;
  },
  /**
   * Sets element width within row-based flex-direction container
   * based on a specified number of children
   * @param  {Integer} columns                Number of child elements within parent container
   * @param  {String} [gutter='1rem']         Width of gutter between each element
   * @param  {String} [propName='flex-basis'] CSS property to set
   */
  gridWidth(columns, gutter = '1rem', propName = 'flex-basis') {
    return `
      ${propName}: calc((100% / ${columns}) - ((${gutter} * ${
      columns - 1
    }) / ${columns}));
    `;
  },
  /**
   * Wrapper around `gridWidth`, which can be used when
   * the number of children is not known ahead of time;
   * i.e. When rendering depends on props.children and some components may return `null`
   * @param  {Integer} columns                Number of child elements within parent container
   * @param  {String} [gutter='1rem']         Width of gutter between each element
   * @param  {String} [propName='flex-basis'] CSS property to set
   */
  gridWidthAuto(columns, gutter = '1rem', propName = 'flex-basis') {
    return `
      > *:nth-last-child(${columns}):first-child,
      > *:nth-last-child(${columns}):first-child ~ * {
        ${this.gridWidth(columns, gutter, propName)}
      }
    `;
  },
  /**
   * Make an element disappear - magic!
   * @return {String}       CSS Text
   */
  hideAccessibly() {
    return `
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    `;
  },
  /**
   * Divider line
   * @param  {String} [color='#cecece']       Color value - hex/rgb/rgba
   * @param  {String} [verticalMargin='3rem'] Top and bottom margin
   * @param  {String} [style='solid']         Border style
   * @param  {String} [height='1px']          Thickness of the line
   * @return {String}                         CSS Text
   */
  horizontalRule(
    color = '#cecece',
    verticalMargin = '3rem',
    style = 'solid',
    height = '1px',
  ) {
    return `
      border: 0;
      border-top: ${height} ${style} ${color};
      height: 1px;
      margin: ${verticalMargin} 0;
    `;
  },
  /**
   * This helper is to address 'notch'-ish concerns on the iphoneX,
   * When an interactive element is too close to the bottom of the screen,
   * the browser doesn't register the first touch event. Instead, the browser
   * resizes the window and shows the browser back/forward toolbar.
   * The most reliable way to accommodate this behavior is to add styles, targeting
   * these devices, to have more padding/margin at the bottom of the screen.
   * @param  {[type]} content                  CSS styles to be applied
   * @param  {String} [orientation='portrait'] Notch is typically a portrait only issue
   */
  iphoneX(content, orientation = 'portrait') {
    let orientationRule = '';
    if (['landscape', 'portrait'].includes(orientation)) {
      orientationRule = ` and (orientation : ${orientation})`;
    }
    return `
      @media only screen
      and (min-device-width: 375px)
      and (min-device-height: 812px) ${orientationRule}
      and (-webkit-device-pixel-ratio: 3) {
        ${content}
      }
    `;
  },
  /**
   * Same as iphoneX (above) except for targeting iPhone 11
   * @param  {[type]} content                  CSS styles to be applied
   * @param  {String} [orientation='portrait'] Notch is typically a portrait only issue
   */
  iphone11(content, orientation = 'portrait') {
    let orientationRule = '';
    if (['landscape', 'portrait'].includes(orientation)) {
      orientationRule = ` and (orientation : ${orientation})`;
    }
    return `
      @media only screen
      and (min-device-width: 375px)
      and (min-device-height: 812px) ${orientationRule}
      and (-webkit-device-pixel-ratio: 2) {
        ${content}
      }
    `;
  },
  /**
   * :-( - sadder face
   */
  msEdge(content, breakpoint = null) {
    let bp = '';
    switch (breakpoint) {
      case 'tablet':
        bp = ' and (min-width: 768px)';
        break;
      case 'desktop':
        bp = ', and (min-width: 992px)';
        break;
    }
    return `
    @supports (-ms-ime-align: auto)${bp} {
      ${content}
    }`;
  },
  /**
   * Cross browser linear gradient
   * @param  {String} startColor     Color value - hex/rgb/rgba
   * @param  {String} endColor       Color value - hex/rgb/rgba
   * @param  {String} [angle='0deg'] Angle of the gradient
   * @return {String}                CSS Text
   */
  linearGradient(startColor, endColor, angle = '0deg') {
    return `
      background: ${startColor}; /* Old browsers */
      background: linear-gradient(${angle}, ${startColor} 0%, ${endColor} 100%); /* W3C */
    `;
  },
  loadingIndicator: `
    svg.loading-indicator {
      height: 4rem;
      width: 4rem;
    }
  `,
  relatedDocumentCard: `
    .document-browse-card {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &__image-wrapper {
        height: 5rem;
        width: 5rem;
      }

      &__content {
        flex-basis: calc(100% - 6.1rem);
      }

      &.no-image {
        .document-browse-card__content {
          flex-basis: 100%;
        }
      }
    }
  `,
  /**
   * Creates screen reader accessible strikethrough text on a <del> element
   * use with del::before, del::after
   * @returns {String}
   */
  strikethroughText() {
    return `
      clip-path: inset(100%);
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
      &:before {
      content: " [deletion start] ";
      }
      &:after {
      content: " [deletion end] "
      }
    `;
  },

  styledLink(underlineColor, backgroundColor) {
    return `
      background-image: linear-gradient(transparent 91%, ${underlineColor} 91%);
      border: none;
      color: inherit;
      cursor: pointer;
      text-decoration: none;
      transition: background .2s ease-in-out;

      &:focus,
      &:active {
        color: inherit;
      }

      &:hover {
        background-color: ${backgroundColor};
      }

      @media print {
        background-image: none !important;
        background-color: transparent !important;
        color-adjust: exact !important;
      }
    `;
  },
  /**
   * Creates a triangle - must be used with ::before or ::after
   * https://css-tricks.com/snippets/sass/css-triangle-mixin/
   * @param  {String} [direction='bottom'] Direction arrow will point
   * @param  {String} [color='#3D3D3D']    Color of Arrow
   * @param  {Number} [size=0.5]           Size in REM Units
   * @return {String}                      CSS Text
   */
  triangle(direction = 'bottom', color = '#3D3D3D', size = 0.5) {
    const perpendicularBorders = `${size}rem solid transparent`;
    let borders = '';
    if (direction === 'top' || direction === 'bottom') {
      borders = `
        border-left: ${perpendicularBorders};
        border-right:  ${perpendicularBorders};
      `;
    } else {
      borders = `
        border-bottom: ${perpendicularBorders};
        border-top:  ${perpendicularBorders};
      `;
    }
    const directionMap = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
      center: 'center',
      ltr: 'rtl',
      rtl: 'ltr',
    };
    return `
      content: '';
      height: 0;
      width: 0;
      z-index: 2;
      border-${directionMap[direction]}: ${size * 1.5}rem solid ${color};
      ${borders}
    `;
  },
  /**
   * Truncates text with ellipsis
   * @param  {Number} width optional - set the width of the element
   * @return {String}       CSS Text
   */
  truncate(width) {
    return `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      ${width ? `width: ${width}` : ''}
    `;
  },

  wavyTopBorder(color = '#EFF2F5') {
    return `
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 3rem;
        background-image:
          radial-gradient(circle at 3rem 7.5rem, rgba(255, 255, 255, 0) 6rem, ${color} 6.1rem);
        background-repeat: repeat;
        background-size: 6rem 12rem;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 4.5rem;
        background-image:
          radial-gradient(circle at 3rem -3rem, ${color} 6rem, rgba(255, 255, 255, 0) 6.1rem);
        background-repeat: repeat;
        background-size: 12rem 12rem;
      }
    `;
  },

  zigZagTopBorder(color = '#DFE5EA') {
    return `
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -30px;
        width: 100%;
        height: 30px;
        background-image:
          linear-gradient(
            45deg,
            rgba(255, 255, 255, 0) 33.333%,
            ${color} 33.333%,
            ${color} 66.667%,
            rgba(255, 255, 255, 0) 66.667%
          ),
          linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0) 33.333%,
            ${color} 33.333%,
            ${color} 66.667%,
            rgba(255, 255, 255, 0) 66.667%
          );
        background-size: 60px 120px;
      }
    `;
  },

  zigZagBottomBorder(color = '#DFE5EA') {
    return `
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -30px;
        width: 100%;
        height: 30px;
        background-image:
          linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0) 33.333%,
            ${color} 33.333%,
            ${color} 66.667%,
            rgba(255, 255, 255, 0) 66.667%
          ),
          linear-gradient(
            45deg,
            rgba(255, 255, 255, 0) 33.333%,
            ${color} 33.333%,
            ${color} 66.667%,
            rgba(255, 255, 255, 0) 66.667%
          );
        background-size: 60px 120px;
        transform: rotate(180deg);
      }
    `;
  },

  gradientSwapOnHover(color1 = '#00cfa6', color2 = '#00a26d') {
    return `
      position: relative;
      padding: 0.3rem 1.7rem 0.8rem 2rem;
      overflow: hidden;
      background-image: linear-gradient(140deg, ${color1}, ${color2});
      width: 100%;
      z-index: 1;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(140deg, ${color2}, ${color1});
        content: '';
        opacity: 0;
        z-index: -1;
        transition: .15s opacity ease-in;
      }

      &:hover {
        &::before { opacity: 1; }
      }
    `;
  },

  purpleWaveBackground(top = '10rem', paddingBottom = '9rem') {
    return `
      padding-bottom: ${paddingBottom};
      position: relative;

      &::after {
        position: absolute;
        top: ${top};
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image:
          url('https://res.cloudinary.com/hksqkdlah/image/upload/v1559157375/ATK%20Kids/Landing%20Page/PurpleWaves.svg');
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
        mix-blend-mode: multiply;
      }
    `;
  },
  syncFlatList(theme) {
    let applyCssForTheme;
    switch (theme) {
      case 'default':
        applyCssForTheme = `
        .flat-list {
          &__header {
            h3 {
              .detail-page-aside & {
                color: #94856B;
                font-size: 1.6rem;
              }
            }
          }

          &__item:not(:last-of-type) {
            margin-bottom: 1.6rem;
          }
        }

        .document-browse-card {
          &__title {
            transition: color 0.2s linear;

            &:hover {
              color: #94856B;
            }
          }

          &__image-wrapper {
            margin-bottom: 0;
          }

          &.no-image {
            min-height: 5rem;
          }
        }
        `;
        break;
      case 'cio':
        applyCssForTheme = `
        background-color: #FFFFFF;
        border: 1px solid #D9CCA7;

        .flat-list {
          &__header {
            margin: 0 0 1.2rem;

            h3 {
              color: #3f2b1e;
              font-family: proximaNovaBold, sans-serif;
              font-size: 1.4rem;
              letter-spacing: 1.6px;
              line-height: 1.22;

              .detail-page-aside & {
                color: #94856B;
                font-size: 1.6rem;
              }
            }
          }
        }

        .document-browse-card {
          &__title {
            color: #3F2B1E;
            font-family: proximaNovaRegular, sans-serif;
            font-size: 1.6rem;
            line-height: 1.8rem;

            &:hover {
              color: ;
            }
          }

          &__image-wrapper {
            margin-bottom: 0;
          }

          &__cta {
            padding: 0;
          }

          &.no-image {
            .document-browse-card__title {
              font-size: 1.6rem;
            }
          }
        }
      `;
        break;
      case 'atk':
        applyCssForTheme = `
        background-color: #FFFFFF;

        .flat-list {
          &__header {
            margin: 0 0 1.2rem;
          }
        }

        .document-browse-card {
          &.vertical {
            border: none;
            padding: 0;
          }

          &__image-wrapper {
            margin-bottom: 0;
          }

          &.no-image {
            .document-browse-card {
              &__content {
                margin: 0;
              }

              &__title {
                font-size: 1.6rem;
              }
            }
          }
        }

        .relateds-shop & {
          .flat-list {
            &__header {
              margin-bottom: 1.2rem;
            }

            &__header h3 {
              margin: 0;
              padding: 0;
            }
          }
        }
      `;
        break;
      case 'cco':
        applyCssForTheme = `
          border: 8px solid #F5F5F5;
          .flat-list {
            &__header h3 {
              color: #000000;
              font-family: proximaNovaRegular, sans-serif;
              font-size: 1.2rem;
              letter-spacing: 6px;
              line-height: 1.22;
              padding-bottom: 0;
            }

            &__item {
              .document-browse-card {
                border: none;
                padding: 0;

                &__title {
                  color: #000000;
                  font-family: Merriweather, serif;
                  font-size: 1.4rem;
                  line-height: 1.22;

                  &:hover {
                    color: #416490;
                  }
                }
              }
            }
          }
        `;
        break;
    }
    return applyCssForTheme;
  },
  focusIndicator(outlineColor = '#3d3d3d', outlineOffset = '3px') {
    return `
      outline: 2px dotted ${outlineColor};
      outline-offset: ${outlineOffset};
    `;
  },
};

export default index;
