import {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
  variables,
} from './defaults';

const themeColors = {
  ...colors,
};

const themeLineHieghts = {
  ...lineHeights,
};

const themeFontSizes = {
  ...fontSizes,
};

const newsletter = {
  siteKey: 'newsletter',
  themeColor: '#3d3d3d',
  checkbox: {
    checkboxBg: themeColors.white,
    checkboxBorder: themeColors.bone,
    checkboxBgHover: themeColors.ivoryWhite,
    checkboxBorderHover: themeColors.bone,
    checkboxBgChecked: themeColors.squirrel,
    checkboxBorderChecked: themeColors.squirrel,
    checkboxBgHoverChecked: themeColors.squirrel,
    checkboxBorderHoverChecked: themeColors.squirrel,
  },
  colors: themeColors,
  fonts,
  fontSizes: themeFontSizes,
  lineHeights: themeLineHieghts,
  breakpoints,
  contentMaxWidth: '1136px',
  letterSpacing,
  socialColors,
  variables: {
    ...variables,
  },
};

export default newsletter;
