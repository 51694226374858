import {
  breakpoints as miseBreakpoints,
  color as miseColors,
  spacing as miseSpacing,
} from 'legacy-mise-ui/styles';

import {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
  variables,
} from './defaults';

const themeBreakpoints = {
  ...breakpoints,
  ...miseBreakpoints,
};

const themeColors = {
  ...colors,
  ...miseColors,
  athensGray: '#f9f9fb',
  burgundy: '#5C303E',
  caramel: '#6E562A',
  cod: '#151515',
  cuttySark: '#57797b',
  darkerMint: '#5A8E91', // 90, 142, 145
  deepPlumb: '#3E285C',
  doveGray: '#6e6e6e',
  mint: '#6BA6AA', // 107, 166, 170
  turquoise: '#8FDEE3', // 143, 222, 227
  orange: 'orange',
  seaSalt: '#D1FCFF', // 209, 252, 255
};

const themeFonts = {
  ...fonts,
  cwf: 'chippewa-falls',
};

const themes = {
  siteKey: 'atk',
  themeColor: '#d73a15',
  checkbox: {
    checkboxBg: themeColors.white,
    checkboxBorder: themeColors.silver,
    checkboxBgHover: themeColors.silver,
    checkboxBorderHover: themeColors.silver,
    checkboxBgChecked: themeColors.mint,
    checkboxBorderChecked: themeColors.mint,
    checkboxBgHoverChecked: themeColors.mint,
    checkboxBorderHoverChecked: themeColors.mint,
  },
  mode: 'light',
  colors: themeColors,
  breakpoints: themeBreakpoints,
  contentMaxWidth: '1200px',
  fonts: themeFonts,
  fontSizes,
  letterSpacing,
  lineHeights,
  socialColors,
  spacing: miseSpacing,
  variables: {
    ...variables,
    recipeDetailPaddingTop: '3.2rem',
    recipeDetailPaddingTopOnboarding: '6.4rem',
  },
};

export default themes;
