import api from '../lib/api';

export const GET_LATEST_DOCUMENTS_PENDING = 'GET_LATEST_DOCUMENTS_PENDING';
export const GET_LATEST_DOCUMENTS_FULFILLED = 'GET_LATEST_DOCUMENTS_FULFILLED';
export const GET_LATEST_DOCUMENTS_REJECTED = 'GET_LATEST_DOCUMENTS_REJECTED';

export const getLatest = (contentId, pageSize) => ({
  type: 'GET_LATEST_DOCUMENTS',
  payload: {
    promise: api.latest(contentId, pageSize),
  },
});
