import { TOGGLE_COOKING_MODE } from '../actions/cookingMode';

export const initialState = {
  currentId: 0,
  isOpen: false,
};

const cookingMode = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case TOGGLE_COOKING_MODE:
      const isOpen = !state.isOpen;
      return {
        isOpen,
        currentId: payload.currentId,
      };

    default:
      return state;
  }
};

export default cookingMode;
