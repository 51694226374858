import { css } from 'styled-components';

import favoritesWidget from './favoritesWidget';
import mixins from './mixins';
import reset from './reset';
import themes from './themes';
import { globalFonts } from './fonts';

const themeValue = (prop, val) => (props) =>
  (props.theme[prop] && props.theme[prop][val]) || val;
const color = (name) => (props) =>
  (props.theme.colors && props.theme.colors[name]) || name;
const ls = (name) => (props) =>
  (props.theme.letterSpacing && props.theme.letterSpacing[name]) || name;
const variable = (name) => (props) =>
  (props.theme.variables && props.theme.variables[name]) || name;
const font = (
  fontSize,
  lineHeight,
  fontFamily,
  fontWeight = 400,
  fontStyle = 'normal',
) =>
  css`
    ${fontStyle} ${fontWeight} ${themeValue(
      'fontSizes',
      fontSize,
    )}/${themeValue('lineHeights', lineHeight)} ${themeValue(
      'fonts',
      fontFamily,
    )};
  `;
const fontSizes = (name) => (props) =>
  (props.theme.fontSizes && props.theme.fontSizes[name]) || name;
const withSiteTheme = (siteTheme, namedTheme) =>
  css`
    ${() => siteTheme.default || ''};
    ${(props) => siteTheme[props.theme.siteKey]};
    ${() => namedTheme || ''}
  `;

export {
  color,
  favoritesWidget,
  globalFonts,
  font,
  fontSizes,
  ls,
  mixins,
  reset,
  themes,
  themeValue,
  variable,
  withSiteTheme,
};
