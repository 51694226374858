import api from '../lib/api';

export const GET_PLAY_HOME_PAGE_PENDING = 'GET_PLAY_HOME_PAGE_PENDING';
export const GET_PLAY_HOME_PAGE_FULFILLED = 'GET_PLAY_HOME_PAGE_FULFILLED';
export const GET_PLAY_HOME_PAGE_REJECTED = 'GET_PLAY_HOME_PAGE_REJECTED';

export const getPlayHomePage = (slug) => ({
  type: 'GET_PLAY_HOME_PAGE',
  payload: {
    promise: api.play(slug),
  },
});
