import Script from 'next/script';
import React from 'react';

const markup =
  'setTimeout(function () {window.twttr = (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0],t = window.twttr || {};if (d.getElementById(id)) return t;js = d.createElement(s);js.id = id;js.src = "https://platform.twitter.com/widgets.js";js.crossOrigin="anonymous";js.defer = true;fjs.parentNode.insertBefore(js, fjs);t._e = [];t.ready = function(f) {t._e.push(f);};return t;}(document, "script", "twitter-wjs"));}, 50)';

const Twitter = () => (
  <Script
    key="twitter-widgets"
    id="twitter-widgets"
    dangerouslySetInnerHTML={{
      __html: markup,
    }}
  />
);

export default Twitter;
