import atk from '../lib/api/atk';

export const KIDS_PODCAST_NEWSLETTER_PENDING =
  'KIDS_PODCAST_NEWSLETTER_PENDING';
export const KIDS_PODCAST_NEWSLETTER_FULFILLED =
  'KIDS_PODCAST_NEWSLETTER_FULFILLED';
export const KIDS_PODCAST_NEWSLETTER_REJECTED =
  'KIDS_PODCAST_NEWSLETTER_REJECTED';

export const newsletterSubmission = ({ email, url }) => ({
  type: 'KIDS_PODCAST_NEWSLETTER',
  payload: {
    data: { email },
    promise: atk.post(url, { atkKidsEmail: email }),
  },
});
