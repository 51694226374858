import nClasses from './classnames';
import nTemplates from './templates';
import widgetApi from '../FavoritesWidget/widgetUtils/api';
import widgetUtils, { WidgetState } from '../FavoritesWidget/widgetUtils/utils';

const newsletterDefaults = {
  type: "Cook's Country Dinner Tonight",
  location: 'dinnertonight',
};

/**
 * When loaded onto a page (for mobile widths only), this widget will create a
 * small fixed-position widget at the bottom of the screen to collect emails.
 * Only renders for anonymous or registrant users.
 * Does not render if `newsletter_submitted` cookie is present
 * @param       {[type]} appEl [description]
 * @constructor
 */
function NewsletterForm(widgetEl, analytics, nCopy, newsletterType, options) {
  this.widgetEl = widgetEl;
  this.analytics = analytics;
  this.nCopy = nCopy;
  this.newsletterType = newsletterType;
  this.options = options || {};
  this.state = new WidgetState({
    email: '',
    error: null,
    status: 'DEFAULT',
  });

  this.handleSubmit = widgetUtils.debounce(
    this.handleSubmit.bind(this),
    250,
    true,
  );
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmissionSuccess = this.handleSubmissionSuccess.bind(this);
  this.handleSubmissionError = this.handleSubmissionError.bind(this);
  this.widgetEl.addEventListener('change', this.handleChange);
  this.widgetEl.addEventListener('submit', this.handleSubmit);
}

NewsletterForm.prototype = {
  /**
   * Unbind and undo when self-destructing
   */
  destroy() {
    this.widgetEl.removeEventListener('change', this.handleChange);
    this.widgetEl.removeEventListener('submit', this.handleSubmit);
  },

  /**
   * Getter for email
   */
  get email() {
    return this.state.get('email');
  },

  /**
   * Set email in state
   */
  set email(value) {
    this.state.set('email', value);
  },

  /**
   * Validates the email input as a required value
   */
  get empty() {
    return this.email.length === 0;
  },

  /**
   * Getter for error
   */
  get error() {
    return this.state.get('error');
  },

  /**
   * Set error in state and re-render if the value has changed
   */
  set error(value) {
    if (value !== this.state.get('error')) {
      this.state.set('error', value);
      this.update();
    }
    // find email input, and focus at the end of the screen
    // the type dance is necessary since email inputs don't support `selectionEnd`
    const emailInput = this.widgetEl.querySelector(
      '#newsletter-widget-form__email',
    );
    emailInput.setAttribute('type', 'text');
    emailInput.selectionStart = emailInput.selectionEnd = emailInput.value.length; // eslint-disable-line
    emailInput.setAttribute('type', 'email');
    emailInput.focus();
  },

  /**
   * Validates the email input as a valid email address
   */
  get invalid() {
    return !widgetUtils.isEmail(this.email);
  },

  /**
   * Used for the analytics event
   */
  get location() {
    const { newsletterLocation } = this.widgetEl.dataset;
    return (
      newsletterLocation || this.options.location || newsletterDefaults.location
    );
  },

  /**
   * Getter for open
   */
  get open() {
    return this.state.get('open');
  },

  /**
   * Getter for status
   */
  get status() {
    return this.state.get('status');
  },

  /**
   * Set status in state and re-render the component
   */
  set status(val) {
    this.state.set('status', val);
    this.update();
  },

  /**
   * Used for the analytics event
   */
  get type() {
    const { newsletterType } = this.widgetEl.dataset;
    return newsletterType || this.options.type || newsletterDefaults.type;
  },

  /**
   * On email change, save the value to state
   */
  handleChange(evt) {
    const input = evt.target.closest('#newsletter-widget-form__email');
    if (input) this.email = input.value.trim();
  },

  /**
   * When api request is successful:
   * - set status (updates UI)
   * - set a cookie to prevent future presentations of this widget
   * - track an event with Mixpanel
   * @return {[type]} [description]
   */
  handleSubmissionSuccess() {
    this.status = 'SUCCESS';
    widgetUtils.setCookie(
      `${this.newsletterType}_newsletter_submitted`,
      'true',
      14,
    );
    this.trackSuccessEvent();
  },

  /**
   * Return a default error when the newsletter submission fails
   * @return {[type]} [description]
   */
  handleSubmissionError() {
    this.error = this.nCopy.form.errors.unknown;
  },
  /**
   * Event handler for submit event on the container
   * Validates the form and (will eventually) send the data to the api
   * If successful, sets a cookie so the widget doesn't render for 14 days
   */
  handleSubmit(evt) {
    evt.preventDefault();
    const form = evt.target.closest('form');
    if (this.validate()) {
      this.status = 'SUBMITTING';
      const data = Object.assign({ email: this.email }, this.options);
      widgetApi.authenticatedFetch(
        form.getAttribute('action'),
        form.getAttribute('method'),
        data,
        this.handleSubmissionSuccess,
        this.handleSubmissionError,
      );
    }
  },

  /**
   * Validates the form input value as non-empty and properly formatted email address
   */
  validate() {
    if (this.empty) this.error = this.nCopy.form.errors.empty;
    else if (this.invalid) this.error = this.nCopy.form.errors.invalid;
    else this.error = null;

    return this.error === null;
  },

  /**
   * Does what you would think it would do
   */
  render() {
    return nTemplates.form(
      Object.assign({ nCopy: this.nCopy }, this.options, this.state.freeze()),
    );
  },

  /**
   * Replace HTML of the form with content from rendered template
   */
  update() {
    this.widgetEl.querySelector(`.${nClasses.form}`).outerHTML = this.render();
  },

  /**
   * Send event data to Mixpanel
   */
  trackSuccessEvent() {
    const evtData = {
      email: this.email,
      location: this.location,
      type: this.type,
      status: 'optin',
    };
    if (typeof this.options.incode !== 'undefined') {
      evtData.incode = this.options.incode;
    }
    this.analytics.trackEvent('NEWSLETTER_PREFERENCES', evtData);
    if (typeof this.options.de_name !== 'undefined') {
      this.analytics.track('NEWSLETTER_EMAIL_CAPTURE', {
        email: this.email,
        type: 'Newsletter',
      });
    }
  },
};

export default NewsletterForm;
