import { useState, useEffect, RefObject, useRef } from 'react';

export default function useTabTrapping({
  ref,
  isElementFocused,
}: {
  ref: RefObject<HTMLDivElement>;
  isElementFocused: boolean;
}) {
  const [firstTabbableElement, setFirstTabbableElement] =
    useState<HTMLElement | null>(null);
  const [lastTabbableElement, setLastTabbableElement] =
    useState<HTMLElement | null>(null);
  const previouslyFocusedElement = useRef<Element | null>(null);

  useEffect(() => {
    if (isElementFocused) {
      previouslyFocusedElement.current = document.activeElement;
    } else if (previouslyFocusedElement.current) {
      (previouslyFocusedElement.current as HTMLElement).focus();
      previouslyFocusedElement.current = null;
    }

    const tabbableEls = ref.current?.querySelectorAll<HTMLElement>(
      'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]), [contenteditable]',
    );
    if (isElementFocused && tabbableEls && tabbableEls.length > 0) {
      setFirstTabbableElement(tabbableEls[0]);
      setLastTabbableElement(tabbableEls[tabbableEls.length - 1]);
    } else {
      setFirstTabbableElement(null);
      setLastTabbableElement(null);
    }
  }, [ref, isElementFocused]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isElementFocused) return;
      if (event.key === 'Tab') {
        if (event.shiftKey && document.activeElement === firstTabbableElement) {
          event.preventDefault();
          lastTabbableElement?.focus();
        } else if (
          !event.shiftKey &&
          document.activeElement === lastTabbableElement
        ) {
          event.preventDefault();
          firstTabbableElement?.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isElementFocused, firstTabbableElement, lastTabbableElement]);
}
