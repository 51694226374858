import useAppContext, { DomainSiteKey } from 'lib/hooks/useAppContext';
import getSiteKeyPreference from './documents/getSiteKeyPreference';

/**
 * Removes old domain and subfolder paths from urls.
 * used for rel=canonical to prevent duplicate content in google seo.
 */
export function consolidateDomain(url: string): string {
  return url
    .replace('cookscountry.com', 'americastestkitchen.com')
    .replace('cooksillustrated.com', 'americastestkitchen.com')
    .replace(
      'americastestkitchen.com/cookscountry/',
      'americastestkitchen.com/',
    )
    .replace(
      'americastestkitchen.com/cooksillustrated/',
      'americastestkitchen.com/',
    );
}

export function subfolderURL(url: string, siteKey: DomainSiteKey): string {
  if (
    url.startsWith('/gift') ||
    url.startsWith('/support') ||
    url.startsWith('/cookscountry') ||
    url.startsWith('/cooksillustrated') ||
    !url.startsWith('/')
  )
    return url;

  if (siteKey === 'cio') return `/cooksillustrated${url}`;
  if (siteKey === 'cco') return `/cookscountry${url}`;

  return url;
}

export function consolidateDomainSiteKey(url: string, data: any) {
  const siteKey = getSiteKeyPreference(data?.attributes?.siteList, undefined);
  if (!siteKey) return url;
  const atkURL = consolidateDomain(url);
  const partialURL = atkURL.replace(/(.*americastestkitchen.com)/, '');
  const subfolderedURL = subfolderURL(partialURL, siteKey);
  return `https://www.americastestkitchen.com${subfolderedURL}`;
}

export function useSubfolderURL(url: string): string {
  const { domainSiteKey } = useAppContext();
  return subfolderURL(url, domainSiteKey);
}

const hostmap: Record<string, DomainSiteKey> = {
  americastestkitchen: 'atk',
  cookscountry: 'cco',
  cooksillustrated: 'cio',
};

type ParseURLReturnType =
  | {
      siteKey: DomainSiteKey;
      pathname: string;
    }
  | undefined;

function parseSubfolderURLAbsolute(url: string): ParseURLReturnType {
  try {
    const { hostname, pathname } = new URL(url);
    const host = hostname.replace(/([\w-]+).([\w]+).(com)(.*)/g, '$2');
    const siteKey = hostmap[host];
    if (siteKey === 'atk') return parseSubfolderURLRelative(pathname);
    return { siteKey, pathname };
  } catch {
    return undefined;
  }
}

function parseSubfolderURLRelative(url: string): ParseURLReturnType {
  if (url.startsWith('/cookscountry')) {
    return { siteKey: 'cco', pathname: url.replace('/cookscountry', '') };
  }
  if (url.startsWith('/cooksillustrated')) {
    return { siteKey: 'cio', pathname: url.replace('/cooksillustrated', '') };
  }
  return { siteKey: 'atk', pathname: url };
}

export function parseSubfolderURL(url: string): ParseURLReturnType {
  if (url.startsWith('/')) {
    return parseSubfolderURLRelative(url);
  } else {
    return parseSubfolderURLAbsolute(url);
  }
}

/**
 * Not for use in articles (ATM). Product wants all articles links to open in new tab.
 */
export function getAnchorTarget(url: string | undefined): undefined | '_blank' {
  // when called from javascript do a quick safety check
  if (!url) return undefined;
  // relative urls are always same tab
  if (!url.startsWith('http')) return undefined;
  // below conditional will pass on this but should be in new window.
  if (url.includes('shop.americastestkitchen.com')) return '_blank';
  // any of these should get redirected to same domain so same tab.
  if (
    [
      'americastestkitchen.com',
      'cookscountry.com',
      'cooksillustrated.com',
    ].some((domain) => url.includes(domain))
  ) {
    return undefined;
  }
  // any external link should open in new tab.
  return '_blank';
}
