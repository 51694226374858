import algolia from '../lib/algolia';

export const GET_COURSE_PENDING = 'GET_COURSE_PENDING';
export const GET_COURSE_FULFILLED = 'GET_COURSE_FULFILLED';
export const GET_COURSE_REJECTED = 'GET_COURSE_REJECTED';

export const getCourse = (queryTerm, isOverride) => ({
  type: 'GET_COURSE',
  payload: {
    promise: algolia.getSchoolCourse(queryTerm, isOverride),
  },
});
