import widgetApi from './widgetUtils/api';
import widgetUtils from './widgetUtils/utils';
import {
  getDocCollectionsActiveKey,
  createCollection as v6CreateCollection,
  destroyCollectionItem,
  postCollectionItem,
  postFavorite,
  destroyFavorite,
} from '../../../lib/api/favorites';
import utils from '../../../lib/utils/index';
import {
  renderHiddenAlertText,
  renderToggleButtonState,
} from './aria-utilities';

// DEV note - traceur doesn't like trailing commas - don't use them

/**
 * Update favorite toggle widget based on state.
 * @param {HTMLElement} el
 * @param {string} docTitle  the title of the document removed from favorites.
 * @param {boolean} pressed  the pressed state, true for favorited.
 */
function updateFavoriteWidget(el, docTitle, pressed) {
  if (pressed) {
    renderHiddenAlertText(el, `${docTitle} has been added to your favorites.`);
    renderToggleButtonState(
      el,
      false,
      `Remove from your favorites: ${docTitle}.`,
    );
  } else {
    renderHiddenAlertText(
      el,
      `${docTitle} has been removed from your favorites.`,
    );
    renderToggleButtonState(el, false, `Save to your favorites: ${docTitle}.`);
  }
}

function FavoritablesManager(
  dataAttr = 'data-manage-favoritable-id',
  className = 'favorited',
) {
  this.favoritedClassName = className;
  this.favoriteDataAttr = dataAttr;
  this.favoriteDataAttrCamelcase = widgetUtils.hyphenToCamelCase(
    dataAttr.replace('data-', ''),
  );
  this.favoritables = {};
  // this can be called from router onChange AND when we get data from algolia
  // we have to listen to both, so we should debounce to avoid duplicate calls
  this.toggleFavorite = widgetUtils.debounce(
    this.toggleFavorite.bind(this),
    250,
  );
  this.getFavoriteCollections = widgetUtils.debounce(
    this.getFavoriteCollections.bind(this),
    250,
  );
  this.createCollection = widgetUtils.debounce(
    this.createCollection.bind(this),
    500,
    true,
  );
}

FavoritablesManager.prototype = {
  /**
   * Set the 'elems' property for each registered favoritable to an empty list.
   * @return {[type]} [description]
   */
  clearFavoritableElems() {
    Object.keys(this.favoritables).map(
      (f) => (this.favoritables[f].elems = []),
    ); // eslint-disable-line
  },

  /**
   * Select DOM elements matching configured scope.
   * Optionally selects a specific document element.
   * @param  {String} objectId
   * @return {Array}
   */
  getFavoritableElems(docKey) {
    let matches;
    const bits = docKey ? docKey.split('--') : [];
    const objectId = bits[0];

    // find all elems that match the passed in data attribute with objectID
    if (objectId) {
      matches = document.querySelectorAll(
        `[${this.favoriteDataAttr}="${objectId}"]`,
      );
    } else {
      matches = document.querySelectorAll(`[${this.favoriteDataAttr}]`);
    }
    return [].slice.call(matches);
  },

  /**
   * Clear and repopulate the list of current Favoritables.
   * @return {undefined}
   */
  update() {
    const currentSiteKey = widgetUtils.getSiteKey();
    this.clearFavoritableElems();
    this.getFavoritableElems().reduce((obj, favEl) => {
      const favId = favEl.dataset[this.favoriteDataAttrCamelcase];
      const originSite = favEl.dataset.originSite || currentSiteKey;
      const objIndex = `${favId}--${originSite}`;
      const elems =
        typeof obj[objIndex] === 'undefined' ? [] : obj[objIndex].elems;
      elems.push(favEl);
      obj[objIndex] = {
        elems,
        favorited: favEl.classList.contains(this.favoriteClassName),
        originSite,
        processed: false,
      };
      return obj;
    }, this.favoritables);
  },

  /**
   * Mark DOM elements as favorited or not favorited.
   * @return {undefined}
   */
  updateElements() {
    const className = this.favoritedClassName;
    const favoritables = this.favoritables;
    Object.keys(favoritables).forEach((favId) => {
      const favoritable = favoritables[favId];
      const isFavorited = favoritable.favorited;
      const hasDomElements =
        typeof favoritable.elems !== 'undefined' && favoritable.elems.length;
      if (hasDomElements) {
        favoritable.elems.forEach((el) => {
          const hasFavClassname = el.classList.contains(className);
          const docTitle = el.dataset.documentTitle
            ? el.dataset.documentTitle
            : 'this item';
          if (isFavorited && !hasFavClassname) {
            updateFavoriteWidget(el, docTitle, true);

            el.classList.add(className);
          } else if (!isFavorited && hasFavClassname) {
            updateFavoriteWidget(el, docTitle, false);

            el.classList.remove(className);
          }
        });
      }
    });
  },

  /**
   * Update the DOM without requesting new data from the server.
   * Useful if toggling grid/list when the result card for a favorited
   * item has changed.
   */
  updateFavoritedElems() {
    const currentSiteKey = widgetUtils.getSiteKey();
    this.clearFavoritableElems();
    this.getFavoritableElems().reduce((obj, favEl) => {
      const favId = favEl.dataset[this.favoriteDataAttrCamelcase];
      const originSite = favEl.dataset.originSite || currentSiteKey;
      const objIndex = `${favId}--${originSite}`;
      const elems =
        typeof obj[objIndex] === 'undefined' ? [] : obj[objIndex].elems;
      const favorited =
        typeof obj[objIndex] === 'undefined' ? false : obj[objIndex].favorited;
      elems.push(favEl);
      obj[objIndex] = {
        elems,
        favorited,
        originSite,
        processed: false,
      };
      return obj;
    }, this.favoritables);
    this.updateElements();
  },

  /**
   * Filter favoritables, returning only the entries which haven't been checked via the api
   * @return {Array}
   */
  getUnprocessedFavoritables() {
    return Object.keys(this.favoritables).filter(
      (favId) => !this.favoritables[favId].processed,
    );
  },

  /**
   * Send the list of current documents to the api, receiving a list of matching favoritables.
   * Mark each document as processed and favorited based on API response. Finally,
   * update the DOM elements.
   * @return {undefined}
   */
  getFavoritedDocuments() {
    const favoritables = this.favoritables;
    this.update();
    const objectIds = this.getUnprocessedFavoritables();
    if (objectIds.length) {
      const url = `${widgetApi.constants.baseUrl}/${widgetApi.constants.endpoints.favorites_intersection}`;
      widgetApi.authenticatedFetch(
        `${url}?site_key=${widgetUtils.getSiteKey()}`,
        'POST',
        objectIds.reduce(
          (obj, curr) => {
            obj.documents[curr] = favoritables[curr].originSite;
            return obj;
          },
          { documents: {} },
        ),
        (data) => {
          objectIds.reduce((obj, favId) => {
            obj[favId].favorited = data.includes(favId);
            obj[favId].processed = true;
            return obj;
          }, this.favoritables);
          this.updateElements();
        },
      );
    } else {
      this.updateElements();
    }
  },

  /**
   * Helper method for parsing object_id's, which can be 'taste_test_123' or 'recipe_8125'
   * @param  {String} objectId
   * @return {Object}
   */
  parseObjectId(objectId) {
    const delimiter = objectId.lastIndexOf('_');
    let type = objectId.substring(0, delimiter);
    const id = objectId.substring(delimiter + 1);
    return {
      id,
      type,
    };
  },

  getFavoritableRestUrl(objectId) {
    const favoritable = this.parseObjectId(objectId);
    return [
      widgetApi.constants.baseUrl,
      `${widgetApi.constants.endpoints.favorites}`,
      `${favoritable.type}/${favoritable.id}`,
    ].join('/');
  },

  // /user_favorites/:type/:id/collections
  getFavoritableCollectionsUrl(objectId) {
    return [this.getFavoritableRestUrl(objectId), 'collections'].join('/');
  },

  getCollectionRestUrl(collectionSlug, favoriteId) {
    const collectionUrl = [
      widgetApi.constants.baseUrl,
      widgetApi.constants.endpoints.favorite_collections,
    ];
    if (collectionSlug) collectionUrl.push(collectionSlug);
    if (favoriteId) collectionUrl.push(`items/${favoriteId}`);
    return collectionUrl.join('/');
  },

  updateFavoritableElems(docKey) {
    const isFavorited = this.favoritables[docKey].favorited;
    const classListMethod = isFavorited ? 'add' : 'remove';
    const className = this.favoritedClassName;
    this.getFavoritableElems(docKey).forEach((e) => {
      e.classList[classListMethod](className);
      const docTitle = e.dataset.documentTitle
        ? e.dataset.documentTitle
        : 'this item';
      updateFavoriteWidget(e, docTitle, isFavorited);
    });
  },

  /**
   * Call favorite api, creating or deleting a user favorite.
   * When complete, update the associated elements on the page.
   * @param  {String} objectId
   * @param  {String} siteKey
   * @param  {String} httpMethod
   * @param  {Function} successCallback
   * @param  {Function} errorCallback
   * @return {undefined}
   */
  toggleFavorite(
    objectId,
    siteKey,
    httpMethod,
    successCallback,
    errorCallback,
  ) {
    const docSiteKey = widgetUtils.getSiteKey(siteKey);
    const favoritable = this.parseObjectId(objectId);
    const errorText = 'Unable to toggle favorite';

    const resHandler = (res) => {
      if (typeof successCallback !== 'undefined') {
        const docKey = `${objectId}--${docSiteKey}`;
        if (typeof this.favoritables[docKey] !== 'undefined') {
          this.favoritables[docKey].processed = false;
          this.favoritables[docKey].favorited =
            !this.favoritables[docKey].favorited;
          this.updateFavoritableElems(docKey);
          if (typeof successCallback !== 'undefined')
            successCallback({
              data: res,
            });
        }
      } else {
        if (typeof errorCallback !== 'undefined') errorCallback(errorText);
      }
    };

    if (httpMethod === 'DELETE') {
      destroyFavorite(objectId)
        .then((data) => resHandler(data))
        .catch(() => () => errorCallback(errorText));
    } else {
      postFavorite(favoritable.type, favoritable.id, docSiteKey)
        .then((res) => resHandler(res))
        .catch(() => errorCallback(errorText));
    }
  },

  createFavorite(objectId, siteKey, successCallback, errorCallback) {
    this.toggleFavorite(
      objectId,
      siteKey,
      'POST',
      successCallback,
      errorCallback,
    );
  },

  destroyFavorite(objectId, siteKey, successCallback, errorCallback) {
    this.toggleFavorite(
      objectId,
      siteKey,
      'DELETE',
      successCallback,
      errorCallback,
    );
  },

  /**
   * Call favorite collections api, adding or removing a user favorite from a collection.
   * @param  {String} collectionSlug
   * @param  {Integer} favoriteId
   * @param  {String} httpMethod
   * @param  {Function} successCallback
   * @param  {Function} errorCallback
   * @return {undefined}
   */
  toggleFavoriteCollection(
    collectionSlug,
    favoriteId,
    httpMethod,
    successCallback,
    errorCallback,
  ) {
    const resHandler = () => {
      if (typeof successCallback !== 'undefined') {
        successCallback({ name: collectionSlug });
      }
    };

    // these methods on favorites v6 return 201 status codes no data

    if (httpMethod === 'DELETE') {
      destroyCollectionItem(collectionSlug, favoriteId)
        .then(() => resHandler())
        .catch(() => errorCallback());
    } else {
      postCollectionItem(collectionSlug, favoriteId)
        .then(() => resHandler())
        .catch(() => errorCallback());
    }
  },

  addFavoriteCollectionItem(
    collectionSlug,
    favoriteId,
    successCallback,
    errorCallback,
  ) {
    return this.toggleFavoriteCollection(
      collectionSlug,
      favoriteId,
      'POST',
      successCallback,
      errorCallback,
    );
  },

  removeFavoriteCollectionItem(
    collectionSlug,
    favoriteId,
    successCallback,
    errorCallback,
  ) {
    return this.toggleFavoriteCollection(
      collectionSlug,
      favoriteId,
      'DELETE',
      successCallback,
      errorCallback,
    );
  },

  /**
   * Call favorite collection api, creating a new collection
   * @param  {String} name
   * @param  {Integer} id
   * @param  {Function} successCallback
   * @param  {Function} errorCallback
   * @return {undefined}
   */
  createCollection(name, id, successCallback, errorCallback) {
    if (!name && !id) return;
    if (id.includes('_')) {
      v6CreateCollection(name)
        .then((resCreateCollection) => {
          if (resCreateCollection && typeof successCallback !== 'undefined') {
            postCollectionItem(resCreateCollection.data.slug, id).then(() =>
              successCallback(resCreateCollection),
            );
          }
        })
        .catch(() => errorCallback('Error creating collection'));

      return;
    }

    v6CreateCollection(name, id)
      .then((data) => {
        if (data && typeof successCallback !== 'undefined') {
          successCallback(data);
        } else {
          errorCallback('Error creating collection');
        }
      })
      .catch(() => errorCallback('Error creating collection'));
  },

  /**
   * Call api endpoint to fetch a list of all user collections
   * @param  {String} objectId
   * @param  {String} siteKey
   * @param  {Function} successCallback
   * @param  {Function} errorCallback
   * @return {undefined}
   */
  getFavoriteCollections(objectId, siteKey, successCallback, errorCallback) {
    const docSiteKey = widgetUtils.getSiteKey(siteKey);
    const compoundId = `${objectId}--${siteKey}`;
    const favoritable = this.parseObjectId(objectId);
    const browsePath = utils.favoriteDocTypeToPath[favoritable.type];

    // elements use the active key to toggle radio buttons which v6 does not use
    getDocCollectionsActiveKey(browsePath, objectId, docSiteKey).then(
      (data) => {
        if (data) {
          if (typeof this.favoritables[compoundId] !== 'undefined') {
            this.favoritables[compoundId].collections = data;
            if (typeof successCallback !== 'undefined') successCallback(data);
          } else if (typeof errorCallback === 'function') {
            errorCallback('An unexpected error has occurred.');
          }
        } else {
          errorCallback('Unable to retrieve collection.');
        }
      },
    );
  },
};

export default FavoritablesManager;
