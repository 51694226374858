import {
  GET_LATEST_DOCUMENTS_FULFILLED,
  GET_LATEST_DOCUMENTS_PENDING,
  GET_LATEST_DOCUMENTS_REJECTED,
} from '../actions/latest';
import constants from '../config/constants';

const { STATUS_CODES: c } = constants;

const siteData = {
  documents: [],
  status: c.DEFAULT,
};

export const initialState = {
  data: { ...siteData },
};

const latest = (state = initialState, action) => {
  switch (action.type) {
    case GET_LATEST_DOCUMENTS_PENDING:
      return {
        data: {
          ...state.data,
          status: c.LOADING,
        },
      };

    case GET_LATEST_DOCUMENTS_REJECTED:
      return {
        data: {
          ...state.data,
          status: c.ERROR,
        },
      };

    case GET_LATEST_DOCUMENTS_FULFILLED:
      const { response } = action.payload;
      return {
        data: {
          ...state.data,
          documents: response,
          status: c.READY,
        },
      };

    default:
      return state;
  }
};

export default latest;
