import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Component } from 'react';
import honeybadger from 'lib/errors/honeybadger';
import analytics from '../../lib/analytics';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.trackEvent = debounce(this.trackEvent, 500);
  }

  componentDidCatch(error, info) {
    this.trackEvent(error, info);
  }

  trackEvent(error, info) {
    const { location } = this.props;
    analytics.track('ERROR_BOUNDARY', { error, info, location });
    honeybadger.notify(error, { context: { ...info, location } });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  /** Add identifying information to mixpanel */
  location: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ErrorBoundary.defaultProps = {
  location: '',
};

export default ErrorBoundary;
