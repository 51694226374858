const translations = {
  hyphenated: {
    recipe: 'recipes',
    episode: 'episodes',
    video: 'videos',
    how_to: 'how-tos',
    taste_test: 'taste-tests',
    equipment_review: 'equipment-reviews',
    course: 'course',
    cookbook: 'cookbook',
    buying_guide: 'buying-guide',
  },
  document_klass: {
    recipe: 'Recipe',
    taste_test: 'Taste Test',
    equipment_review: 'Equipment Review',
    how_to: 'How To',
    magazine: 'Magazine',
    video: 'Clip',
    article: 'Article',
    episode: 'Episode',
    course: 'Course',
    cookbook: 'Cookbook',
    buying_guide: 'Buying Guide',
  },
  plural: {
    article: 'Articles',
    book: 'Cookbook Collections',
    buying_guide: 'Buying Guides',
    collection: 'Collections',
    cookbook: 'Cookbooks',
    course: 'Courses',
    episode: 'Episodes',
    equipment_review: 'Equipment Reviews',
    how_to: 'How Tos',
    kids_activity: 'Kids Activities',
    kids_recipe: 'Kids Recipes',
    kids_quiz: 'Kids Quizzes',
    magazine: 'Magazines',
    recipe: 'Recipes',
    taste_test: 'Taste Tests',
    video: 'Clips',
  },
  browse: {
    document_klass: {
      articles: 'Articles',
      books: 'Books',
      'buying-guides': 'Buying Guides',
      buying_guides: 'Buying Guides',
      episodes: 'Episodes',
      equipment_reviews: 'Equipment Reviews',
      feature_contents: 'Features',
      guides: 'Guides',
      how_tos: "How To's",
      magazines: 'Magazines',
      radio: 'Radio Programs',
      recipes: 'Recipes',
      taste_tests: 'Taste Tests',
      videos: 'Clips',
      playlists: 'Playlists',
    },
    document_klass_singular_human: {
      articles: 'Article',
      books: 'Book',
      episodes: 'Episode',
      equipment_reviews: 'Equipment Review',
      how_tos: 'How To',
      magazines: 'Magazine',
      recipes: 'Recipe',
      taste_tests: 'Taste Test',
      science: 'Science',
      videos: 'Video',
    },
    document_klass_singular: {
      books: 'book',
      buying_guides: 'buying_guide',
      episodes: 'episode',
      equipment_reviews: 'equipment_review',
      how_tos: 'how_to',
      magazines: 'magazine',
      recipes: 'recipe',
      taste_tests: 'taste_test',
      videos: 'video',
    },
    facetKey: {
      books: 'recipe',
      'buying-guides': 'buying_guide',
      courses: 'course',
      episodes: 'episode',
      equipment_reviews: 'equipment_review',
      how_tos: 'how_to',
      magazines: 'magazine',
      recipes: 'recipe',
      taste_tests: 'taste_test',
      videos: 'video',
    },
    facetKeyToHumanPlural: {
      books: 'books',
      courses: 'courses',
      episodes: 'episodes',
      equipment_reviews: 'reviews',
      how_tos: 'how tos',
      magazine: 'magazines',
      recipes: 'recipes',
      taste_tests: 'tests',
      videos: 'videos',
    },
    /* the following are doc-type specific mappings for slugs that don't translate
     * directly to their text version - i.e. tools_gadgets => Tools & Gadgets */
    books: {},
    'buying-guides': {},
    episodes: {
      '/episodes/browse': 'Full Episodes',
      '/videos/browse/recipes': 'Recipe Clips',
      '/videos/browse/equipment_reviews': 'Equipment Review Clips',
      '/videos/browse/taste_tests': 'Taste Test Clips',
      '/videos/browse/science': 'Science Clips',
    },
    equipment_reviews: {
      trending: 'Trending This Week',
      bakeware: 'Bakeware',
      cleaning_supplies: 'Cleaning Supplies',
      cookware: 'Cookware',
      grilling: 'Grilling',
      kids_babies: 'Kids & Babies',
      kitchen_basics: 'Kitchen Basics',
      knives: 'Knives',
      small_appliances: 'Small Appliances',
      tools_gadgets: 'Tools & Gadgets',
    },
    equipment_reviews_to_url_path: {
      'Kids & Babies': 'kids_babies',
      'Tools & Gadgets': 'tools_gadgets',
      'Trending This Week': 'trending',
    },
    equipment_reviews_cio: {
      '/equipment_reviews/browse/buying-guides': 'Buying Guides',
      trending: 'Trending This Week',
      bakeware: 'Bakeware',
      cookware: 'Cookware',
      kitchen_basics: 'Kitchen Basics',
      knives: 'Knives',
      small_appliances: 'Small Appliances',
      tools_gadgets: 'Tools & Gadgets',
    },
    how_tos: {
      baking: 'Baking & Desserts',
      beverages: 'Beverages',
      cooking_101: 'Cooking 101',
      cooking_basics: 'Cooking Basics',
      dairy: 'Dairy & Eggs',
      fats: 'Fats & Oils',
      fish: 'Fish & Shellfish',
      fruits: 'Fruits',
      grilling: 'Grilling',
      kitchen_equipment: 'Kitchen Equipment',
      meat: 'Meat',
      pasta: 'Pasta, Grains, & Beans',
      poultry: 'Poultry',
      seasonings: 'Seasonings',
      vegetables: 'Vegetables',
    },
    magazines: {},
    recipes: {
      beans_and_grains: 'Beans & Grains',
      breads_sandwiches_pizza: 'Breads, Sandwiches, & Pizza',
      breakfast: 'Eggs & Breakfast',
      desserts: 'Desserts & Baked Goods',
      seafood: 'Fish & Seafood',
      grilling: 'Grilling & Barbecue',
      sauces: 'Marinades & Sauces',
      meat: 'Meat',
      pasta: 'Pasta',
      poultry: 'Poultry',
      salads: 'Salads',
      soups: 'Soups & Stews',
      vegetables: 'Vegetables',
    },
    recipesLanding: {
      beans_and_grains: 'Beans & Grains',
      breads: 'Breads',
      sandwiches: 'Sandwiches',
      pizza: 'Pizza',
      breakfast: 'Eggs & Breakfast',
      desserts: 'Desserts & Baked Goods',
      seafood: 'Fish & Seafood',
      grilling: 'Grilling & Barbecue',
      sauces: 'Marinades & Sauces',
      meat: 'Meat',
      pasta: 'Pasta',
      poultry: 'Poultry',
      salads: 'Salads',
      soups: 'Soups & Stews',
      vegetables: 'Vegetables',
    },
    reviewsLanding: {
      tools_gadgets: 'Tools & Gadgets',
      kitchen_basics: 'Kitchen Basics',
      pantry_items: 'Pantry Items',
      small_appliances: 'Small Appliances',
      cookware: 'Cookware',
      bakeware: 'Bakeware',
      dairy_products: 'Dairy Products',
      prepared_foods: 'Prepared Foods',
      grilling: 'Grilling',
      knives: 'Knives',
      pasta_grains_beans: 'Pasta, Grains, & Beans',
      spices: 'Spices & Seasonings',
      beverages: 'Beverages',
      canned_goods: 'Canned Goods',
      baking_ingredients: 'Baking Ingredients',
      meat_poultry: 'Meat & Poultry',
      seafood: 'Seafood',
      cleaning_supplies: 'Cleaning Supplies',
      kids_babies: 'Kids & Babies',
    },
    searchPlaceholder: {
      episodes: 'Search episodes and clips',
      equipment_reviews: 'Search equipment reviews',
      recipes: 'Search recipes',
      taste_tests: 'Search taste tests',
      videos: 'Search episodes and clips',
    },
    taste_tests: {
      baking_ingredients: 'Baking Ingredients',
      beverages: 'Beverages',
      canned_goods: 'Canned Goods',
      dairy: 'Dairy & Eggs',
      dairy_products: 'Dairy Products',
      meat: 'Meat, Poultry, & Seafood',
      meat_poultry: 'Meat & Poultry',
      pantry_items: 'Pantry Items',
      pasta_grains_beans: 'Pasta, Grains, & Beans',
      prepared_foods: 'Prepared Foods',
      produce: 'Produce',
      seafood: 'Seafood',
      spices: 'Spices & Seasonings',
    },
    taste_tests_to_url_path: {
      'Dairy & Eggs': 'dairy_eggs',
      'Pasta, Grains, & Beans': 'pasta_grains_beans',
      'Meat & Poultry': 'meat_poultry',
      'Meat, Poultry, & Seafood': 'meat',
      'Spices & Seasonings': 'spices',
    },
    videos: {
      '/episodes/browse': 'Full Episodes',
      '/videos/browse/recipes': 'Recipe Clips',
      '/videos/browse/equipment_reviews': 'Equipment Review Clips',
      '/videos/browse/taste_tests': 'Taste Test Clips',
      '/videos/browse/science': 'Science Clips',
    },
    videos_cio: {
      baking: 'Baking & Desserts',
      meat: 'Meat',
      poultry: 'Poultry',
      fish: 'Fish & Shellfish',
      pasta_grains_beans: 'Pasta, Grains & Beans',
      salads_sandwiches: 'Salads & Sandwiches',
      soups: 'Soups',
      sauces: 'Sauces',
      fruits_vegetables: 'Fruits & Vegetables',
      breakfast: 'Dairy, Eggs & Breakfast',
      grilling: 'Grilling',
      beverages: 'Beverages',
      cooking_basics: 'Cooking Basics & Kitchen Equipment',
      seasonings: 'Seasonings, Fats & Oils',
      reviews: 'Reviews',
    },
    // for videos and episodes, cause their paths are screwy
    facetValueFallbacks: {
      episodes: {},
      equipment_reviews: {},
      magazines: {},
      recipes: {},
      taste_tests: {},
      videos: {
        baking: 'Baking & Desserts',
        meat: 'Meat',
        poultry: 'Poultry',
        fish: 'Fish & Shellfish',
        pasta_and_grains: 'Pasta, Grains & Beans',
        pasta_grains_beans: 'Pasta, Grains & Beans',
        salads_sandwiches: 'Salads & Sandwiches',
        soups: 'Soups',
        sauces: 'Sauces',
        fruits_vegetables: 'Fruits & Vegetables',
        breakfast: 'Dairy, Eggs & Breakfast',
        grilling: 'Grilling',
        beverages: 'Beverages',
        cooking_basics: 'Cooking Basics & Kitchen Equipment',
        seasonings: 'Seasonings, Fats & Oils',
        equipment_reviews: 'Equipment Review Clips',
        recipes: 'Recipe Clips',
        reviews: 'Reviews',
        science: 'Science Clips',
        taste_tests: 'Taste Test Clips',
      },
    },
  },
};

const reverseObject = (obj) =>
  Object.keys(obj).reduce((newObj, key) => {
    newObj[obj[key]] = key;
    return newObj;
  }, {});

// Comment to remind the format produced by the following 'pluralizeFacetKey' code
// { recipe: "recipes", taste_test: "taste_tests" ... }
translations.pluralizeFacetKey = reverseObject(translations.browse.facetKey);
translations.favorites = {
  equipment_reviews: reverseObject(translations.browse.equipment_reviews),
  recipes: reverseObject(translations.browse.recipes),
  taste_tests: reverseObject(translations.browse.taste_tests),
};

const validList = {
  search_review_type_list: [
    'Bakeware',
    'Cleaning Supplies',
    'Cookware',
    'Grilling',
    'Kids & Babies',
    'Kitchen Basics',
    'Knives',
    'Small Appliances',
    'Tools & Gadgets',
  ],
  search_test_type_list: [
    'Pantry Items',
    'Dairy Products',
    'Prepared Foods',
    'Pasta, Grains, & Beans',
    'Spices & Seasonings',
    'Beverages',
    'Canned Goods',
    'Baking Ingredients',
    'Meat & Poultry',
    'Seafood',
  ],
};

module.exports = {
  translations,
  validList,
  search_site_list: [
    'cio',
    'cco',
    'atk',
    'cookbook_collection',
    'school',
    'shop',
  ],
  /* NOTE - these are intentionally not alphabetized - listed in order of appearance on search */
  searchable_document_klass: [
    'recipe',
    'equipment_review',
    'taste_test',
    'how_to',
    'article',
    'video',
    'episode',
    'course',
    'cookbook',
  ],
  /* see algolia.js - getValidSubFacets */
  search_document_klass: {
    recipe: {
      search_course_list: 'Course',
      search_cuisine_list: 'Cuisine',
      search_dish_type_list: 'Dish Type',
      search_holiday_list: 'Holiday',
      search_main_ingredient_list: 'Main Ingredient',
      search_recipe_type_list: 'Recipe Type',
      search_cookbook_collection_titles: 'Cookbook Collection',
      search_browse_slugs: 'Recipe Categories',
    },
    equipment_review: {
      search_review_type_list: 'Equipment',
    },
    taste_test: {
      search_test_type_list: 'Ingredients',
    },
    how_to: {
      search_how_to_type_list: 'How To Type',
    },
    article: null,
    video: {
      search_season_list: 'Season',
      search_course_list: 'Course',
      search_cuisine_list: 'Cuisine',
      search_dish_type_list: 'Dish Type',
      search_main_ingredient_list: 'Main Ingredient',
      search_recipe_type_list: 'Recipe Type',
      search_review_type_list: 'Equipment',
      search_test_type_list: 'Ingredients',
    },
    episode: {
      search_season_list: 'TV Season',
    },
    magazine: {
      search_document_klass_formatted: 'Category',
    },
    course: null,
    cookbook: null,
    buying_guide: null,
  },
};
