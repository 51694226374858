import favoritesTemplates from './templates';

/**
 * Creates DOM for a single collection checkbox input.
 * @param  {Object} collection the collection data to initially render.
 * @return {HTMLElement} DOM element for input checkbox of collection.
 */
function collectionInput(collection, favoritableTitle) {
  const el = document.createElement('div');
  el.id = `input-collection-${collection.slug}-wrapper`;
  el.className = 'collections-list__input';
  el.innerHTML = `
    <label
      class="control control--checkbox"
      for="input-collection-${collection.slug}"
    >
      <input
        class="${collection.active ? 'active' : 'inactive'}"
        id="input-collection-${collection.slug}"
        name="input-collection-${collection.slug}"
        type="checkbox"
        aria-label="add ${favoritableTitle} to your ${
    collection.name
  } collection"
        value="${collection.slug}"
        ${collection.active ? 'checked' : ''}
      />
      <div class="control__indicator"></div>
      <span
        id="input-collection-${collection.slug}-control-label"
        class="control-label"
      >
          ${collection.name}
        </span>
    </label>
  `;
  return el;
}

/**
 * Creates HTML string from state with help from @see {@link favoritesTemplates.popoverTemplate}.
 * @return {String}  html string for popovers.
 */
function popover(state, collections, triggerEl, maxWidth) {
  const innerHTML = `
      <section id="favorites-popover" aria-labelledby="favorites-popover__label">
        <heading class="favorites-popover__heading">
          <h3 class="favorites-popover__heading" id="favorites-popover__label">
            Add to custom collection
          </h3>
          <a class="mobile-close close" href="#close" title="Close">
            <svg width="11" height="10" viewBox="0 0 11 10">
              <g fill="none" fill-rule="evenodd" stroke="#999" stroke-linecap="round">
                <path d="M1.313.313l9.11 9.11M1.313 9.688l9.11-9.111"/>
              </g>
            </svg>
          </a>
        </heading>
        <div>
          <div id="collection-list" class="collections-list"></div>
          <form
            id="collection-form"
            class="collection-form${state.error ? ' error' : ''}"
          >
            <div>
              <div class="collection-form__input">
                <input
                  aria-describedby="favorites-popover-error"
                  id="collectionName"
                  name="collectionName"
                  placeholder="Add New Collection"
                  type="text"
                />
              </div>
              <div class="collection-form__button">
                <button
                  class="favorites-app-button"
                  type="submit"
                >
                  CREATE
                </button>
              </div>
            </div>
          </form>
          ${
            state.error
              ? `<p id="favorites-popover-error" class="favorites-popover__error">${state.error}</p>`
              : ''
          }
        </div>
        <footer class="favorites-popover__footer">
          <a class="dashboard-link" href="/favorites" title="Go to Favorites" data-last-element>Go to Favorites</a>
        </footer>
      </section>
    `;

  const classes = [];
  if (state.open) classes.push('open');

  return favoritesTemplates.popoverTemplate(
    triggerEl,
    innerHTML,
    classes,
    maxWidth,
  );
}

/**
 * Rerender existing popover collections changes.
 * @param {any[]} collections
 */
function renderPopoverCollections(collections, favoritableTitle) {
  const collectionList = document.getElementById('collection-list');

  /** @returns {HTMLElement} */
  const getCollectionInputWrapper = (collection) =>
    document.getElementById(`input-collection-${collection.slug}-wrapper`);

  /** @returns {HTMLElement} */
  const getCollectionInput = (collection) =>
    document.getElementById(`input-collection-${collection.slug}`);

  /** @returns {HTMLElement} */
  const getCollectionInputLabel = (collection) =>
    document.getElementById(
      `input-collection-${collection.slug}-control-label`,
    );

  collections.forEach((collection, index) => {
    const wrapper = getCollectionInputWrapper(collection);
    if (!wrapper) {
      const element = collectionInput(collection, favoritableTitle);
      if (index === 0) {
        const firstInput = element.querySelector('input');
        if (firstInput) firstInput.setAttribute('data-first-element', 'true');
      }
      collectionList.appendChild(element);
    } else {
      const collectionInput = getCollectionInput(collection);
      if (index === 0) {
        const firstInput = collectionInput.querySelector('input');
        if (firstInput) firstInput.setAttribute('data-first-element', 'true');
      }
      collectionInput.className = collection.active ? 'active' : 'inactive';
      if (collection.active) {
        collectionInput.setAttribute('checked', '');
      }

      const collectionInputLabel = getCollectionInputLabel(collection);
      collectionInputLabel.textContent = collection.name;
    }
  });
}

/**
 * Rerenders add to collection form error text and styles.
 * @param {any} state popover app state
 */
function renderFormError(state) {
  const collectionForm = document.getElementById('collection-form');
  collectionForm.classList.toggle('error', state.error);
  const errorP = document.getElementById('favorites-popover-error');
  if (!errorP && state.error) {
    const elP = document.createElement('p');
    elP.id = 'favorites-popover-error';
    elP.className = 'favorites-popover__error';
    elP.setAttribute('role', 'status');
    elP.textContent = state.error;
    collectionForm.insertAdjacentElement('afterend', elP);
  } else if (errorP && state.error) {
    errorP.textContent = state.error;
  } else if (errorP && !state.error) {
    errorP.remove();
  }
}

/**
 * Rerenders add to collection form success text and styles.
 * @param {any} state popover app state
 */

function renderFormSuccess(state) {
  const collectionForm = document.getElementById('collection-form');
  collectionForm.classList.toggle('success', state.success);
  const successP = document.getElementById('favorites-popover-success');
  if (!successP && state.success) {
    const elP = document.createElement('p');
    elP.id = 'favorites-popover-success';
    elP.className = 'favorites-popover__success';
    elP.setAttribute('role', 'status');
    elP.textContent = state.success;
    collectionForm.insertAdjacentElement('afterend', elP);
  } else if (successP && state.success) {
    successP.textContent = state.success;
  } else if ((successP && state.error) || (successP && !state.success)) {
    successP.remove();
  }
}

/**
 * Pass through render calls for popover collection checkboxes and form errors.
 * @see {@link renderPopoverCollections}
 * @see {@link renderFormError}
 * @param {any} state
 * @param {Array<any>} collections
 */
function renderPopover(state, collections, favoritableTitle) {
  renderPopoverCollections(collections, favoritableTitle);
  renderFormError(state);
  renderFormSuccess(state);
}

/**
 * Helper is exposed method to previous implementation,
 *  chooses between updates and initializing dom.
 * @param {HTMLElement} popoverEl The popover element in popover-app.js
 * @param {object} state The state of popover-app.js
 */
function renderPopoverHelper(
  popoverEl,
  state,
  collections,
  favoritableTitle,
  triggerEl,
  maxWidth,
  doFocus,
) {
  if (!state.open) {
    popoverEl.innerHTML = '';
  } else {
    if (!popoverEl.innerHTML) {
      popoverEl.innerHTML = popover(state, collections, triggerEl, maxWidth);
      popoverEl.querySelector('input').focus();
    }
    renderPopover(state, collections, favoritableTitle);
    if (collections.length === 0) {
      const firstInput = popoverEl.querySelector('input');
      if (firstInput) firstInput.setAttribute('data-first-element', 'true');
    }
  }
  popoverEl.style.display = state.open ? 'block' : 'none';
  if (doFocus) {
    const element = popoverEl.querySelector('input');
    if (element) element.focus();
  }
}

export default renderPopoverHelper;
