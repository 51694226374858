import queryString from 'query-string';

import events from './events';
import utils from '../../utils';

/**
 * BlueConic tracking library.
 *
 * BlueConic event tracking is not similar to GA or Mixpanel.
 * There is no api or library to call. Via their library, they
 * will read events from a global namespace we create. This namespace
 * should contain a list of events and possibly some user information.
 * The structure of this global variable is TBD.
 * The global events list should be reset with each PAGEVIEW.
 */
function blueconic(user, location, cookies = {}, siteKey) {
  let currentUser = user;
  const { offer_code, incode, extcode, otc = 'default', utm_source } = cookies; // eslint-disable-line camelcase
  const blueConicTracking = 'blueConicTracking';

  /**
   * User config taken from Blueconic initialization. This will
   * likely be trimmed significantly based on feedback from BlueConic
   * as they get most of this info from other user interactions on the site.
   */
  const config = (user) => {
    if (!user) user = utils.getDefaultUser();
    const parsed = queryString.parse(window.location.search) || {};
    return {
      site_key: siteKey,
      ip: '',
      email: user.email,
      user_id: user.id,
      role: user.role,
      package: '',
      offer_test_variant: otc,
      ref: parsed.ref,
      offer_code,
      incode,
      extcode,
      utm_source,
    };
  };

  const publishEvents = () => {
    if (
      typeof window.blueConicClient !== 'undefined' &&
      window.blueConicClient.event
    ) {
      const pub = window.blueConicClient.event.publish;
      window[blueConicTracking].events.map((ev) => pub(ev.name));
    }
  };

  /**
   * Push the event data onto the global stack.
   */
  const trackEvent = (eventName, options = {}) => {
    const evt = { ...options, name: events[eventName] };
    window[blueConicTracking].events.push(evt);
    publishEvents();
  };

  /**
   * This exists to maintain proper library api, calls trackEvent
   */
  const trackPageView = (options) => {
    trackEvent('PAGEVIEW', options);
  };

  /**
   * Create the global BlueConicTracking namespace
   */
  const startBlueConic = () => {
    if (typeof window.blueConicTracking === 'undefined') {
      window[blueConicTracking] = {
        user: config(currentUser),
        events: [],
        trackEvent,
      };
    }
  };

  /* blueConicClient picks up our global object, don't need to wait for them */
  const initialize = () => {
    startBlueConic();
  };

  /**
   * If the user status changes, we should reset the BlueConicTracking namespace
   */
  const refreshUser = (user) => {
    currentUser = user;
    startBlueConic();
  };

  const trackRouterChange = (options = {}) => {
    options.location = document.location.pathname;
    trackEvent('PAGEVIEW', options);
  };

  initialize();

  return {
    refreshUser,
    trackEvent,
    trackPageView,
    trackRouterChange,
  };
}

export default blueconic;
